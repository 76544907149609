import { getUser } from "../utils/Common";
import API from "../utils/api";

export async function SetCssByCompany() {
  await API.getData("/Company/get?companyId=" + getUser().companyId)
    .then((res) => {
      sessionStorage.setItem("cssByCompany", JSON.stringify(res.data));
      sessionStorage.setItem("configBaseURL", Buffer.from(res.config.baseURL));
      setTimeout(() => {
        window.location.reload(true);
      }, 1200);
    })
    .catch(function (err) {
      console.error("Error de conexion " + err);
    });
}

export async function SetEmarsuite() {
  await API.getData(
    "/Emarsuite/authEmarsuite?cedula=" +
      getUser().employeeIdCard +
      "&companyId=" +
      getUser().companyId
  )
    .then((res) => {
      sessionStorage.setItem("emarsuite", res.data.authLink);
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    })
    .catch(function (err) {
      console.error("Error de conexion " + err);
    });
}

export const GetEmarsuite = () => {
  let link = {
    enlace: "",
  };
  link.enlace = sessionStorage.getItem("emarsuite");
  return link;
};
export const GetCssByCompany = () => {
  let Css = {
    logo: "empty.png",
    PrimaryColor: "",
    AccentColor: "",
    name: "",
    CompanyLogoWithTitle: "",
    emailSupport: "",
    activateSurveyVirus: "",
    activateBonificacionMens: "",
  };
  let obj = JSON.parse(sessionStorage.getItem("cssByCompany"));

  if (obj !== null && obj.length > 0) {
    Css.logo = obj[0].logo ?? "";
    Css.CompanyLogoWithTitle = obj[0].companyLogoWithTitle ?? "";
    Css.PrimaryColor += obj[0].primaryColor ?? "#ed1c24";
    Css.AccentColor += obj[0].accentColor ?? "transparent";
    Css.name += Css.name + (obj[0].name ?? "");
    Css.emailSupport += Css.emailSupport + (obj[0].emailSupport ?? "");
    Css.activateSurveyVirus = obj[0].activateSurveyVirus;
    Css.activateBonificacionMens = obj[0].activateBonificacionMens;
  }

  return Css;
};

export const ChangeEnterBackground = (e) => {
  e.target.style.background =
    GetCssByCompany().AccentColor === "transparent"
      ? e.target.style.background
      : GetCssByCompany().AccentColor;
};
export const ChangeLeaveBackground = (e) => {
  e.target.style.background =
    GetCssByCompany().PrimaryColor === "transparent"
      ? e.target.style.background
      : GetCssByCompany().PrimaryColor;
};
/*Para usarlo: en los controles onMouseEnter={ChangeEnterBackground} onMouseLeave={ChangeLeaveBackground} */
