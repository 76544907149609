import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { getUser } from "../../utils/Common";
import $ from "jquery";
import {
  ShowPopUp,
  ShowInfoMessage,
  MessageResults,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";
import { Grid } from "@material-ui/core";
import DropdownList from "../../components/dropdown/dropdownList";

import storage from "../../firebase/firebase";

export default function AddMericoSchoolApplication(props) {
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };
  const [dropUnitType, setUnitType] = useState([]);
  const [dropUnitTypeValor, setUnitTypeValor] = useState("");
  const [archivoUrl, setArchivoUrl] = React.useState("");
  const [Actadocus, setDocusActa] = React.useState(["SUBIR INFORMACIÓN"]);
  const [CartaDirectordocus, setDocusCartaDirector] = React.useState([
    "SUBIR INFORMACIÓN",
  ]);
  const [Calificacionesdocus, setDocusCalificaciones] = React.useState([
    "SUBIR INFORMACIÓN",
  ]);

  const [Acta, setActa] = useState("");
  const [CartaDirector, setCartaDirector] = useState("");
  const [Calificaciones, setCalificaciones] = useState("");

  const [ActaName, setActaName] = useState("");
  const [CartaDirectorName, setCartaDirectorName] = useState("");
  const [CalificacionesName, setCalificacionesName] = useState("");

  const [dropCurso, setCurso] = useState([]);
  const [dropCursoValor, setCursoValor] = useState("");

  const description = useFormInput("");
  const email = useFormInput("");
  const phone = useFormInput("");
  const [titleValid, setTitleValid] = useState(true);

  //Buscamos la dependecia del empleado
  const GetDependiente = () => {
    API.getData("Dependient/get?employeeId=" + getUser().employeeIdCard)
      .then((res) => {
        if (res.status === 200) {
          let dropData = [];
          res.data.forEach((item) => {
            var name = item.firstname + " " + item.surname;

            if (item.status === 1) {
              dropData.push({ label: name, value: item.id });
            }
          });
          let Drop = (
            <DropdownList
              idControl="dropUniType"
              headerLabel="Dependiente"
              data={dropData}
              handleChange={OnDropUnidChange}
            />
          );
          setUnitType(Drop);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };

  const OnDropUnidChange = (selectedOption) => {
    sessionStorage.setItem("UnitTypeValue", selectedOption.value);
    setUnitTypeValor(selectedOption.value);
  };

  //Buscamos los cursos
  const Getcursos = () => {
    API.getData("Courses/get")
      .then((res) => {
        if (res.status === 200) {
          let dropData = [];
          res.data.forEach((item) => {
            if (item.status === 1) {
              dropData.push({ label: item.description, value: item.id });
            }
          });
          let Drop = (
            <DropdownList
              idControl="dropCurso"
              headerLabel="Curso"
              data={dropData}
              handleChange={OnCursoChange}
            />
          );
          setCurso(Drop);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };

  const OnCursoChange = (selectedOption) => {
    sessionStorage.setItem("CursoValue", selectedOption.value);
    setCursoValor(selectedOption.value);
  };

  const addSolicitoMerito = async () => {
    if (
      Acta != "" &&
      CartaDirector != "" &&
      Calificaciones != "" &&
      email.value != "" &&
      phone.value != ""
    ) {
      let date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let fecha = "" + year + "-0" + month + "-" + day + "";
      var todayDate = new Date().toISOString().slice(0, 10); 
      Swal.fire({
        title: "Guardando información!",
        html: "Por favor espere!", // add html attribute if you want or remove
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const storageRef = storage.ref("/SchoolMeritDocuments");
      //Guardamos Acta
      const archivoPath = storageRef.child(Acta.name);
      await archivoPath.put(Acta);
      const enlaceUrlActa = await archivoPath.getDownloadURL();
      //Guardar Carta Director
      const archivoPaths = storageRef.child(CartaDirector.name);
      await archivoPaths.put(CartaDirector);
      const enlaceUrlDirector = await archivoPaths.getDownloadURL();
      //Guardar Calificaciones
      const archivoPathc = storageRef.child(Calificaciones.name);
      await archivoPathc.put(Calificaciones);
      const enlaceUrlCalificaciones = await archivoPathc.getDownloadURL();

      API.postData("SchoolMerit/add", {
        id: 0,
        course_id: dropCursoValor,
        dependient_id: dropUnitTypeValor,
        employee_id: getUser().employeeIdCard,
        status_id: 1,
        email: email.value,
        phone: phone.value,
        comment: description.value,
        created_in: todayDate,
        status: 1,
      })
        .then((res) => {
          addDocumento(res.data, enlaceUrlActa, ActaName);
          addDocumento(res.data, enlaceUrlDirector, CartaDirectorName);
          addDocumento(res.data, enlaceUrlCalificaciones, CalificacionesName);
          MessageResults(res.status);
          props.history.push("/SolicitudMerito");
          setTimeout(() => {
            window.location.reload(true);
          }, 1200);
        })
        .catch((error) => {
          ShowAlertMessage(
            "Información",
            "Hubo un problema intente de nuevo",
            "error"
          );
          console.log(error);
        });
      Swal.close();
    } else {
        ShowInfoMessage( "Debe cargar todos los documentos y completar toda la información solicitada");
       
    }
  };

  const addDocumento = async (resp, url, fillname,) => {
    var todayDate = new Date().toISOString().slice(0, 10); 

    API.postData("SchoolMeritDocument/add", {
      id: 0,
      school_merit_id: resp.id,
      document_url: url,
      status_id: 1,
      file_name: fillname,
      created_in: todayDate,
      status: 1,
      description: "Por definir",
    })
      .then((response) => {
        /*  MessageResults(response.status);
          props.history.push("/news");
          setTimeout(() => {
            window.location.reload(true);
          }, 1200);*/
      })
      .catch((error) => {
        ShowAlertMessage(
          "Información",
          "Hubo un problema intente de nuevo",
          "error"
        );
        console.log(error);
      });
  };
  const archivoHandlerActa = async (e) => {
    const archivo = e.target.files[0];
    setDocusActa(e.target.files[0].name);
    setActaName(e.target.files[0].name);
    setActa(archivo);
  };
  const archivoDirector = async (d) => {
    const archivo = d.target.files[0];
    setDocusCartaDirector(d.target.files[0].name);
    setCartaDirector(archivo);
    setCartaDirectorName(d.target.files[0].name);
  };
  const HandlerCalificacion = async (c) => {
    const archivo = c.target.files[0];
    setDocusCalificaciones(c.target.files[0].name);
    setCalificaciones(archivo);
    setCalificacionesName(c.target.files[0].name);
  };
  const sendMedicalLicense = (image) => {
    API.postData("MedicalLicenses/Add", {
      employeeNumber: getUser().employeeNumber,
      employee: getUser().firstName + " " + getUser().lastName,
      description: description.value.toLowerCase(),
      companyId: getUser().companyId,
      img: image,
      inactive: "N",
    })
      .then((response) => {
        setTimeout(() => {
          MessageResults(response.status);
        }, 1500);
      })
      .catch((error) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Intente de nuevo, algo salio mal al enviar la Licencia Médica</p>"
        );
        console.log(error);
      });
  };
  useEffect(() => {
    GetDependiente();
    Getcursos();
  }, []);

  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <h4 className="text-center">SOLICITAR MÉRITO ESCOLAR</h4>
        <hr />

        <section>
          <div id="myform" className="form_container">
            <div class="row">
              <div class="form-group col-md-12">
                <div id="dropFields" className="row mb-2">
                  {dropUnitType}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-12">
                <div id="dropFieldss" className="row mb-2">
                  {dropCurso}
                </div>
              </div>
            </div>

            <div className="form_group">
              <label className="classspan text-left">Email</label>

              <input type="text" className="txtbox" {...email} />
            </div>
            <div className="form_group">
              <label className="classspan text-left">Télefono</label>

              <input type="text" className="txtbox" {...phone} />
            </div>

            <br />
            <div class="form-group ">
              <Grid item sm={3} xs={6}>
                <button class="contenedor-btn-file" style={{ color: "white" }}>
                  <i class="fa fa-file-text" style={{ color: "white" }}></i>
                  Acta de nacimiento
                  <label for="btn-file"></label>
                  <input
                    id="btn-file"
                    type="file"
                    onChange={archivoHandlerActa}
                  />
                </button>
                <label for="btn-file">{Actadocus}</label>
              </Grid>
              <br />

              <Grid item sm={3} xs={6}>
                <button class="contenedor-btn-file" style={{ color: "white" }}>
                  <i class="fa fa-file-text" style={{ color: "white" }}></i>
                  Carta del director
                  <label for="btn-file-Director"> </label>
                  <input
                    id="btn-file-Director"
                    type="file"
                    onChange={archivoDirector}
                  />
                </button>
                <label for="btn-file-Director">{CartaDirectordocus}</label>
              </Grid>
              <br />

              <Grid item sm={3} xs={6}>
                <button class="contenedor-btn-file" style={{ color: "white" }}>
                  <i class="fa fa-file-text" style={{ color: "white" }}></i>
                  Calificaciones
                  <label for="btn-file-Calificacion"></label>
                  <input
                    id="btn-file-Calificacion"
                    type="file"
                    onChange={HandlerCalificacion}
                  />
                </button>
                <label for="btn-file-Calificacion">{Calificacionesdocus}</label>
              </Grid>
              <br />
            </div>

            <div className="form_group">
              <label className="classspan text-left">Comentario: </label>
              <textarea
                type="text"
                className={`form-control ${!titleValid && "is-invalid"}`}
                placeholder="Comentario"
                rows="4"
                {...description}
                cols="50"
                tyle={{
                  resize: "none",
                }}
              ></textarea>
              <br />
              <Button
                type="btn-outline-danger"
                text="ENVIAR SOLICITO"
                clickHandler={addSolicitoMerito}
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
