import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import { Accordion, Card } from "react-bootstrap";
import { getUser } from "../../utils/Common";
import {
  PoliciesValidation,
  DisabledBackValidation,
} from "../../utils/CommonFunctions";
import Loading from "../loading/loading";

import "../../tools/JsTools.css";
const PoliciesServices = ({ props }) => {
  const [policies, setPolicies] = useState([]);
  const [filter, setFilter] = useState("");
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    API.getData(
      "Policies/get?employee=" +
        getUser().employeeIdCard +
        "&companyId=" +
        getUser().companyId +
        "&level=" +
        getUser().level
    )
      .then((response) => {
        setDataLoading(false);
        if (response.data.length > 0) {
          setPolicies(response.data);
        } else {
          setPolicies([]);
          setDataLoading(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function searchSpace(event) {
    let texto = event.target.value;
    setFilter(texto.toUpperCase());
  }

  return (
    <div>
      <section className="container">
        <div className="container">
          <br />
          <br />
          <input
            className="form-control"
            placeholder="Escriba para buscar"
            onChange={(e) => searchSpace(e)}
          />

          <br />
          {!dataLoading ? (
            <Accordion>
              {policies
                .filter((item) =>
                  JSON.stringify(item.title).toUpperCase().includes(filter)
                )
                .map((item) => (
                  <a
                    key={item.id}
                    href={
                      "/genericpolicies?id=" + item.id + "&name=" + item.title
                    }
                  >
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={item.id}
                        className={
                          item.leida
                            ? "bg-Green custom-card-header"
                            : " custom-card-header"
                        }
                      >
                        <div className="container d-flex align-items-center justify-content-center">
                          <span className="text-dark title-upper">
                            {item.leida ? (
                              <span
                                className="fa fa-check-circle-o green faa-flash animated faa-slow"
                                title="Leída y Aceptada"
                              >
                                &nbsp;
                              </span>
                            ) : (
                              ""
                            )}
                            {item.title}
                          </span>
                        </div>
                        <span className="policy-cordion">
                          <i class="fa fa-chevron-right custom-arrow-right"></i>
                        </span>
                      </Accordion.Toggle>
                    </Card>
                  </a>
                ))}
            </Accordion>
          ) : (
            <Loading />
          )}
        </div>
        <br />
        <br />
      </section>
    </div>
  );
};

export default PoliciesServices;
