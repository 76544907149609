import React, { useState, useEffect } from "react";
import CarouselBanner from "../../components/carrousels/carousel_banner";
import {
  getUser,
  removeUserSession,
  removeBonification,
  getBonification,
} from "../../utils/Common";
import API from "../../utils/api";
import Congratulations from "../../views/Birthday/congratulatons";
import Moment from "moment";
import {
  ShowPopUp,
  ShowAlertMessage,
  ShowConfirmationMessage,
} from "../../utils/CommonFunctions";
import { GetCssByCompany, SetCssByCompany } from "../../CssbyCompany/CommonCSS";

import { NumbersWithComma } from "../../utils/CommonFunctions";
import { data } from "jquery";
export default function Home(props) {
  const [company, setCompany] = useState([]);
  const [siteMenu, setSiteMenu] = useState([{ TemplateHtml: "" }]);

  const handleLogout = () => {
    removeUserSession();
    props.history.push("/login");
  };

  const SaveChanges1 = () => {
    removeBonification();
  };
  const SaveChanges = () => {
    if (GetCssByCompany().name == "") {
      SetCssByCompany();
    }
    let date = new Date().getFullYear();
    if (getBonification().activateBonificacionMens) {
      if (getBonification().bonificacion !== "OK") {
        ShowPopUp({
          handlerEvent: SaveChanges1,
          htmlBody: `<html><body><section>
   <div class="container">
   Pago Bonificación ${date}<br /><br />Estimado colaborador<br /><br />El ${date} fue un año de grandes retos y cambios, desafiamos las adversidades con la 
   voluntad y determinación para seguir “elevando la calidad de vida de los dominicanos a través de nuestros productos y servicios”. <br /><br />
   Agradecemos tu empeño y dedicación, para juntos obtener los resultados que hoy nos permiten realizar el pago de la bonificación.<br /><br />
   Es por ello que recibirás <b> RD$${NumbersWithComma(
     getBonification().bonificacion
   )} </b>(antes de descuentos) <br /><br />
   Esperamos continuar trabajando juntos para hacer de nuestra visión una realidad “contar con la preferencia de nuestras marcas en República Dominicana 
   “… porque contamos con buenas marcas y buenas personas.
   </div>	
   </section></body></html>`,
          isDisabled: true,
          TextOk: "Aceptar",
        });
      }
    }
  };
  const GetCompany = () => {
    API.getData("/Company/get?companyId=" + getUser().companyId)
      .then((res) => {
        if (res.status === 200) {
          debugger;
          setCompany(res.data);

          if (GetCssByCompany().activateSurveyVirus == true) {
            API.getData(
              "/PollVaccinesService/Validar?employeeIdCard=" +
                getUser().employeeIdCard
            )
              .then((res) => {
                if (res.data) {
                } else {
                  ShowConfirmationMessage(
                    SaveDisableChanges,
                    "",
                    [],
                    "Debes completar la encuesta de vacunación desea hacerlo ahora?"
                  );
                }
              })
              .catch(function (err) {
                console.error("Error de conexion " + err);
              });
          }
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };

  const SaveDisableChanges = (params) => {
    props.history.push("/PollCovid");
  };

  const GetMenu = (pathUrl) => {
    API.getData(
      "SiteMenu/GetbyMenuRole?companyId=" +
        getUser().companyId +
        "&isSupervisor=" +
        getUser().isSupervisor.toUpperCase()
    )
      .then((res) => {
        if (res.status === 200) {
          setSiteMenu(res.data.find((c) => c.PatchName == pathUrl));
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };

  const GetEvaluation = (code) => {
    API.getData(
      "/NewEvaluation/GetNewEvaluationCalendarId?companyId=" +
        getUser().companyId +
        "&evaluationCalendarId=" +
        code +
        "&employeeNumber=" +
        getUser().employeeIdCard +
        "&evaluarAnual=" +
        getUser().hiringDate +
        "&fechaActual=" +
        Moment(new Date()).format("YYYY-MM-DD") +
        "&level=" +
        getUser().level
    )
      .then((res) => {
        if (res.data != "") {
          localStorage.setItem("EvaluationPending", "true");
          ShowAlertMessage("Información", "DEBE COMPLETAR LA ENCUESTA", "info");
          props.history.push("/new_evaluation?id=8");
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };
  const GetEvaluation2 = () => {
    API.getData(
      "/NewEvaluation/GetNewEvaluationCalendarId?companyId=" +
        getUser().companyId +
        "&evaluationCalendarId=8&employeeNumber=" +
        getUser().employeeIdCard +
        "&evaluarAnual=" +
        getUser().hiringDate +
        "&fechaActual=" +
        Moment(new Date()).format("YYYY-MM-DD") +
        "&level=" +
        getUser().level
    )
      .then((res) => {
        if (res.data != "") {
          localStorage.setItem("EvaluationPending", "true");
          ShowAlertMessage("Información", "DEBE COMPLETAR LA ENCUESTA", "info");
          props.history.push("/new_evaluation?id=8");
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };

  useEffect(() => {
    SaveChanges();
   // GetEvaluation2();
    GetEvaluation(5);
    GetEvaluation(6);
    GetEvaluation(8);
    GetEvaluation(10);
    GetMenu("home");
    GetCompany();
  }, []);

  return (
    <div>
      <br />

      <section className="container d-flex align-items-center justify-content-center text-center ">
        <div className="inner">
          <div className="container">
            <Congratulations />
          </div>

          <br />
          <header className="special">
            {company.map((item) => (
              <h5 key={item.id}>
                <div
                  className="highlights"
                  dangerouslySetInnerHTML={{ __html: item.mottoPrimary }}
                />
              </h5>
            ))}
          </header>
          {
            <div
              className="highlights"
              dangerouslySetInnerHTML={{ __html: siteMenu.TemplateHtml }}
            />
          }
        </div>
      </section>
    </div>
  );
}
