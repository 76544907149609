import React from "react";
import PoliciesServices from "../../components/policies/policiesServices";

export default class Policies extends React.Component {
  render() {
    return (
      <>
        <PoliciesServices />
      </>
    );
  }
}
