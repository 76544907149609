import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import queryString from "query-string";
import BackButton from "../../components/Button/BackButton";

export default function CommunicationGeneric(props) {
  const [communication, setCommunication] = useState([]);
  const id = queryString.parse(props.location.search).id;
  const name = queryString.parse(props.location.search).name;

  useEffect(() => {
    API.getData("News/getbyid?id=" + id)
      .then((response) => {
        setCommunication(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },[]);

  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <h4 className="text-center">{name}</h4>
        <br /> 
        <div className="content">
          {<div dangerouslySetInnerHTML={{ __html: communication.content }} />}
        </div>
      </div>
    </section>
  );
}
