import React from 'react'
import Swal from 'sweetalert2';
import API from '../../utils/api';
import { MessageResults, ShowAlertMessage } from '../../utils/CommonFunctions';

export const deleteLicense = async (id)=>{
  
    return new Promise((resolve, eject)=>{
        Swal.fire({
            title: "Elimando",
            showConfirmButton: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            allowOutsideClick: false,
            dangerMode: true,
          });
        API.deleteData(`MedicalLicenses/delete?id=${id}`)
            .then((response) => {
              Swal.close();
              if(response.status == 200){
                MessageResults(response.status);
              setTimeout(() => {
                  window.location.reload(true);
              }, 1500);
              }
            })
            .catch((error) => {
              ShowAlertMessage(
                "¡Mensaje de aviso!",
                " <p>Intente de nuevo, algo salio mal al eliminar el permiso</p>"
              );
              console.log(error);
            });
    });
  }