import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Moment from "moment";
import "moment/locale/es";
import es from "date-fns/locale/es";
import Button from "../Button/Button";
import "react-datepicker/dist/react-datepicker.css";
import { getUser, removeUserSession } from "../../utils/Common";
import API from "../../utils/api";
import { ShowAlertMessage, ShowConfirmationMessage, MessageResults } from "../../utils/CommonFunctions";
import { GetAmountBetweenDays, GetDefaultDate, GetWeekendDays, GetAvailableHolidays, GetExistinDateRange } from './vacationCommon'

registerLocale("es", es);

const VacationsAdd = (props) => {

    const [availableDays, setAvailableDays] = useState(document.getElementById("tbAvailabledaysAdd") !== null ? document.getElementById("tbAvailabledaysAdd").value : "0");
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [listHoliday, setListHoliday] = useState([]);
    const GetdefDate = () => {
        let curDate = new Date();
        curDate.setDate(curDate.getDate() + 7);
        return curDate;
    }
    const [deftDate, setdeftDate] = useState(GetdefDate());
    const onChangeFrom = (date) => {
        setStartDate(date);
        //setEndDate(GetWeekendDays(GetDefaultDate(date), availableDays));
        GetAmountBetweenDays()
    }
    const onChangeTo = (date) => {
        setEndDate(date);
        GetAmountBetweenDays();
        onChangeCounter();
    }
    const onChangeCounter = () => {
        setTimeout(() => {
            if (parseFloat(document.getElementById('tbDays').value) > parseFloat(document.getElementById('tbAvailabledaysAdd').value)) {
                setDisabledBtn(true);
            } if (parseFloat(document.getElementById('tbDays').value) > 0 && parseFloat(document.getElementById('tbDays').value) <= parseFloat(document.getElementById('tbAvailabledaysAdd').value)) {
                setDisabledBtn(false);
            }
        }, 250);
    }
    const OpenHolyday = () => {
        ShowAlertMessage("Días Feriados (" + new Date().getFullYear() + ")", GetHoliday(), '');
    }
    const SaveVacation = () => {

        let fromDate = new Date(document.getElementById('dtFrom').value.substr(6, 4) + '-' + document.getElementById('dtFrom').value.substr(3, 2) + '-' + document.getElementById('dtFrom').value.substr(0, 2) + ' ');
        let toDate = new Date(document.getElementById('dtTo').value.substr(6, 4) + '-' + document.getElementById('dtTo').value.substr(3, 2) + '-' + document.getElementById('dtTo').value.substr(0, 2) + ' ');
        let holidaysTaken = parseInt(document.getElementById('tbDays').value);
        let dataResult = GetExistinDateRange(fromDate, toDate);
        if (holidaysTaken > 0) {
            if (dataResult.Status) {
                setDisabledBtn(true);
                ShowAlertMessage("Ya tiene registrada las siguentes fechas para vacaciones, favor seleccionar un rango disponible!", dataResult.data, '');
            } else {
                API.postData("vacation/add", {
                    employeeNumber: getUser().employeeNumber.trim(),
                    dateFrom: new Date(document.getElementById('dtFrom').value.substr(6, 4) + '-' + document.getElementById('dtFrom').value.substr(3, 2) + '-' + document.getElementById('dtFrom').value.substr(0, 2) + ' '),
                    dateTo: new Date(document.getElementById('dtTo').value.substr(6, 4) + '-' + document.getElementById('dtTo').value.substr(3, 2) + '-' + document.getElementById('dtTo').value.substr(0, 2) + ' '),
                    holidaysTaken: holidaysTaken,
                    vacationRequestStatusId: 1,
                    companyId: getUser().companyId
                })
                    .then((res) => {
                        if (res.status === 200) {
                            MessageResults(res.status);
                            setTimeout(() => {
                                window.location.reload(true);
                            }, 1200);
                        }
                    })
                    .catch(function (err) {
                        console.error("Error de conexion " + err);
                        MessageResults(400, err);
                    });
            }
        }else{
            ShowAlertMessage("Debe verificar que no este seleccionando una fecha que no tenga vacaciones", dataResult.data, '');
        }
    }
    const AddVatations = () => {
        ShowConfirmationMessage(SaveVacation, '')
    }
    const GetHoliday = () => {
        let holidays = GetAvailableHolidays();
        let dataHtml = [];
        dataHtml.push('<div class="container d-flex align-items-center justify-content-center"><ul class="no-bullets">');
        holidays.forEach((item, index) => {
            if (item.currentFullHoliDay !== null) {
                const dayName = new Date(item.currentFullHoliDay).toLocaleString('es', { weekday: 'long' });
                let cssClass = '';
                switch (dayName) {
                    /* case 'sábado':
                         cssClass = 'red';
                         break;*/
                    case 'domingo':
                        cssClass = 'red';
                        break;

                    default:
                        cssClass = '';
                        break;
                }

                let currentDateFormat = "<li><span class='capitalized " + cssClass + "'>" + dayName + "</span> <span>" + Moment(new Date(item.currentFullHoliDay)).format("DD") + "</span>&nbsp;de<span class='capitalized'> " + new Date(item.currentFullHoliDay).toLocaleString('es', { month: 'long' }) + '</span> ' + Moment(new Date(item.currentFullHoliDay)).format("YYYY") + '</li>';
                dataHtml.push(currentDateFormat);
            }
        });
        dataHtml.push('</ul></div>');
        return dataHtml.join('');

    }
    useEffect(() => {
        setTimeout(() => {
            //setStartDate(GetDefaultDate(deftDate))
            //setEndDate(GetWeekendDays(GetDefaultDate(deftDate)))
            GetAmountBetweenDays()
        }, 1500);


    }, []);

    return (
        <>
            <br />
            <div className=" htmlPayroll vacation-add  table-responsive container">
                <div className="row">
                    <div className="lowcolor col-12">

                        <h2 className="h2" Style={"text-align:center"}>Solicitar&nbsp;Vacaciones</h2>

                        <hr />
                    </div>
                </div>
                <section>
                    <div className="row">
                        <div className=" col-12">
                            <div className="input-group-prepend">
                                <div className="text-wrap textInfo mb-2">
                                    <span>
                                        <i className="fa fa-exclamation-triangle faa-flash animated"></i>
                        &nbsp;Debe tener pendiente al momento de solicitar sus vacaciones los d&iacute;as festivos. &nbsp;<a className="fa faa-flash animated size-effect bold-font" href="#" onClick={OpenHolyday}><span class="iconify" data-icon="fa:calendar" data-inline="false"></span>&nbsp;Ver Calendario Festivo {new Date().getFullYear()}</a>
                                        <br />&nbsp;NOTA: en el momento de seleccionar sus vacaciones si existe uno o varios d&iacute;as festivos estos seran restados autom&aacute;ticamente para no afectar el disfrute de sus vacaciones. Tambi&eacute;n debe tener pendiente NO seleccionar d&iacute;as que ya haya seleccionado.
                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="tbHiredDate">Fecha&nbsp;Contrataci&oacute;n</label>
                            <input type="text" className="form-control not-allowed custom-color " id="tbHiredDate" value={Moment(props.props.User.hiringDate).format("L")} readOnly />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="tbAvailabledaysAdd">Cantidad&nbsp;Disponible&nbsp;(d&iacute;as)</label>
                            <input type="text" className="form-control not-allowed" id="tbAvailabledaysAdd" readOnly />
                        </div>

                    </div>
                </section>
                <section>
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="dtFrom">Desde</label>
                            <div className="vacation-calendar input-group mb-2">
                                <DatePicker
                                    id="dtFrom"
                                    className="form-control reactCalendar"
                                    relativeSize={true}
                                    popperPlacement="auto-right"
                                    minDate={deftDate}
                                    selected={startDate}
                                    locale="es"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={onChangeFrom}
                                />
                                <div className="input-group-prepend calendarCs">
                                    <div className="notAllowed">
                                        <span
                                            class="iconify"
                                            data-icon="fa:calendar"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="dtTo">Hasta</label>&nbsp;<span hidden={!disabledBtn} className="title-required">&nbsp;<i className="fa fa-exclamation-triangle faa-flash animated">&nbsp;Excede d&iacute;as disponibles o ya existe!</i></span>
                            <div className="vacation-calendar input-group mb-2">
                                <DatePicker
                                    className="form-control reactCalendar"
                                    id="dtTo"
                                    relativeSize={true}
                                    popperPlacement="auto-right"
                                    selected={endDate}
                                    minDate={deftDate}
                                    locale="es"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={onChangeTo}
                                />
                                <div className="input-group-prepend calendarCs">
                                    <div className="notAllowed">
                                        <span
                                            className="iconify"
                                            data-icon="fa:calendar"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="tbDays">Cantidad&nbsp;a&nbsp;Solicitar</label>
                            <input type="number" className="form-control" id="tbDays" min="1" readOnly />
                        </div>
                        <div className="form-group col-md-12">
                            <label htmlFor="btnAdd">&nbsp;</label>
                            <div className="">
                                <div className="input-group mb-2">
                                    <Button
                                        isDisabled={disabledBtn ?? false}
                                        type="btn-outline-danger"
                                        text="Solicitar Vacaciones"
                                        clickHandler={AddVatations}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="csholiday">
                            <ul>

                            </ul>

                        </div>

                    </div>

                </section>

            </div>
            <br />
            <br />
        </>
    )
}
export default VacationsAdd;