import React from 'react';
import { getUser } from '../../utils/Common';
import { GetImagePatch } from '../../utils/CommonFunctions';
import { useBarcode } from 'react-barcodes';
import  {GetCssByCompany} from "../../CssbyCompany/CommonCSS";

export default function MyCarnet() {
    document.body.style.backgroundColor = "white";
    const { inputRef  } = useBarcode({
        value: getUser().employeeIdCard,
        options: {
          height: 30,
          displayValue: false
        }
      });
    return (
            <section id="main" className="wrapper" Style={{ padding: "1rem 0 2rem 0" }}>
                <div  >
                    <div className=" container d-flex align-items-center justify-content-center card" Style="width: 22rem;">
                        <br />
                        <p className="my-carnet-size">
                        <p></p>
                        <img src={GetCssByCompany().CompanyLogoWithTitle} alt="logo" width="250px"  />
                            <br /><br /> 
                            <img src={GetImagePatch("/images/employee_photo/"+ getUser().employeeIdCard+".jpg")} width="200px" alt="foto" />
                            <br />
                            <br />
                            <h4>{getUser().firstName+" "+getUser().lastName}</h4>
                            <br />
                            <h4>{getUser().employeeNumber}</h4>
                            <br />
                        <svg ref={inputRef } /> 
                        </p>

                    </div>                     
                </div>
            </section>

    );
}
