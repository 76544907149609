import React from 'react'

export default function AllBirthday(props) {
    return (
  <>
      <section className="container">
         <div className="inner">
          <br />
          <br />                        
           <div className="highlights container d-flex align-items-center justify-content-center">
            <section>
              <div className="content2">
                <header>
                  <a href="/todayBirthdate" className="icon most-visited-log">
                    <span
                      className="iconify iconifysize  color-Icon size-effect"
                      data-icon="fa:birthday-cake"
                      data-inline="false"
                    ></span>
                  </a>
                  <br />
                  <br />
                  <h6 className="text-wrap">Cumpleaños del D&iacute;a</h6>
                  <br />
                  <br />
                </header>
              </div>
            </section>
            <section>
              <div className="content2">
                <header>
                  <a href="/monthBirthdate" className="icon most-visited-log">
                    <span
                      className="iconify iconifysize  color-Icon size-effect"
                      data-icon="carbon:calendar-heat-map"
                      data-inline="false"
                    ></span>
                  </a>
                  <br />
                  <br />
                  <h6 className="text-wrap">Cumpleaños del Mes</h6>
                  <br />
                  <br />
                </header>
              </div>
            </section>
           </div>                         
         </div>
    </section>
    </>
    )
}