import React, { useState, useEffect } from 'react';
import API from '../../utils/api';
import { getUser } from "../../utils/Common";

export default function MissionVisionValues() {

    const [mission, setMission] = useState("");
    const [vision, setVision] = useState("");
    const [values, setValues] = useState("");
    const [aboutUs, setAboutUs] = useState("");

    useEffect(() => {
        API.getData('company/get?companyId=' + getUser().companyId).then(response => {
            setMission(response.data[0].mision);
            setVision(response.data[0].vision);
            setValues(response.data[0].values);
            setAboutUs(response.data[0].aboutUs);
        }).catch(error => {
            console.log(error);
        });
      },[]);

    return ( 
        <section id="main" className="wrapper">
            <div className="inner">
                <h2 Style={"text-align:center"}>¿Quiénes Somos?</h2>
                <br></br>
                <div className="content">
                    <header>
                        <h3>Visión</h3>
                    </header>
                    <div dangerouslySetInnerHTML={{__html: vision}} />
                    <br />
                    <br />
                    <header>
                        <h3>Misión</h3>
                    </header>
                    <div dangerouslySetInnerHTML={{__html: mission}} />
                    <br />
                    <br />
                    <header>
                        <h3>Valores</h3>
                    </header>               
                    <div dangerouslySetInnerHTML={{__html: values}} />
                </div>
            </div>
        </section>
    )
}