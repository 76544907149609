import React, { useState, useEffect } from "react";
import { getUser } from "../../utils/Common";
import {
  Container,
} from "@material-ui/core"
import moment from "moment";
import Moment from "moment"
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { addDependi } from "../action/dependient";

const now = moment().minutes(0).seconds(0).add(1, "hours");
const nowPlus1 = moment().minutes(0).seconds(0).add(1, "hours");

const initEvent = {
  firstname: "",
  surname: "",
  employee_id: "",
  gender: "",
  birthdate: now.toDate(),
  created_in: nowPlus1.toDate(),
  status: "",
};

export default function AddDependient(props) {
  const [formValues, setFormValues] = useState(initEvent);
  const [name, setNameValid] = useState(true);
  const [surnameValid, setSurnameValid] = useState(true);
  //const [permit, setPermit] = useState([{}]);

  const { firstname, surname, employee_id, gender, birthdate, created_in, status } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();


    if (firstname.trim().length < 2) {
      return setNameValid(false);
    }
    if (surname.trim().length < 2) {
        return setSurnameValid(false);
      }


    formValues.employee_id = getUser().employeeIdCard;
    formValues.status = 1;

    var result = addDependi(formValues);
    setTimeout(() => {
      props.history.push('/dependient');
    }, 1500);

    setNameValid(true);
    setSurnameValid(true);
  };

/*
  useEffect(() => {
    const getListPermit = async () =>{
      const response = await getPermitType();
      setPermit(response.data);
  }
    getListPermit();
  }, [])
*/

  return (
    <Container className="container">
      <div className="row">
        <div className="col-12">
          <br />
          <h2 className="h2" Style={"text-align:center"}>
            &nbsp;CREAR&nbsp;DEPENDIENTE
          </h2>
          <hr />
        </div>
      </div>
      <form className="container" onSubmit={(e) => e.preventDefault()}>
        
        <div  className="form-group">
            <label className="classspan text-left">Nombre del dependiente</label>
            <input
            type="text"
            className={`form-control ${!name && "is-invalid"}`}
            value={firstname}
            name="firstname"
            onChange={handleInputChange}
            style={{
                resize: "none",
              }}
          />
          </div>

        <div  className="form-group">
            <label className="classspan text-left">Apellido del dependiente</label>
            <input
            type="text"
            className={`form-control ${!surnameValid && "is-invalid"}`}
            value={surname}
            name="surname"
            onChange={handleInputChange}
            style={{
                resize: "none",
              }}
          />
          </div>

        <div className="form-group">
          <label className="classspan text-left">Género</label>
          <select className="form-select" name="gender" value={gender} onChange={handleInputChange}>
              <option>Seleccione una opción</option>
              <option value="M">Masculino</option>
              <option value="F">Femenino</option>
          </select>
        </div>

        <div  className="form-group">
            <label className="classspan text-left">Fecha de Nacimiento</label>
            <input
            type="date"
            className="form-control"
            value={birthdate}
            name="birthdate"
            onChange={handleInputChange}
            style={{
                resize: "none",
              }}
          />
          </div>

        <button type="submit" className="btn btn-danger btn-block" onClick={handleSubmitForm}>
          <i className="fa fa-save" style={{color:'white'}}></i>
          <span style={{color:'white'}}> Guardar</span>
        </button>
      </form>
    </Container>
  );
}
