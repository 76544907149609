import React from 'react'
import ContactDirectory from '../../components/Contact_Us/contact_directory';

export default function ContactDir(props) {
    
    return (
        <div>
             <ContactDirectory props={props} />
        </div>
    )
}