import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import { Accordion, Card } from "react-bootstrap";
import { getUser, removeUserSession } from "../../utils/Common";

import "bootstrap/dist/css/bootstrap.min.css";

const FaqServices = ({ props }) => {
  const [faq, setFaq] = useState([]);

  useEffect(() => {

    API.getData("Faq/get?companyId=" + getUser().companyId)

      .then((response) => {
        setFaq(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },[]);

  return (
    <>
      <Accordion>
        {faq.map((item) => (
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={item.id}>
            <div className="row justify-content-center">
              <p  className="text-justify" >{item.question}</p>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={item.id}>
              <Card.Body>
                {
                  <center>
                    
                    <div
                      key={item}
                      dangerouslySetInnerHTML={{ __html: item.answer }}
                    />
                  </center>
                }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </>
  );
};

export default FaqServices;
