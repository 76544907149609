import React, { useState, useEffect } from "react";
import Button from "../Button/Button";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import $ from 'jquery';
import Moment from "moment";
import es from "date-fns/locale/es";
import "moment/locale/es";
import { getUser, removeUserSession } from "../../utils/Common";
import API from "../../utils/api";
import { makeStyles,createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Loading from "../loading/loading";
import VacationsAdd  from "./vacationsAdd";
import {GetAmountBetweenDays, GetDefaultDate, GetWeekendDays, SetVacationList} from './vacationCommon';
import {ShowConfirmationMessage, MessageResults} from "../../utils/CommonFunctions";

registerLocale("es", es);

const VacationDetails = (props)=> {

    const [availableDays, setAvailableDays] = useState((getUser().vacationPendingDays === null? 0: getUser().vacationPendingDays));
    const [availableLegalDays, setAvailableLegalDays] = useState((getUser().vacationDays === null? 0: getUser().vacationDays));
    const [listVacations, setListVacations] = useState([]);
    const [addVacation, setAddVacation] = useState(true);
    const [userInfo,setUserInfo] = useState(props.User);
    const [rows, setRows] = useState([]);
    const useStyles = makeStyles({ root: {  width: '100%', }, container: {  maxHeight: 440, },});
    const theme = createMuiTheme({ palette: { primary: { main: '#1976d2' }, }, }, esES);    
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5); 
    const [disabledBtn, setDisabledBtn] = useState(false);
    const columns = [    
                   
                { id: 'creationDate', label: 'Fecha\u00a0de\u00a0Solicitud', minWidth: 190, align: 'left' },
                { id: 'holidaysTaken', label: 'Días\u00a0Solicitados', minWidth: 100, align: 'center'},
                { id: 'fromDate', label: 'Desde', minWidth: 120, align: 'left' },
                { id: 'toDate', label: 'Hasta', minWidth: 120, align: 'left' },
                { id: 'status', label: 'Estado', minWidth: 120, align: 'left' },
                { id: 'btn', label: ' ', minWidth: 80, align: 'left' },

            ];
    const [dataLoading, setDataLoading] = useState(true); 
  const  createData = (id, creationDate, holidaysTaken,fromDate, toDate, status, btn)=> {
    return { id, creationDate, holidaysTaken,fromDate, toDate, status, btn };
  }
 const EditVacation = ()=>{
   alert('editar');
 }
 const RemoveVacation = (e)=>{
    ShowConfirmationMessage(DeleteVacation, '', $(e.currentTarget).attr('data-item'));
    
 }
 const DeleteVacation = (IdRecord)=>{
   if(IdRecord != undefined){
       API.deleteData("vacation/Delete?id=" + IdRecord)
      .then((res) => {
          if (res.status === 200) {
            MessageResults(res.status);
              setTimeout(() => {
                window.location.reload(true);
              }, 1200);           
          } 
      })
      .catch(function (err) {
      console.error("Error de conexion " + err);
          MessageResults(400, err);
      });  

   }
  
 }   
async  function GetData(){
await API.getData("/Vacation/GetbyEmployeeNumber?employeeNumber="+ getUser().employeeIdCard.trim() + "&companyId=" + getUser().companyId )
      .then((res) => {
        if (res.status === 200) {
          let dataResult = [];
          let DaysRemaining=0;
          SetVacationList(res.data);
            res.data.forEach(item => { 
              if(item.isActive === true){
                  let btn = (item.vacationRequestStatus.cssClass ==='status-requested'? true : '');     
                  if(item.vacationRequestStatus.cssClass ==='status-requested'|| item.vacationRequestStatus.cssClass === "status-approved"){
                    DaysRemaining = DaysRemaining + item.holidaysTaken;
                  }                
                  dataResult.push(
                      createData(item.vacationRequestId, 
                      Moment(item.creationDate).format("DD/MM/YYYY hh:mm A"),
                      item.holidaysTaken,Moment(item.dateFrom).format("DD/MM/YYYY"),
                      Moment(item.dateTo).format("DD/MM/YYYY"),
                      <div dangerouslySetInnerHTML={{__html: '<span class="' + item.vacationRequestStatus.cssClass + '">' + item.vacationRequestStatus.vacationRequestName + '</span>'}}></div>,
                      (btn !==''? <div dangerouslySetInnerHTML={{__html: '<span class="">' + btn + '</span>'}}></div> : '') )
                      ); 
              }

          });
          setRows(dataResult)
          debugger
          setAvailableDays(availableDays - DaysRemaining)
          if((availableDays - DaysRemaining) == 0){
            setDisabledBtn(true);
          }
          document.getElementById("tbAvailabledaysAdd").value = (availableDays - DaysRemaining);
        } 
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
        setDataLoading(false);
      });  
 
  setDataLoading(false);

}
  const AddVatations = ()=>{
    setAddVacation(false);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };  

  useEffect(() => {
     GetData();
      if(availableDays == "0"){
        setDisabledBtn(true);
      }
  }, []);    
    
    return (
      <>
        <div hidden={!addVacation}>
            <br />
            <div className=" htmlPayroll table-responsive container">
              <div className="row">
                <div className="lowcolor col-12">
   
                  <h2 className="h2" Style={"text-align:center"}>Solicitar&nbsp;Vacaciones</h2>

                  <hr />
                </div>
              </div>        
              <section>
                  <div className="form-row">
                      <div className="form-group col-md-3">
                        <label htmlFor="tbHiredDate">Fecha&nbsp;Contrataci&oacute;n</label>
                        <input type="text" className="form-control not-allowed custom-color " id="tbHiredDate" value={Moment(userInfo.hiringDate).format("L")}   readOnly />
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="tbAvailableLegalDays">D&iacute;as&nbsp;Correspondientes</label>
                        <input type="text" className="form-control not-allowed" id="tbAvailableLegalDays" value={availableLegalDays}  readOnly />
                      </div>                                  
                      <div className="form-group col-md-3">
                        <label htmlFor="tbAvailabledays">D&iacute;as&nbsp;Disponibles</label>
                        <input type="text" className="form-control not-allowed" id="tbAvailabledays" value={availableDays}  readOnly />
                      </div>
                      <div className="form-group col-md-3">
                      <label htmlFor="btnAdd">&nbsp;</label>
                      <div className="">
                        <div className="input-group mb-2">
                            <Button
                              isDisabled={disabledBtn ?? false}
                              type="btn-outline-danger"
                              text="Solicitar Vacaciones"
                              clickHandler={AddVatations}
                            />
                        </div>
                      </div>
      
                      </div>                
                  </div>
              </section>        
              <div className="row">
                <div className="lowcolor col-12">
                  <span className="custom-color">Resumen</span>  
                  <hr />
                </div>
              </div>
                  <div className=" table-responsive">
                      {!dataLoading ?
                          <div id="dv_vacation" className="">
                              {/* <span>No tiene solicitudes que mostrar</span> */}
                              <ThemeProvider theme={theme}>
                                  <Paper className={classes.root}>
                                  <TableContainer className={classes.container + " capitalized "}>
                                      <Table  aria-label="sticky table">
                                      <TableHead>
                                          <TableRow>
                                          {columns.map((column) => (
                                              <TableCell
                                              key={column.id}
                                              align={column.align}
                                              style={{ minWidth: column.minWidth }}
                                              >
                                              <strong>{column.label}</strong>
                                              </TableCell>
                                          ))}
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                          return (
                                              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                              {columns.map((column) => {
                                                  const value = row[column.id];
                                                  return (
                                                  <TableCell key={column.id} align={column.align}>
                                                      {(column.id === 'btn' && typeof(row.btn) !==undefined && row.btn !==	""?  
                                                        <span>
                                                           {/* <a href='#' id='btEdit' data-item={row.id} className='fa fa-pencil-square-o custom-color size-effect-x2' onClick={EditVacation} title='Editar' ></a>&nbsp;&nbsp; */}
                                                           <a href='#' id='btDelete'  data-item={row.id} className='fa fa-trash red size-effect-x2'onClick={RemoveVacation}  title='Eliminar' ></a>
                                                           </span>
                                                      : (column.format && typeof value === 'number' ? column.format(value) : value)) }
                                                  </TableCell>
                                                  );
                                              })}
                                              </TableRow>
                                          );
                                          })}
                                      </TableBody>
                                      </Table>
                                  </TableContainer>
                                  <TablePagination
                                      rowsPerPageOptions={[5, 10, 25, 100]}
                                      component="div"
                                      count={rows.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onChangePage={handleChangePage}
                                      onChangeRowsPerPage={handleChangeRowsPerPage}
                                  />
                                  </Paper>
                              </ThemeProvider>                        
                          </div>
                      :
                      <div className="container">
                        <Loading />  
                      </div>
                      
                      }
                </div>


            <br />
            <br />

            </div>
            <br />
            <br />
        </div>
        <div hidden={addVacation}>
            <VacationsAdd  daysPending={availableDays}  props={props} validation={addVacation} />
            
        </div>                  

     </>
    )
}
export default VacationDetails;