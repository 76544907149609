import React from 'react'
import VacationAdministrator from '../../components/admin/vacationAdmin';
import { getUser } from '../../utils/Common';


export default function VacationAdmin(props) {
    const usre = getUser();
    
    return (
        <div>
             <VacationAdministrator User={usre} />
        </div>
    )
}