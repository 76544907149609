import React, { useState, useEffect } from "react";
import API from "../../utils/apiadmin";
import $ from "jquery";
import {
  getAdmToken,
  setAdmSession,
  getAdmUser,
  removeAdmUser,
} from "../../utils/Common";
import Button from "../../components/Button/Button";
import { registerLocale } from "react-datepicker";
import Moment from "moment";
import es from "date-fns/locale/es";
import "moment/locale/es";
import Loading from "../../components/loading/loading";
import {
  ShowConfirmationMessage,
  MessageResults,
  ShowAlertMessage,
  GetImagePatch,
} from "../../utils/CommonFunctions";
import { DataTable } from "datatables.net";
import { LangSpanish } from "../../tools/dataTables.Language";

registerLocale("es", es);

$(document).ready(() => {
  removeAdmUser();
  $("body").on("click", "#TblUserAdm #btReset", (e) => {
    OnClickReset(e);
  });
});
const OnClickReset = (e) => {
  ShowConfirmationMessage(ResetUser, "", e);
};

const ResetUser = (e) => {
  if (getAdmToken()) {
    let dataParam = JSON.parse(atob($(e.currentTarget).attr("data-item")));
    let alterVal = $("#tbAlterPsw").val();
    dataParam.password =
      alterVal.trim() !== "" ? alterVal.trim() : dataParam.employeeIdCard;
    API.putData("User/Reset", dataParam)
      .then((res) => {
        if (res.status === 200) {
          MessageResults(res.status);
          $("#tbAlterPsw").val("");
          $("#btnSearch").click();
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
        MessageResults(400, err);
      });
  } else {
    window.location.reload();
  }
};

export default function ServiceDescAdm(props) {
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    API.postData("AuthBackend/LoginBackend", {
      username: username.value,
      password: password.value,
    })
      .then((response) => {
        setLoading(false);
        setAdmSession(response.data.token, response.data.user);
        setUserToken(getAdmToken() ? true : false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response === undefined) {
          setError("Error: Error de Conexión");
        } else {
          if (error.response.status === 401) {
            setError("¡Verifique usuario y/o contraseña!");
            document.getElementById("tbUser").focus();
          } else setError("Error: " + error.message);
        }
      });
  };

  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };
  const [userToken, setUserToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const username = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  const fillData = () => {
    if (getAdmToken()) {
      let employeeIdCard = $("#tbEmployeeIdCard").val();
      let defaultData = [
        {
          id: "",
          employeeFullName: "",
          employeeIdCard: "",
          CompanyName: "",
          psw: "",
          lastLogin: "",
          itemBtn: "",
          changePass: "",
        },
      ];
      API.getData(
        "/User/GetUserbyEmployeeIdCard?employeeIdCard=" + employeeIdCard
      )
        .then((res) => {
          setDataLoading(false);
          if (res.status === 200) {
            $("#spNotRecord").text("");
            let dataResult = [];
            res.data.forEach((item) => {
              let changePass =
                item.changePassword !== null ? item.changePassword : "";
              changePass =
                changePass === "X"
                  ? "<i class='fa fa-thumbs-o-up green' ></i>"
                  : "<i class='fa fa-thumbs-down red'></i>";
              dataResult.push({
                employeeFullName:
                  '<span class="capitalized defaultText" data-id="' +
                  item.id +
                  '">' +
                  item.firstName +
                  " " +
                  item.lastName +
                  "</span>",
                employeeIdCard:
                  '<span class="capitalized defaultText">' +
                  item.employeeIdCard +
                  "</span>",
                changePsw:
                  '<span class="d-flex align-items-center justify-content-center" title="Cambiar Clave?">' +
                  changePass +
                  "</span>",
                CompanyName:
                  '<span class="d-flex align-items-center justify-content-center"  title="' +
                  item.CompanyName +
                  '"><img src="' +
                  GetImagePatch("/images/" + item.logo) +
                  '"  class="img-user-grid"  alt="Logo" /></span>',
                psw:
                  '<span class="capitalized defaultText">' +
                  item.password.substr(0, 20) +
                  "..." +
                  "</span>",
                lastLogin:
                  '<span class="defaultText">' +
                  (item.lastLogin !== null
                    ? Moment(item.lastLogin).format("DD/MM/YYYY hh:mm A")
                    : "") +
                  "</span>",
                itemBtn:
                  "<span  id='btReset'  class='d-flex align-items-center justify-content-center btn btn-success size-effect defaultText' data-item=" +
                  btoa(JSON.stringify(item)) +
                  "  title='Resetear' ><i class='fa fa-refresh '></i></span>",
              });
            });

            $("#TblUserAdm").DataTable({
              destroy: true,
              searching: false,
              language: LangSpanish,
              lengthMenu: [8, 20, 40, 60, 80, 90, 100, 200],
              lengthChange: false,
              order: [[0, "desc"]],
              data: dataResult.length === 0 ? defaultData : dataResult,
              columns: [
                {
                  data: "employeeIdCard",
                  title: "ID\u00a0Usuario",
                  width: "10%",
                  className: "capitalized",
                },
                {
                  data: "employeeFullName",
                  title: "Nombre",
                  width: "40%",
                  className: "capitalized",
                },
                {
                  data: "psw",
                  title: "Contraseña",
                  width: "30%",
                  className: "capitalized",
                },
                {
                  data: "changePsw",
                  title: "Cambiar?",
                  width: "10%",
                  className: "capitalized",
                },
                {
                  data: "CompanyName",
                  title: "Compañía",
                  width: "10%",
                  className: "capitalized",
                },
                {
                  data: "lastLogin",
                  title: "Ultimo\u00a0Login",
                  width: "20%",
                  className: "capitalized",
                },
                {
                  data: "itemBtn",
                  title: "Resetear",
                  width: "3%",
                  orderable: false,
                },
              ],
            });
            $(".csHidden").attr("style", "display:none");
          }
        })
        .catch(function (err) {
          $("#spNotRecord").text(err.response.data.Message);
          console.error("Error de conexion " + err);
        });
      setDataLoading(false);
    } else {
      window.location.reload();
    }
  };
  const searchUser = () => {
    $("body").on("click", "#btnSearch", (e) => {
      setDataLoading(true);
      setTimeout(() => {
        fillData();
      }, 200);
    });
  };

  useEffect(() => {
    searchUser();
  }, []);

  return (
    <>
      {" "}
      {!userToken ? (
        <div className="login_container login-service-desk-adm">
          <h4 className="text-center login-service-desk-adm">
            ADMINISTRADOR DE USUARIOS
          </h4>
          <div id="myform" className="form_container">
            <hr />
            <div className="form_group">
              <label className="classspan login-service-desk-adm">
                Usuario
              </label>

              <input
                id="tbUser"
                type="text"
                {...username}
                className="txtbox textblack"
                placeholder="ejemplo: 00000000000"
              />
            </div>
            <div className="form_group">
              <label className="classspan login-service-desk-adm">
                Contraseña
              </label>
              <input
                type="password"
                {...password}
                className="txtbox textblack"
                placeholder="********"
              />
            </div>
            {error && (
              <>
                <small style={{ color: "red" }}>{error}</small>
                <br />
              </>
            )}
            <br />
            <div className="form_group">
              <button
                type="button"
                className="mybt btn btn-outline-danger text-wrap"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? "Cargando..." : "ENTRAR"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {userToken ? (
        <div id="divTbl">
          <div>
            <br />
            <div className=" table-responsive container">
              <div className="row">
                <div className="lowcolor col-12">
                  <h2 className="h2">
                    <i class="fa fa-users"></i>&nbsp;Usuarios
                  </h2>
                  <br />

                  <div class="col-12">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-12">
                        <span>
                          <strong>Cédula&nbsp;Empleado:</strong>
                        </span>
                        &nbsp;
                        <i
                          className="fa fa-exclamation-circle green"
                          title="Digite la Cédula sin guiones ni espacio"
                        ></i>
                        <input
                          type="text"
                          className="form-control"
                          id="tbEmployeeIdCard"
                          placeholder="Digite Cédula completa"
                        />
                      </div>
                      <div class="col-lg-8 col-md-8 col-sm-12  pull-left">
                        <br />
                        <span id="btnSearch" className="btn btn-danger">
                          <i class="fa fa-search"></i>&nbsp;Buscar
                        </span>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-12">
                        <span>
                          <strong>Usar&nbsp;Clave&nbsp;Alterna:</strong>
                        </span>
                        &nbsp;
                        <i
                          className="fa fa-exclamation-circle green"
                          title="Use este campo en caso de que quiere usar una clave personalizada"
                        ></i>
                        <input
                          type="text"
                          className="form-control"
                          id="tbAlterPsw"
                          placeholder="********"
                        />
                      </div>
                      <div class="col-lg-9 col-md-9 col-sm-12"></div>
                    </div>
                  </div>

                  <hr />
                  <span id="spNotRecord"></span>
                </div>
              </div>
              <div className=" table-responsive">
                {!dataLoading ? (
                  <>
                    <div className="container">
                      <div className="">
                        {!dataLoading ? (
                          <>
                            <div
                              className="table-responsive"
                              Style="min-height:700px"
                            >
                              <table
                                id="TblUserAdm"
                                className="table table-striped table-bordered"
                                Style="width:100% !important"
                              ></table>
                              <div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <a
                                  className="btn btn-danger"
                                  href="/ServiceDescAdm"
                                >
                                  <i class="fa fa-arrow-left"></i>&nbsp;Regresar
                                </a>
                              </div>
                            </div>
                          </>
                        ) : (
                          <Loading />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="container">
                    <Loading />
                  </div>
                )}
              </div>

              <br />
              <br />
            </div>
            <br />
            <br />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
