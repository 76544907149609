import Button from "../../components/Button/Button";
import React, { useState, useEffect } from "react";
import $, { param } from "jquery";
import "moment/locale/es";
import API from "../../utils/api";
import Moment from "moment";
import Loading from "../../components/loading/loading";
import { LangSpanish } from "../../tools/dataTables.Language";
import { getUser, removeUserSession } from "../../utils/Common";
import { getDependient } from "../action/dependient";



export default function Dependient(props) {
  const [dataLoading, setDataLoading] = useState(true);
  const [permission, setPermission] = useState(true);
  const [dependient, setDependient] = useState([{}]);


  var employeeId = getUser().employeeIdCard;

  useEffect(() => {
    const getListDependient = async () =>{
      const response = await getDependient(employeeId);
      setDependient(response.data);
  }
  getListDependient();
  setDataLoading(false);
  }, [])

  const onButtonClick = () => {
    props.history.push("/addDependiente");
  };

  return (
    <div className=" htmlMyrequests table-responsive container">
      <section>
        <br />
        <div className="row">
          <div className="col-12">
            <br />
            <h2 className="h2" Style={"text-align:center"}>
              &nbsp;DEPENDIENTE
            </h2>
            <hr />
          </div>
        </div>
      </section>
      <div className="row">
       <div className="col-lg-10 col-md-10 col-sm-8"></div>
        <div className="col-lg-2 col-md-2 col-sm-4">
          <div className="mb-2">
            <Button
              type="btnRequest btn-outline-danger text-wrap most-visited-log"
              data_prefix="myrequest/solicitarcarta"
              text="Crear dependiente"
              clickHandler={onButtonClick}
            />
            <br />
          </div>
        </div>
        {!dataLoading ? (
          <>
            <div className="container">
              <div className="">
                {!dataLoading ? (
                  <div
                    className="scroll-table bordered"
                    Style="min-height:600px"
                  >
                    {Array.isArray(dependient)
                        ? dependient.map(data => (
                            data.status === 1 ?(
                                <div class="card">
                                <div class="card-header">
                                    <img src="https://media.istockphoto.com/vectors/children-icon-vector-id1178790725?k=20&m=1178790725&s=170667a&w=0&h=xhoOk5cniFDnZUJDauo_DTtAfzkktBcJ3xNvskKyhiQ=" alt="Profile Image" class="profile-img"/>
                                </div>
                                <div class="card-body" id="parrafo">
                                    <h4>
                                    <p class="name">{data.firstname + " " + data.surname}</p>
                                    <p class="job">Dependiente</p>
                                    </h4>
                                    <h4 id="p-2">
                                    <p class="name">{(data.gender === "M" ? "Masculino" : "Femenino")}</p>
                                    <p class="job">Género</p>
                                    </h4>

                                </div>
                                <div class="card-body">
                                <h4 id="p-3">
                                    <p class="name">{Moment(data.birthdate).format("DD/MMM/YYYY  ")}</p>
                                    <p class="job">Fecha de nacimiento</p>
                                    </h4>
                                </div>
        
                                <div class="card-footer">
                                    
                                </div>
                            </div>
                            ):null
                        ))
                    : null}
                    
                    </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="container">
            <Loading />
          </div>
        )}

        <br />
        <br />
      </div>
    </div>
  );
}
