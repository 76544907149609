 
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./carousel_banner.css";
import CarouselServices from "../../services/carousel/carousel.services";

export default class CarouselBanner extends React.Component {
  render() {
 
    const getConfigurableProps = () => ({
      showArrows: true,
      slide:true,
      showStatus: false,
      showIndicators: false,
      infiniteLoop: true,
      showThumbs: false,
      useKeyboardArrows: true,
      autoPlay: true,
      stopOnHover: true,
      swipeable: true,
      dynamicHeight: true,
      emulateTouch: true,
      interval: 3000,
      transitionTime: 150,
      swipeScrollTolerance: 5,
    }); 

    return <CarouselServices props={getConfigurableProps} />;
 
  }
}