import React from "react";
import { NumbersWithComma } from "../../utils/CommonFunctions";
import {getUser} from '../../utils/Common';

export default function MyCompensation() {

  return (
    <section id="main" className="">
        <br />
        <br />
      <div className="container">
      <h2 Style={"text-align:center"}>Mi Compensacion</h2>
      <br />
      <br />
        <section>
          <header className="container">
            <div className="important-jumbotron">
            <div className="container">
              <h5 className=" text-justify">
               <i className="fa fa-exclamation-triangle faa-flash animated faa-slow red"><b>&nbsp;Importante!!!</b></i>
              </h5>
              <p className=" text-justify">
                Los datos aquí suministrados son aproximados y estimados.
                Exclusivamente para consumo individual del colaborador. En
                ningún caso podrá ser utilizado como cifras oficiales ante
                instituciones externas a la empresa, ni ser considerados como
                definitivos.
              </p>
              </div>
            </div>
          </header>
          <div>
          <div className="">     
            <div className="container">
              <div className="body-details-search">
              <div className="row">
                      <div className="col-md-6">
                      <h6>
                      &nbsp;Salario
                      </h6>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-2">
                      <span><b>&nbsp;{NumbersWithComma(getUser().salary)}</b>&nbsp;</span>
                      </div>
                </div>
                <hr />
                <div className="row">
                      <div className="col-md-6">
                      <h6>
                      &nbsp;Prima Vacacional
                      </h6>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-2">
                        <span>
                        <b>&nbsp;{getUser().vacationPremium
                            ? NumbersWithComma(getUser().vacationPremium)
                            : "0.00"}</b>&nbsp;
                        </span>
                      </div>
                </div>
                <hr />
                <div className="row">
                      <div className="col-md-6">
                      <h6>
                      &nbsp;Bono Vacacional
                      </h6>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-2">
                      <span>
                      <b>&nbsp;{getUser().vacationBonus
                          ? NumbersWithComma(getUser().vacationBonus)
                          : "0.00"}</b>&nbsp;
                      </span>
                      </div>
                </div>
                <hr />
                <div className="row">
                      <div className="col-md-6">
                      <h6>
                      &nbsp;Regal&iacute;a (A la fecha)
                      </h6>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-2">
                      <span><b>&nbsp;{getUser().royalty ? NumbersWithComma(getUser().royalty) : "0.00"}</b>&nbsp;</span>
                      </div>
                </div>
                <hr />
                <div className="row">
                      <div className="col-md-6">
                      <h6>
                      &nbsp;Bonificaci&oacute;n (A la fecha)
                      </h6>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-2">
                      <span><b>&nbsp;{getUser().bonus ? NumbersWithComma(getUser().bonus) : "0.00"}</b>&nbsp;</span>
                      </div>
                </div>
                <hr />                   
              </div> 
                                             

              </div>         
              </div> 
 


          </div> 
        </section>
      </div>
    </section>
  );
}
