import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Moment from "moment";
import "moment/locale/es";
import es from "date-fns/locale/es";
import Button from "../Button/Button";
import BackButton from "../Button/BackButton";
import "react-datepicker/dist/react-datepicker.css";
import "./payroll.css";
import BuildTableWithData from "./payrollTable";
import { getUser, removeUserSession } from "../../utils/Common";
import API from "../../utils/api";
import { ShowAlertMessage } from "../../utils/CommonFunctions";

registerLocale("es", es);

const PayrollServices = ({ props }) => {
  const [listPayroll, setListPayroll] = useState([]);
  const [resultPayroll, setResultPayroll] = useState(true);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());

  const clickSearch = () => {
    API.getData(
      "/PayRoll/GetbyEmployeeAndDateRange?employeeNumber=" +
        getUser().employeeIdCard.trim() +
        "&companyId=" +
        getUser().companyId +
        "&dateTimeFrom=" +
        Moment(startDate).format("YYYY-MM-DD") +
        "&dateTimeTo=" +
        Moment(endDate).format("YYYY-MM-DD") +
        "&level=" +
        getUser().level
    )
      .then((res) => {
        if (res.status === 200) {
          setListPayroll(BuildTableWithData(res));
          setResultPayroll(true);
        } else {
          setResultPayroll(false);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };
  const GetEvaluation = () => {
    API.getData(
      "/NewEvaluation/GetNewEvaluationCalendarId?companyId=" +
        getUser().companyId +
        "&evaluationCalendarId=6&employeeNumber=" +
        getUser().employeeIdCard +
        "&evaluarAnual=" +
        getUser().hiringDate +
        "&fechaActual=" +
        Moment(new Date()).format("YYYY-MM-DD") +
        "&level=" +
        getUser().level
    )
      .then((res) => {
        if (res.data != "") {
          debugger;
          localStorage.setItem("EvaluationPending", "true");
          ShowAlertMessage("Información", "DEBE COMPLETAR LA ENCUESTA", "info");
          props.history.push("/new_evaluation?id=6");
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };

  useEffect(() => {
    //  if (getUser().companyId != "01") {
    GetEvaluation();
    //  }
    clickSearch();
  }, []);

  return (
    <>
      <br />
      <div className=" htmlPayroll  table-responsive container">
        <div className="row">
          <div className="lowcolor col-12">
            <h2 className="h2" Style={"text-align:center"}>
              Periodo de Pago
            </h2>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-group-prepend">
              <div className="input-group-text mb-2">
                Seleccione&nbsp;el&nbsp;rango&nbsp;de&nbsp;fecha&nbsp;que&nbsp;desea&nbsp;visualizar
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">Desde:</div>
              </div>
              <DatePicker
                id="dtFrom"
                readonly={false}
                className=" reactCalendar"
                relativeSize={true}
                popperPlacement="auto-right"
                selected={startDate}
                locale="es"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setStartDate(date)}
                peekNextMonth
                showMonthDropdown
                dropdownMode="select"
                useShortMonthInDropdown
                showYearDropdown
              />
              <div className="input-group-prepend calendarCs">
                <div className="notAllowed">
                  <span
                    class="iconify"
                    data-icon="fa:calendar"
                    data-inline="false"
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">Hasta:&nbsp;</div>
              </div>
              <DatePicker
                readonly={false}
                className=" reactCalendar"
                relativeSize={true}
                popperPlacement="auto-right"
                id="dtTo"
                selected={endDate}
                minDate={startDate}
                locale="es"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setEndDate(date)}
                peekNextMonth
                showMonthDropdown
                dropdownMode="select"
                useShortMonthInDropdown
                showYearDropdown
              />
              <div className="input-group-prepend calendarCs">
                <div className="notAllowed">
                  <span
                    className="iconify"
                    data-icon="fa:calendar"
                    data-inline="false"
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 col-sm-12">
            <div className="input-group mb-2">
              <Button
                type="btn-outline-danger"
                text="BUSCAR"
                clickHandler={clickSearch}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="lowcolor col-12">
            <span className="custom-color">Resultados</span>
            <hr />
          </div>
        </div>

        <div className="">
          {resultPayroll ? (
            <Accordion defaultActiveKey="0">
              {listPayroll.map((item) => (
                <Card key={item.id}>
                  <Accordion.Toggle
                    className="panel-heading"
                    as={Card.Header}
                    eventKey={item.id}
                  >
                    <strong>
                      {Moment(item.payrollDate).format("YYYY-MM-DD")}
                    </strong>
                    <span className="arrowAcordion">
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={item.id}>
                    <Card.Body>
                      <div className="accordion">
                        <div
                          dangerouslySetInnerHTML={{ __html: item.itemData }}
                        />
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          ) : (
            <>
              <div className="container d-flex align-items-center justify-content-center">
                <span className="custom-color">No hay data disponible.</span>
              </div>
            </>
          )}
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default PayrollServices;
