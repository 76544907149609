import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import Swal from "sweetalert2";
import Button from "../../components/Button/Button";
import { getUser } from "../../utils/Common";
import { GetImagePatch } from "../../utils/CommonFunctions";

export default function LeadersOnBoard() {
  const [benefits, setbenefits] = useState([]);

  useEffect(() => {
    API.getData("BenefitsCategory/get?companyId=" + getUser().companyId)
      .then((response) => {
        if (response.status === 200) {
          setbenefits(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const valora_cuestionario = () => {
    var modal = document.getElementById("divModal");
    var puntaje = 50;
    var box = document.getElementsByTagName("input");
    var total = 0;
    var selecion = 0;

    for (var i = 0; i < box.length; i++) {
      if (box[i].type == "checkbox") {
        total++;
        if (box[i].checked == true) {
          selecion++;
        }
      }
    }
    var puntuacion = (selecion / total) * 100;
    puntaje = puntuacion;
    validar_check();
    moveRight(puntaje);
  };

  const moveRight = (puntaje) => {
    if (puntaje <= 50) {
      Swal.fire({
        title: "Ups!",
        html: "<br/> <br/><img src='https://static.wixstatic.com/media/f83393_4c545f9ea0be4d75a1c0ff1866220910~mv2.gif'  class='img-fluid' alt='Responsive image' /><br/> <br/>Estás dando vueltas para encontrar el camino. <br/>Busca ayuda en tú supervisor",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Puntos a mejorar!",
        confirmButtonColor: "#ed1c24",
        cancelButtonColor: "#444444",
        confirmButtonText:
          '<a href="javascript:history.go(0)" style="text-decoration: none !important;color:#fff !important;">Aterrizar</a>',
      });
    } else if (puntaje <= 60) {
      Swal.fire({
        title: "Ups!", 
        html: "<br/> <br/><img src='https://i1.wp.com/media.giphy.com/media/l4FGFECmMEWACQYhO/giphy.gif'  class='img-fluid' alt='Responsive image' /><br/> <br/> Estás camino al aeropuerto, continúa sin pausa.<br/>Revisa el listado, mira y escucha a tú gente, elije y agenda los próximos pasos",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Puntos a mejorar!",
        confirmButtonColor: "#ed1c24",
        cancelButtonColor: "#444444",
        confirmButtonText:
          '<a href="javascript:history.go(0)" style="text-decoration: none !important;color:#fff !important;">Aterrizar</a>',
      });
    } else if (puntaje <= 70) {
      Swal.fire({
        title: "Mejor!",
        html: "<br/> <br/><img src='https://1.bp.blogspot.com/-YOX4JZc6LyI/VUImLSyg9yI/AAAAAAAAzis/AFqApfK-9Xo/s1600/toy-story-72.gif'  class='img-fluid' alt='Responsive image' /><br/> <br/> Llegaste al aeropuerto, busca tu Boarding Pass y dirígete al counter. <br/>Entiende lo que te falta, y acción!",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Puntos a mejorar!",
        confirmButtonColor: "#ed1c24",
        cancelButtonColor: "#444444",
        confirmButtonText:
          '<a href="javascript:history.go(0)" style="text-decoration: none !important;color:#fff !important;">Aterrizar</a>',
      });
    } else if (puntaje <= 80) {
      Swal.fire({
        title: "Mucho mejor!",
        text: "<br/> <br/><img src='https://i.pinimg.com/originals/d5/27/8b/d5278ba20f554623235c6dfeec024b96.gif'  class='img-fluid' alt='Responsive image' /><br/> <br/> Has avanzado mucho, estás documentado, informado,  y necesitas cubrir lo importante. Te esperan!",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Puntos a mejorar!",
        confirmButtonColor: "#ed1c24",
        cancelButtonColor: "#444444",
        confirmButtonText:
          '<a href="javascript:history.go(0)" style="text-decoration: none !important;color:#fff !important;">Aterrizar</a>',
      });
    } else if (puntaje <= 90) {
      Swal.fire({
        title: "Excelente!",
        text: "<div><br/> <br/><img src='https://1.bp.blogspot.com/-BB_wUX-e29g/XxurrbGv1oI/AAAAAAAGUuA/EHXKg4zSiVYf607wkCWPlQP9SFqxNMziACLcBGAsYHQ/s640/BriskSerpentineIndochinahogdeer-size_restricted.gif'  class='img-fluid' alt='Responsive image' /><br/> <br/>Felicitaciones! Has trabajado duro, lo tienes casi todo, revisa las pequeñas cosas que harán de tu gestión, una experiencia memorable.</div>",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Puntos a mejorar!",
        confirmButtonColor: "#ed1c24",
        cancelButtonColor: "#444444",
        confirmButtonText:
          '<a href="javascript:history.go(0)" style="text-decoration: none !important;color:#fff !important;">Aterrizar</a>',
      });
    } else {
      Swal.fire({
        title: "Super excelente!",
        text: "<br/> <br/><img src='https://1.bp.blogspot.com/-BB_wUX-e29g/XxurrbGv1oI/AAAAAAAGUuA/EHXKg4zSiVYf607wkCWPlQP9SFqxNMziACLcBGAsYHQ/s640/BriskSerpentineIndochinahogdeer-size_restricted.gif'  class='img-fluid' alt='Responsive image' /><br/> <br/>Estás haciendo un trabajo Extraordinario. Tu gente te lo dice e innova contigo y estás disfrutando de los frutos. Estás dentro de DC2023!",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Puntos a mejorar!",
        confirmButtonColor: "#ed1c24",
        cancelButtonColor: "#444444",
        confirmButtonText:
          '<a href="javascript:history.go(0)" style="text-decoration: none !important;color:#fff !important;">Aterrizar</a>',
      });
    }
  };

  const cargar = ()=>{
    setTimeout(() => {
      window.location.reload(true);
    }, 1200);
  }
  const validar_check = () => {
    var box = document.getElementsByTagName("input");
    var lab01 = document.getElementById("ckl01");
    var lab02 = document.getElementById("ckl02");
    var lab03 = document.getElementById("ckl03");
    var lab04 = document.getElementById("ckl04");
    var lab05 = document.getElementById("ckl05");
    var lab06 = document.getElementById("ckl06");
    var lab07 = document.getElementById("ckl07");
    var lab08 = document.getElementById("ckl08");
    var lab09 = document.getElementById("ckl09");
    var lab10 = document.getElementById("ckl10");
    var lab11 = document.getElementById("ckl11");
    var lab12 = document.getElementById("ckl12");
    var lab13 = document.getElementById("ckl13");
    var lab14 = document.getElementById("ckl14");
    var lab15 = document.getElementById("ckl15");

    for (var i = 0; i < box.length; i++) {
      if (box[i].checked == false) {
        if (box[i].id == "ck01") {
          lab01.style.fontWeight = "600";
          lab01.style.color = "#ed1c24";
        } else if (box[i].id == "ck02") {
          lab02.style.fontWeight = "600";
          lab02.style.color = "#ed1c24";
        } else if (box[i].id == "ck03") {
          lab03.style.fontWeight = "600";
          lab03.style.color = "#ed1c24";
        } else if (box[i].id == "ck04") {
          lab04.style.fontWeight = "600";
          lab04.style.color = "#ed1c24";
        } else if (box[i].id == "ck05") {
          lab05.style.fontWeight = "600";
          lab05.style.color = "#ed1c24";
        } else if (box[i].id == "ck06") {
          lab06.style.fontWeight = "600";
          lab06.style.color = "#ed1c24";
        } else if (box[i].id == "ck07") {
          lab07.style.fontWeight = "600";
          lab07.style.color = "#ed1c24";
        } else if (box[i].id == "ck08") {
          lab08.style.fontWeight = "600";
          lab08.style.color = "#ed1c24";
        } else if (box[i].id == "ck09") {
          lab09.style.fontWeight = "600";
          lab09.style.color = "#ed1c24";
        } else if (box[i].id == "ck10") {
          lab10.style.fontWeight = "600";
          lab10.style.color = "#ed1c24";
        } else if (box[i].id == "ck11") {
          lab11.style.fontWeight = "600";
          lab11.style.color = "#ed1c24";
        } else if (box[i].id == "ck12") {
          lab12.style.fontWeight = "600";
          lab12.style.color = "#ed1c24";
        } else if (box[i].id == "ck13") {
          lab13.style.fontWeight = "600";
          lab13.style.color = "#ed1c24";
        } else if (box[i].id == "ck14") {
          lab14.style.fontWeight = "600";
          lab14.style.color = "#ed1c24";
        } else if (box[i].id == "ck15") {
          lab15.style.fontWeight = "600";
          lab15.style.color = "#ed1c24";
        }
      }
    }
  };

  return (
    <form id="regForm" action="">
      <br />
      <br />
      <center>
        <h4>Lideres abordo</h4>
      </center>{" "}
      <div id="divModal" class="modal">
        <div class="modal-content"></div>
      </div>
      <div id="divModal2" class="modal">
        <div class="modal-content"> </div>
      </div>
      <div class="inner maindiv">
        <div style={{ float: "left", width: "40px" }}>
          <img
            id="avion"
            src={GetImagePatch("/images/avion_la.png")}
            width="240px"
            height="86px"
            style={{ position: "relative", left: "100px", top: "495px" }}
          />
          <img
            id="logo"
            src={GetImagePatch("/images/logo_la.png")}
            width="165px"
            height="165px"
            style={{
              position: "relative",
              left: "130px",
              top: "0px",
            }}
          />
        </div>

        <div>
          <table id="demo" border="0" cellspacing="0">
            <tbody>
              <tr>
                <td>
                  <input type="checkbox" id="ck01" />

                  <label for="ck01" id="ckl01" style={{ fontWeight: "600" }}>
                    Aplico las políticas y procedimientos NUEV@S.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck02" />

                  <label for="ck02" id="ckl02" style={{ fontWeight: "600" }}>
                    Me visto profesionalmente según código de vestimenta.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck03" />

                  <label for="ck03" id="ckl03" style={{ fontWeight: "600" }}  >
                    Vivo y practico los valores de la empresa: Responsabilidad,
                    Honestidad, Excelencia, Respeto.
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck04" />

                  <label for="ck04" id="ckl04" style={{ fontWeight: "600" }}>
                    Conozco, recuerdo, participo la visión, misión, los
                    objetivos y pilares estratégicos.
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck05" />

                  <label for="ck05" id="ckl05" style={{ fontWeight: "600" }}>
                    Tanto yo como los colaboradores a mi cargo ponchan todos los
                    días al entrar y al salir de la empresa.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck06" />

                  <label for="ck06" id="ckl06" style={{ fontWeight: "600" }}>
                    Aplico el Governance suministrado realizando las
                    interacciones esperadas mediante gestión de métricas
                    concretas.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck07" />

                  <label for="ck07" id="ckl07" style={{ fontWeight: "600" }}>
                    El área que lidero, ha sido reconocida y premiada por 5S.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck08" />

                  <label for="ck08" id="ckl08" style={{ fontWeight: "600" }}>
                    Los colaboradores a mi cargo conocen y utilizan todos los
                    beneficios "Pensando en Ti".
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck09" />

                  <label for="ck09" id="ckl09" style={{ fontWeight: "600" }}>
                    Educo y aplico correctamente a través del Régimen
                    Disciplinario.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck10" />

                  <label for="ck10" id="ckl10" style={{ fontWeight: "600" }}>
                    Tanto yo como los colaboradores a mi cargo, disfrutan de
                    vacaciones.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck11" />

                  <label for="ck11" id="ckl11" style={{ fontWeight: "600" }}>
                    Educo sobre el Código Conducta Corripio, hago denuncias
                    cuando conozco de un incumplimiento.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck12" />

                  <label for="ck12" id="ckl12" style={{ fontWeight: "600" }}>
                    Participo junto a los colaboradores en actividades
                    recreativas de la empresa.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck13" />

                  <label for="ck13" id="ckl13" style={{ fontWeight: "600" }}>
                    Celebro los Uno a Uno con mis supervisados utilizando como
                    base las Competencias Conductuales provistas.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck14" />

                  <label for="ck14" id="ckl14" style={{ fontWeight: "600" }}>
                    Creo, desarrollo e implemento nuevas ideas con y junto al
                    equipo.
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" id="ck15" />

                  <label for="ck15" id="ckl15" style={{ fontWeight: "600" }} >
                    Promuevo y gestiono la promoción de los colaboradores a
                    cargo, motivando la capacitación y proponiéndolos ante
                    vacantes publicadas
                  </label>
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <center>
                    <button
                      type="button"
                      className="mybt btn btn-outline-danger text-wrap"
                      onClick={valora_cuestionario}
                    >
                      Despegar
                    </button>
                    <br />
                    <br />{" "}
                    <button
                      type="button"
                      className="mybt btn btn-outline-danger text-wrap"
                      onClick={cargar}
                    >
                      Reiniciar  
                    </button>
                  
                  </center>
              
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
}
