import React  from "react";
import { getUser } from "../../utils/Common";
import { NumbersWithComma } from "../../utils/CommonFunctions";

export default function MyBenefits() {
  return (
    <section id="main" className="wrapper">
      <div className="inner">
      <h2>Mis Beneficios</h2>
      <br />
      <br />
        <section>
          <div className="content2">
            <header>
              <h6>
                <b>Ultimos gastos</b>
              </h6>
              <p>
                {getUser().lastExpenses
                  ? NumbersWithComma(getUser().lastExpenses)
                  : "0.00"}
              </p>
              <hr />
              <h6>
                <b>Seguro de vida</b>
              </h6>
              <p>
                {getUser().lifeInsurance
                  ? NumbersWithComma(getUser().lifeInsurance)
                  : "0.00"}
              </p>
              <hr />
              <h6>
                <b>Seguro medico complementario</b>
              </h6>
              <p>
                {getUser().complementaryMedicalInsurance
                  ? getUser().complementaryMedicalInsurance
                  : "N/A"}
              </p>
            </header>
          </div>
        </section>
      </div>
    </section>
  );
}
