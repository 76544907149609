import API from "../../utils/api";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import Moment from "moment";
import "moment/locale/es";
import es from "date-fns/locale/es";
import {GetDateDiff} from "../../utils/CommonFunctions";

registerLocale("es", es);

export const GetAmountBetweenDays = () => {

    let dtFrom = document.getElementById('dtFrom');
    let dtTo = document.getElementById('dtTo');
    let counter = document.getElementById('tbDays');
        counter.setAttribute("max", document.getElementById('tbAvailabledaysAdd').value);
        
    if(dtFrom.length !== null & dtTo.length !== null){
        setTimeout(() => {
            let begingDate = '';
            let endDateVal = ''
            let diffDays  = 0;
            if(dtFrom.value.length ===10 && dtTo.value.length ===10){
                let Count = 0;
                begingDate = dtFrom.value;
                endDateVal = dtTo.value;
                begingDate = new Date(begingDate.substr(6,4)  + '-' + begingDate.substr(3,2) + '-' + begingDate.substr(0,2) + ' ');
                endDateVal  = new Date(endDateVal.substr(6,4)  + '-' + endDateVal.substr(3,2) + '-' + endDateVal.substr(0,2) + ' ');
                diffDays  = Math.round((endDateVal - begingDate)/(1000 * 60 * 60 * 24));
                let validation = new Date(begingDate);
                let holidays = GetAvailableHolidays();
                let counterHoliday=0;
                for (let idx = 0; idx <= diffDays; idx++) {
                    holidays.forEach(item => {  //Validate Holiday
                        if(item.currentFullHoliDay !== null){
                              const holiday = new Date(item.currentFullHoliDay).toISOString().slice(0,10);
                              if(validation.toISOString().slice(0,10) === holiday ){
                                counterHoliday ++;
                              }
                        }
              
                    });                      
                    switch (validation.toLocaleString('en-us', {weekday:'long'})) {
                        case "Saturday":
                            Count = Count + 0.5
                            break;
                        case "Sunday":
                            break;
                            default:
                                Count ++;
                            break;
                    } 
                    validation.setDate(validation.getDate() + 1);
                }
                counter.value = Count - counterHoliday;
       
                 
            }
        }, 200); 

   }else{
       counter.value = "0";
   }    

}
export  const GetWeekendDays = (beginDate)=>{
    let newDate = new Date(beginDate);
    let Count=0;
    let availableDays = document.getElementById("tbAvailabledaysAdd");
    let daysAmount = (availableDays !== null ? availableDays.value: "0");

    for (let idx = 0; idx < parseInt(daysAmount); idx++) {
      let holidays = GetAvailableHolidays();
      holidays.forEach(item => {  //Validate Holiday
          if(item.currentFullHoliDay !== null){
                const holiday = new Date(item.currentFullHoliDay).toISOString().slice(0,10);
                if(newDate.toISOString().slice(0,10) === holiday ){
                    newDate.setDate(newDate.getDate() + 1);
                }
          }

      });         
      switch (newDate.toLocaleString('en-us', {weekday:'long'})) {
          case "Saturday":
                newDate.setDate(newDate.getDate() + 3)
              break;
          case "Sunday":
                newDate.setDate(newDate.getDate() + 2)
              break;
              default:
                newDate.setDate(newDate.getDate() + 1)
              break;
      }
      Count ++;
  
    }
    if(Count >0){
       newDate.setDate(newDate.getDate() - 1) 
    }
    return GetDefaultDate(newDate);
  }

export const GetDefaultDate = (defaultDate)=>{
      switch (defaultDate.toLocaleString('en-us', {weekday:'long'})) {
          case "Saturday":
              defaultDate.setDate(defaultDate.getDate() + 2);
              break;
          case "Sunday":
              defaultDate.setDate(defaultDate.getDate() + 1);
              break;
      }

      return defaultDate;
      
}

export  const SetAvailableHolidays = ()=>{
    if(sessionStorage.getItem('holiday') === null){
        API.getData("Holiday/GetHolidaySorted")
        .then((res) => {
            if (res.status === 200) {
                sessionStorage.setItem('holiday', btoa(JSON.stringify(res.data)));
            } 
        })
        .catch(function (err) {
        console.error("Error de conexion " + err);
               sessionStorage.setItem('holiday', JSON.stringify([]));
        }); 
    }

}

export  const GetAvailableHolidays = ()=>{
    if(sessionStorage.getItem('holiday') !== null){
       return JSON.parse(atob(sessionStorage.getItem('holiday'))); 
    }else{ return []; }
}
export const SetVacationList = (data) => {
    let dataResult = [];
    for (let index = data.length - 1; index >= 0; index --) {
        dataResult.push({dateFrom: data[index].dateFrom, dateTo:data[index].dateTo, employeeNumber: data[index].employeeNumber});  
    }
    sessionStorage.setItem('vacationRequested', btoa(JSON.stringify(dataResult)));

}

export const GetVacationList = () => {
    if(sessionStorage.getItem('vacationRequested') !== null){
        let dataResult = [];
        let data = JSON.parse(atob(sessionStorage.getItem('vacationRequested')));
            for (let index = 0; index < data.length; index ++) {
                let betweenCount = GetDateDiff(new Date(data[index].dateFrom), new Date(data[index].dateTo));
                let newCurDate = new Date(data[index].dateFrom);
                for (let index = 0; index <= betweenCount; index++) {
                    dataResult.push({dateFrom: new Date(newCurDate)});
                    newCurDate.setDate(newCurDate.getDate() + 1);
                } 
            }      
        return dataResult; 
     }else{ return []; }

}
export  const GetExistinDateRange = (fromDate, toDate)=>{
      /* ************Step 1 Selected Range ************ */
  
      let betweenCount = GetDateDiff(fromDate, toDate);
      let selectedRangeDate = [];
      let dataHtml = [];
      let headerUl ="<div class='container d-flex align-items-center justify-content-center'><ul class='no-bullets'>";
      let newCurDate = fromDate;
      for (let index = 0; index <= betweenCount; index++) {
          selectedRangeDate.push({id:index, dtFrom: new Date(newCurDate)});
          newCurDate.setDate(newCurDate.getDate() + 1);
      }
      /* ************Step 1 Selected Range ************ */
      let ExistingDates = false; 
      /* ************Step 2 existing Selected Range ************ */  
        GetVacationList().forEach(item => {
           let counterDif = GetDateDiff(new Date(item.dateFrom), new Date(item.dateTo));
                selectedRangeDate.forEach(itemDate => {
                    let newDate = new Date(item.dateFrom);
                    if(Moment(newDate).format('YYYY-MM-DD') === Moment(itemDate.dtFrom).format('YYYY-MM-DD') ){
                        const dayName = new Date(newDate).toLocaleString('es', {weekday:'long'});
                        let cssClass ='';
                        let isWeekend = '<span class="red fa fa-commenting faa-flash animated faa-slow  size-effect-x2" title="Este día no afecta sus vacaciones, solo se muestra de modo informativo"></span>';
                        switch (dayName) {
                            case 'sábado':
                                cssClass = 'red';

                                break;
                            case 'domingo':
                                cssClass = 'red';
                                break;                        
                        
                            default:
                                cssClass = '';
                                isWeekend = ''
                                break;
                        }
        
                        let currentDateFormat = "<li><span class='capitalized "+ cssClass +"'>" + dayName + "</span> <span>" + Moment(new Date(newDate)).format("DD") + "</span>&nbsp;de<span class='capitalized'> " + new Date(newDate).toLocaleString('es', { month: 'long' }) + "</span> " + Moment(new Date(newDate)).format("YYYY") + "&nbsp;" + isWeekend + "</li>";           
                        dataHtml.push([currentDateFormat]);
                        ExistingDates = true;
                    } 
                    newDate.setDate(newDate.getDate() + 1)                    
                });             
 

        });

      /* ************Step 2 existing Selected Range ************ */     
      return {Status: ExistingDates, data: headerUl + dataHtml.join('') + "</ul></div>"};
}