import React, { useState, useEffect } from "react";
import { getUser, removeUserSession } from "../../utils/Common";
import API from "../../utils/api";

export default function MyBusiness() {
  const [company, setCompany] = useState([]);
  const [siteMenu, setSiteMenu] = useState([]);
  const GetMenu = (pathUrl) => {
    API.getData(
      "SiteMenu/GetbyMenuRole?companyId=" +
        getUser().companyId +
        "&isSupervisor=" +
        getUser().isSupervisor.toUpperCase()
    )
      .then((res) => {
        if (res.status === 200) {
          setSiteMenu(res.data.find((c) => c.PatchName == pathUrl));
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };
  const GetCompany = () => {
    API.getData("/Company/get?companyId=" + getUser().companyId)
      .then((res) => {
        if (res.status === 200) {
          setCompany(res.data);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };

  useEffect(() => {
    GetCompany();
    GetMenu(window.location.pathname.replace("/", ""));
  }, []);
  return (
    <div>
      <section className="wrapper">
        <div className="inner">
          <header className="special">
            {company.map((item) => (
              <h5>
                {" "}
                <div
                  className="highlights"
                  dangerouslySetInnerHTML={{ __html: item.mottoSecundary }}
                />{" "}
              </h5>
            ))}
          </header>
          {
            <div
              className="highlights"
              dangerouslySetInnerHTML={{ __html: siteMenu.TemplateHtml }}
            />
          }
        </div>
      </section>
    </div>
  );
}
