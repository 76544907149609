import React from "react";
import { GetImagePatch } from "../../utils/CommonFunctions";

export default function AppMovil(props) {

    const downloadLink = 'itms-services://?action=download-manifest&url=https://app.corripio.com.do/IOS2/manifest.plist';
    const handleDownload = () => {
        window.open(downloadLink, '_blank');
    };

    return (
        <div className="login_container">
            <h4 className="text-center">App Somos Corripio</h4>
            <div id="myform" className="form_container">
                <hr />
                <br />
                <br />
                <br />
                <br />
                <div>
                    <a href={downloadLink} id="text">
                        <img
                            src={GetImagePatch("/images/GDC.png")}
                            className="size-effect img-fluid"
                            alt=""
                        />
                    </a>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="form_group">
                    <button
                        type="button"
                        className="mybt btn btn-outline-danger text-wrap"
                        onClick={handleDownload}
                    >
                        {"DESCARGAR"}
                    </button>
                </div>
            </div>

        </div>
    );
}
