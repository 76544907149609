import React from 'react'
import AnnualCompensation from '../../components/AnnualCompensation/AnnualCompensation';
import { getUser, removeUserSession } from '../../utils/Common';


export default function AnnualCompensations(props) {
    const usre = getUser();

    const handleLogout = () => {
        removeUserSession();
        props.history.push('/login');
    }
    
    return (
        <div>
             <AnnualCompensation User={usre} />
        </div>
    )
}