import React from "react";
import FaqServices from "../../components/faq/faqServices";

export default class FaqView extends React.Component {
  render() {
    return (
      <>
        <section id="main" className="wrapper">
          <div className="inner">
            <center>
              <h5>Preguntas Frecuentes</h5>
            </center>
            <FaqServices />
          </div>
        </section>
      </>
    );
  }
}
