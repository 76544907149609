import React, { useState } from "react";
import API from "../../utils/api";
import Button from "../../components/Button/Button";
import {
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import { getUser, removeUserSession } from "../../utils/Common";
import Swal from 'sweetalert2';

export default function ChangePassword(props) {
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };

  const [loading, setLoading] = useState(false);
  const currentPassword = useFormInput("");
  const newPassword = useFormInput("");
  const confirmPassword = useFormInput("");

  const isStrongPassword = (password) => {
    // Añade tus criterios para una contraseña fuerte aquí.
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const change = () => {
    if (currentPassword.value === "") {
      ShowAlertMessage(
        "¡Mensaje de aviso!",
        " <p>La contraseña anterior no puede estar en blanco</p>"
      );
      return;
    }
    if (!isStrongPassword(newPassword.value)) {
      ShowAlertMessage(
        "¡Mensaje de aviso!",
        " <p>La contraseña debe ser compleja: al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial.</p>"
      );
      return;
    }
    if (newPassword.value !== confirmPassword.value) {
      ShowAlertMessage(
        "¡Mensaje de aviso!",
        " <p>Las contraseñas no coinciden, por favor verifique</p>"
      );
      return;
    }

    API.putData("User/change", {
      id: 0,
      employeeIdCard: getUser().employeeIdCard,
      password: currentPassword.value.toString(),
      changePassword: newPassword.value.toString(),
      LastLogin: null,
      HideCarnet: null
    })
      .then((response) => {
        Swal.fire({
          title: "¡Mensaje de aviso!",
          html: " <p>Contraseña Actualizada!</p>",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: `Ok`,
        }).then((result) => {
          if (result.value) {
            removeUserSession();
            window.location.reload(true)
          }
        })
      })
      .catch((error) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Contraseña actual no coincide</p>"
        );
        console.log(error);
      });
  };

  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <section>
          <center>
            <div id="myform" className="form_container">
              <div className="form_group">
                <label className="classspan text-left">Contraseña actual</label>
                <input
                  type="password"
                  className="txtbox"
                  {...currentPassword}
                />
              </div>
              <div className="form_group">
                <label className="classspan text-left">Nueva Contraseña</label>
                <input type="password" className="txtbox" {...newPassword} />
              </div>
              <div className="form_group">
                <label className="classspan text-left">
                  Confirme Contraseña
                </label>
                <input
                  type="password"
                  className="txtbox"
                  {...confirmPassword}
                />
              </div>
              <br />
              <Button
                type="btn-outline-danger"
                text="CAMBIAR CONTRASEÑA"
                clickHandler={change}
              />
            </div>
          </center>
        </section>
      </div>
    </section>
  );
}