import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { getToken, removeUserSession } from "../../utils/Common";
import PrivateRoute from "../../utils/private_route";
import PublicRoute from "../../utils/public_route";
import Home from "../../views/Home_user/home";
import Login from "../../views/Home_user/login";
import Logout from "../../views/Home_user/logout";
import MyBusiness from "../../views/Business/my_business";
import MissionVisionValues from "../../views/Business/mission_vision_values";
import Historia from "../../views/Business/historia";
import FundacionCorripio from "../../views/Business/fundacion_corripio";
import BusinessUnit from "../../views/Business/BusinessUnit";
import UnitGeneric from "../../views/Business/unit_generic";
import CommunicationGeneric from "../../views/Comunication/Communication_generic";
import Communication from "../../views/Comunication/Communication";
import FaqView from "../../views/Contact_Us/faq";
import PoliciesView from "../../views/Policies/policies";
import PoliciesGeneric from "../../views/Policies/policies_generic";
import MyBenefits from "../../views/Benefits/benefits";
import BenefitsIMG from "../../views/Benefits/benefitsImg";
import BenefitsGeneric from "../../views/Benefits/benefits_generic";
import MyProfile from "../../views/Profile/my_profile";
import MyData from "../../views/Profile/mi_data";
import MyChangePassword from "../../views/Profile/change_password";
import Benefits from "../../views/Profile/mi_benefits";
import MyCompensation from "../../views/Profile/my_compensation";
import MyTools from "../../views/Profile/my_tools";
import Portals from "../../views/Portales/portals";
import Payroll from "../../views/Profile/payroll";
import AnnualCompensation from "../../views/AnnualCompensation/annual_compensation";
import MyContacto from "../../views/Contact_Us/Contact";
import MySuggestion from "../../views/Contact_Us/Suggestion";
import AnonymeLine from "../../views/Contact_Us/Anonyme";
import MyRequests from "../../views/my_requests/my_requests";
import GymRequest from "../../views/my_requests/gym_request";

import MyCarnet from "../../views/Profile/my_carnet";
import API from "../../utils/api";

import {
  getUser,
  getPolicieSession,
  getUserChangesSession,
  getEvaluationSession,
} from "../../utils/Common";

import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";
import ContactDir from "../../views/Contact_Us/Directory";
import AllBirthday from "../../views/Birthday/birthday";
import Vacations from "../../views/Vacations/vacations";
import UserRequest from "../../views/my_requests/UserRequest";
import TodayBirthdate from "../../views/Birthday/todayBirthdate";
import MonthBirthdate from "../../views/Birthday/monthBirthdate";
import VacationAdmin from "../../views/admin/vacationAdmin";
import HistoryButton from "../Button/HistoryButton";
import Ticket from "../../views/Contact_Us/ticket";
import PollCovid from "../../views/Contact_Us/PollCovid";
import ServiceDescAdm from "../../views/Home_user/servicedescAdmin";
import InternalNotifications from "../../views/internalNotifications/internalNotifications";
import MyInternalNotifications from "../../views/internalNotifications/myInternalNotifications";
import Evaluations from "../../views/evaluations/new_evaluation";
import MedicalLicenses from "../../views/Contact_Us/MedicalLicense";
import AddMedicalLicenses from "../../views/Contact_Us/AddMedicalLicense";
import LeadersOnBoard from "../../views/Benefits/leadersOnBoard";
import VaccinationData from "../../views/Profile/Vaccination_data";
import EmployeePermission from "../../views/EmployeePermission/EmployeePermission";
import AddEmployeePermission from "../../views/EmployeePermission/AddEmployeePermission";
import EmployeePermissionAdmin from "../admin/employeePermissionAdmin";
import PollVaccine from "../../views/PollVaccine/pollVaccine";
import { getPolicies } from "../../views/action/permissionAction";
import EdiMedicalLicense from "../../views/Contact_Us/EdiMedicalLicense";
import TicketGyG from "../../views/Contact_Us/TicketGyG";
import AddpromotionPapaJohns from "../../views/Promotiones/promotionPapaJohns";
import AddpromotionBurgerKing from "../../views/Promotiones/promotionBurgerKing";
import Promociones from "../../views/Promotiones/promotion";
import AddMericoSchoolApplication from "../../views/SchoolMerit/SchoolMerit";
import Dependient from "../../views/SchoolMerit/Dependient";
import AddDependient from "../../views/SchoolMerit/AddDependient";
import Appmovil  from "../../views/Home_user/appmovil";
import ZendeskSdk from "../../views/Home_user/zendesksdk";
export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
    };
  }
  componentDidMount() {
    if(getUser()){
      getPolicies(getUser().employeeNumber, getUser().companyId,getUser().level);
    }else{
      console.log('No sesion');
    }
  }
  render() {
    getPolicieSession();
    getUserChangesSession();
    const background = {
      backgroundColor: GetCssByCompany().PrimaryColor,
    };

    const goBack = () => {
      getPolicieSession();
      getEvaluationSession();
      getUserChangesSession();
      window.history.back();
    };

    return (
      <Router>
        <header id="header" style={background}>

          <nav>{getToken() ? <a href="#menu"></a> : <></>}</nav>
          <Link to="/" className="logo">
            <center>
              {GetCssByCompany() ? (
                <img
                  className={getUser() ? (
                    getUser().companyId == 1 ? "main-logo_1" : "main-logo"
                  ): <></>}
                  src={GetCssByCompany().logo}
                  alt="logo"
                />
              ) : (
                <></>
              )}
              {getUser() ? (
                getUser().companyId != 1 ? (
                     <>&nbsp;{GetCssByCompany() ? GetCssByCompany().name: <></>}</>
                ):<></>
            ) : <></>}
            </center>
          </Link>
          {getToken() ? (
            <>
              <InternalNotifications />
            </>
          ) : (
            <></>
          )}
          {getToken() ? (
            window.location.pathname != "/" ? (
              <>
                <HistoryButton clickHandler={goBack}></HistoryButton>
              </>
            ) : (
              <div></div>
            )
          ) : (
            <></>
          )}
        </header>

        <nav id="menu" style={background}>
          <ul className="links">
            <li>
              <div>
                <span className="title-upper menu-name">
                  <strong>
                    {getUser() ? getUser().firstName : <></>}&nbsp;
                    {getUser() ? getUser().lastName : <></>}
                  </strong>
                </span>
                <br />
                <span className="title-upper menu-code">
                  C&oacute;digo&nbsp;Empleado:&nbsp;
                  {getUser() ? getUser().employeeNumber : <></>}
                </span>
              </div>
            </li>
            <li>
              <Link to="/">
                <i className="fa fa-home"></i>Inicio
              </Link>
            </li>
            <li>
              <Link
                to="/empresa"
                className="most-visited-log"
                data-prefix="menu"
              >
                <i className="fa fa-building-o"></i>Mi Empresa
              </Link>
            </li>
            <li>
              <Link
                to="/perfil"
                className="most-visited-log"
                data-prefix="index"
              >
                <i className="fa fa-user"></i>Mi Perfil
              </Link>
            </li>
            <li>
              <Link to="/userrequest" className="most-visited-log">
                <i className="fa fa-envelope"></i>Mis Solicitudes
              </Link>{" "}
            </li>
            <li>
            <div class="dropdown">
              <i className="fa fa-star dropbtn most-visited-log"></i>Mérito escolar
              <div class="dropdown-content most-visited-log">
                  <a href="/dependient">Dependientes</a>
                  <a href="#">Mis solicitudes</a>
                  <a href="#">Solicitar</a>
              </div>
            </div>
            </li>
            {getUser() ? (
                getUser().isSupervisor ? (
                  <li>
                  <Link to="/reportVaccinated" className="most-visited-log">
                    <i className="fa fa-user-md"></i>Reporte de Vacunados
                  </Link>{" "}
                </li>
                ):null
            ) : null}
            {/*  <li>
              <Link
                to="/leadersOnBoard"
                className="most-visited-log"
                data-prefix="index"
              >
                <i className="fa fa-user"></i>Lideres Abordos
              </Link>
            </li>*/}
            <li>
              <Link to="/portales" className="most-visited-log">
                <i className="fa fa-globe"></i>Portales
              </Link>
            </li>
            <li>
              <Link
                to="/policies"
                className="most-visited-log"
                data-prefix="menu"
              >
                <i className="fa fa-file-text-o"></i>Politícas
              </Link>
            </li>
            <li>
              <Link
                to="/benefits"
                className="most-visited-log"
                data-prefix="menu"
              >
                <i className="fa fa-users"></i>Pensando En Tí
              </Link>
            </li>
            <li>
              <Link
                to="/birthday"
                className="most-visited-log"
                data-prefix="menu"
              >
                <i className="fa fa-gift fa-4"></i>Mis Celebraciones
              </Link>
            </li>
            <li>
              <a id="btnBoxMessage" href="/internalNotifications">
                {getToken() ? (
                  <>
                    <InternalNotifications />
                  </>
                ) : (
                  <></>
                )}
                Mis Notificaciones
              </a>
            </li>
            <li>
              <Link to="/contacto">
                <i className="fa fa-envelope-o"></i>Contactanos
              </Link>
            </li>
            <li>
              <Link to="/appmovil">
                <i className="fa fa-phone"></i> DESCARGAR APP PARA IOS
              </Link>
            </li>
           
            <li>
              <Link to="/logout">
                <i className="fa fa-sign-out"></i>Cerrar
              </Link>
            </li>
          </ul>
        </nav>
        <Switch>
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/ServiceDescAdm" component={ServiceDescAdm} />
          <PrivateRoute path="/logout" component={Logout} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/empresa" component={MyBusiness} />
          <PrivateRoute path="/perfil" component={MyProfile} />
          <PrivateRoute path="/carnet" component={MyCarnet} />
          <PrivateRoute path="/acercade" component={MissionVisionValues} />
          <PrivateRoute path="/historia" component={Historia} />
          <PrivateRoute path="/leadersOnBoard" component={LeadersOnBoard} />
          
          <PublicRoute path="/addpromotionPapaJohns" component={AddpromotionPapaJohns} />
          <PublicRoute path="/addpromotionBurgerKing" component={AddpromotionBurgerKing} />
          <PublicRoute path="/appmovilIOS" component={Appmovil} />
          <PublicRoute path="/zendesksdk" component={ZendeskSdk} />
          
          <PrivateRoute path="/promociones" component={Promociones} />
          
          
          <PrivateRoute
            path="/internalNotifications"
            component={MyInternalNotifications}
          />
          <PrivateRoute path="/medicalLicense" component={MedicalLicenses} />
          <PrivateRoute
            path="/addMedicalLicenses"
            component={AddMedicalLicenses}
          />

          <PrivateRoute
            path="/fundacion_corripio"
            component={FundacionCorripio}
          />
          <PrivateRoute path="/unidadnegocio" component={BusinessUnit} />
          <PrivateRoute path="/unidad" component={UnitGeneric} />
          <PrivateRoute path="/payroll" component={Payroll} />
          <PrivateRoute path="/communication" component={Communication} />
          <PrivateRoute
            path="/genericCommunication"
            component={CommunicationGeneric}
          />
          <PrivateRoute path="/faq" component={FaqView} />
          <PrivateRoute path="/policies" component={PoliciesView} />
          <PrivateRoute path="/genericpolicies" component={PoliciesGeneric} />
          <PrivateRoute path="/benefits" component={MyBenefits} />
          <PrivateRoute path="/benefitsImg" component={BenefitsIMG} />
          <PrivateRoute path="/benefitsGeneric" component={BenefitsGeneric} />
          <PrivateRoute path="/mydata" component={MyData} />
          <PrivateRoute path="/mycompensation" component={MyCompensation} />
          <PrivateRoute path="/my_requests" component={MyRequests} />
          <PrivateRoute path="/portales" component={Portals} />
          <PrivateRoute path="/mytools" component={MyTools} />
          <PrivateRoute path="/contacto" component={MyContacto} />
          <PrivateRoute path="/mybenefits" component={Benefits} />
          <PrivateRoute path="/suggestion" component={MySuggestion} />
          <PrivateRoute path="/anonymeline" component={AnonymeLine} />
          <PrivateRoute path="/changepassword" component={MyChangePassword} />
          <PrivateRoute
            path="/annual_compensation"
            component={AnnualCompensation}
          />
          <PrivateRoute path="/Directory" component={ContactDir} />
          <PrivateRoute path="/birthday" component={AllBirthday} />
          <PrivateRoute path="/vacations" component={Vacations} />
          <PrivateRoute path="/userrequest" component={UserRequest} />
          <PrivateRoute path="/todayBirthdate" component={TodayBirthdate} />
          <PrivateRoute path="/monthBirthdate" component={MonthBirthdate} />
          <PrivateRoute path="/vacationAdmin" component={VacationAdmin} />
          <PrivateRoute path="/ticket" component={Ticket} />
          <PrivateRoute path="/new_evaluation" component={Evaluations} />
          <PrivateRoute path="/PollCovid" component={PollCovid} />
          <PrivateRoute path="/GymRequest" component={GymRequest} />
          <PrivateRoute path="/vaccinationData" component={VaccinationData} />
          <PrivateRoute path="/addEmployeePermission" component={AddEmployeePermission} />
          <PrivateRoute path="/employeePermission" component={EmployeePermission} />
          <PrivateRoute path="/employeePermissionAdmin" component={EmployeePermissionAdmin} /> 
          <PrivateRoute path="/reportVaccinated" component={PollVaccine} /> 
          <PrivateRoute path="/editMedicalLicense" component={EdiMedicalLicense} />
          <PrivateRoute path="/ticketGyG" component={TicketGyG} />

          <PrivateRoute path="/SolicitudMerito" component={AddMericoSchoolApplication} />
          <PrivateRoute path="/dependient" component={Dependient} />
          <PrivateRoute path="/addDependiente" component={AddDependient} />
          <PrivateRoute path="/appmovil" component={Appmovil} />
          
        </Switch>
      </Router>
    );
  }
}
