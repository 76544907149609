import React, { useState } from "react";
import Moment from "moment";
import { NumbersWithComma } from "../../utils/CommonFunctions";
import Chart  from "react-apexcharts";

const BuildChart = ({ props }) => {
  let grossAmount = 0.0;
  let serieData = [];
  let labelData = [];
  let maxValue = 1000000;
  
  if(sessionStorage.getItem('GraficAnnual') != null){
    serieData = JSON.parse(sessionStorage.getItem('GraficAnnual'));
  }
  serieData.forEach(element => {
    labelData.push(element.name);
  });
  if(serieData.find(c=>c.name =="Ingresos Brutos") !== undefined){
    maxValue = parseInt(serieData[0].maxValue);
  }
  const state = {
                series: serieData,
            options: {
              chart: {
                type: 'bar',
                height: 380
                
              },
            plotOptions: {
                bar: {
                  barHeight: '90%',
                  distributed: false,
                  horizontal: true,
                  dataLabels: {
                    position: 'bottom'
                  },
                }
              },colors: ['#007bff9e', '#900812' ],
              dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                  fontSize: '15px',
                  colors: ['#131a21']
                },
                formatter: function (val, opt) {
                  // return opt.w.globals.labels[opt.seriesIndex] + ":  " + NumbersWithComma(val)
                  return   NumbersWithComma(val)
                },
                offsetX: 0,
                dropShadow: {
                  enabled: true
                }
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              legend: {
              show: false,
            },
              
              grid: {
                xaxis: {
                  lines: {
                    show: false
                  }
                }
              },
              yaxis: {
                min: -maxValue,
                max: maxValue,
                dataLabels: {
                  enabled: false
                },
              },
              tooltip: {
                shared: false,
                x: {
                  formatter: function (val,opt) {
                    return opt.w.globals.labels[opt.seriesIndex] 
                  }
                },
                y: {
                  formatter: function (val) {
                    return NumbersWithComma(val)  
                  }
                }
              },
              xaxis: {
                categories: labelData,
                labels: {
                  formatter: function (value) {
                    return NumbersWithComma(value)
                  }
                }
                 
              },
            },

          
  };
 


  

  return (
    <>
      <div className="container table-responsive">
      <br /> 
      <div className="row header-grafic">
          <h6>&nbsp;&nbsp;<i class="defaultred fa fa-bar-chart"></i>&nbsp;Gr&aacute;fico&nbsp;Resumen&nbsp;Ingresos</h6>  
      </div>
        <div className="row">
            <Chart
                options={state.options}
                series={state.series}
                type="bar"
                width={800}
                height={400}
            />
        </div>
        <br />
      </div>
    </>
  );
};

export default BuildChart;