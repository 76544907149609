import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import Button from "../../components/Button/Button";
import { getUser } from "../../utils/Common";
import $ from "jquery";
import {
  ShowPopUp,
  MessageResults,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";
import { Grid } from "@material-ui/core";
import queryString from "query-string";
import storage from "../../firebase/firebase";
import Swal from "sweetalert2";

export default function EdiMedicalLicense(props) {
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };
  const [imagen, setImage] = useState("");
  
  const addMedicalLicense = async () => { 
    let image = "";
    let dataUpload = $("#btn-file")[0];
    let formData = new FormData();
    formData.append("postedFiles", dataUpload.files[0]);
    await API.postData("MedicalLicenses/UploadFiles", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        image = res.data[0];
        SaveChanges();
        MedicalEmail();
        setTimeout(() => {
           window.location.reload(true);
        }, 1200);
        
      })
      .catch(function (err) {
        ShowAlertMessage(
          "Información",
          "Hubo un problema intente de nuevo",
          "error"
        );
        console.error("Error de conexion " + err);
      });
  };

  const ids = queryString.parse(props.location.search).id;
  const SaveChanges = () => {
    API.putData("MedicalLicenses/imgForm",{
      imgForm: imagen,
      id: parseInt(ids)
    })
      .then((res) => {
        if (res.status === 200) {
          MessageResults(res.status);
          setTimeout(() => {
            window.location.reload(true);
          }, 2500);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
        MessageResults(400, err);
      });
  };
  //Function for sending mail, the part of (email) is the recipient(To whom the mail will arrive) 
  //and emailFrom is the sender (who sends the message).
  const MedicalEmail = () => {
    API.postData("EmailSender/SendMedicalEmail", {
      Email: "asistenciagyg@corripio.com.do",
      Name: getUser().firstName + " " + getUser().lastName,
      Departament: getUser().department,
      Unit: getUser().positionName,
      Message: "imagen de formulario medico para la licencia medica",
      img: imagen,
      emailFrom: getUser().firstName + " " + getUser().lastName
    })
      .then((response) => {
      })
      .catch((error) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Intente de nuevo, algo salio mal al enviar su sugerencia</p>"
        );
        console.log(error);
      });
  }

  const saveFile = (e) => {
    Swal.fire({
      title: "Cargando Imagén",
      showConfirmButton: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      allowOutsideClick: false,
      dangerMode: true,
    });
    const ref = storage.ref(`/images/${e.name}`);
    const uploadTask = ref.put(e);
    uploadTask.on("state_changed", console.log, console.error, () => {
      ref.getDownloadURL().then((url) => {
        setImage(url);
        debugger;
        console.log(url);
        Swal.close();
      });
    });
  };

  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <h4 className="text-center">ENVIAR FORMULARIO FIRMADO Y SELLADO</h4>
        <hr />

        <section>
          <div id="myform" className="form_container">
            <div className="form_group">
              <label className="classspan text-left">Nombre Completo</label>

              <input
                type="text"
                className="txtbox"
                value={getUser().firstName + " " + getUser().lastName}
                readonly="true"
              />
            </div>
            <br />
            <div class="form-group ">
              <Grid item sm={3} xs={6}>
                <button class="contenedor-btn-file" style={{ color: "white" }}>
                  <i class="fa fa-picture-o" style={{ color: "white" }}></i>
                  Adjuntar imagen
                  <label for="btn-file"></label>
                  <input
                    id="btn-file"
                    type="file"
                    i
                    name="logo"
                    onChange={(e) => saveFile(e.target.files[0])}
                    multiple
                  />
              </button>
              </Grid>
              <br/>
              <Grid item sm={3} xs={6}>
                <img id="output" src={imagen} width="250" height="200"/>
              </Grid>
            </div>

            <div className="form_group">
              <br />
              <Button
                type="btn-outline-danger"
                text="ENVIAR FORMULARIO"
                clickHandler={addMedicalLicense}
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
