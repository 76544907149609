import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import { Accordion, Card } from "react-bootstrap";
import { getUser } from "../../utils/Common";
import Loading from "../../components/loading/loading";
import QRCode from "qrcode.react";

import "../../tools/JsTools.css";
export default function VaccinationData() {
  const [vacunation, setVacunation] = useState("");
  const [filter, setFilter] = useState("");
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    API.getData(
      "/PollVaccinesService/getbyemployeeidcardPerfil?employeeIdCard=" +
        getUser().employeeIdCard
    )
      .then((response) => {
        setDataLoading(false);
        setVacunation(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <section className="container">
        <br />
        <br />
        <h3 Style={"text-align:center"}>Mis Datos Sobre La Vacunación</h3>
        <div className="container">
          <br />
          <br />
          <div class="row">
            <div class="col-md-12">
              <h6>
                <b>{vacunation.tituloPrimera}</b>
              </h6>
              <p>{vacunation.primeraDosis ? "Si" : "No"}</p>
              <hr />
              <h6>
                <b>{vacunation.tituloSegunda} </b>
              </h6>
              <p>{vacunation.segundaDosis ? "Si" : "No"}</p>
              <hr />
              <h6>
                <b>{vacunation.tituloTercera} </b>
              </h6>
              <p>{vacunation.terceraDosis ? "Si" : "No"}</p>
              <hr />
              <h6>
                <b>{vacunation.tituloCuarta} </b>
              </h6>

              <p>{vacunation.cuartaDosis ? "Si" : "No"}</p>
              <hr />
              <h6>
                <b>{vacunation.tituloCovid} </b>
              </h6>

              <p>{vacunation.covid ? "Si" : "No"}</p>
              <hr />
              <h6>
                <b>{vacunation.tituloVeces} </b>
              </h6>

              <p>{vacunation.vecesCovid }</p>
            </div>
          </div>

          {/*vacunation.declined ? (
            <div class="row">
              <div class="col-md-12">
                <center>
                  <h5 className="text-justify">
                    Debe Actualizar la foto de su tarjeta de vacunación fue
                    rechazada por el departamento de revisión.
                  </h5>
                  <br />

                  <div class="form-group col-md-6 ">
                    <a
                      href="/PollCovid"
                      type="button"
                      className="mybt btn btn-outline-danger text-wrap"
                    >
                      Actualizar Datos de vacunación
                    </a>
                  </div>
                </center>
              </div>
            </div>
          ) : vacunation.aceptarPhoto ? (
            !dataLoading ? (
              <section>
                <div className="content2">
                  <header>
                    <center>
                    <QRCode value={vacunation.imagenPrimera} size={300} />
                    </center>
                  </header>
                </div>
              </section>
            ) : (
              <Loading />
            )
          ) : (
            <section>
              <div className="content2">
                <h5 className="text-justify">
                  SU TAJERTA DE VACUNACIÓN ESTA EN PROCESO DE CONFIRMACIÓN POR
                  EL DEPARTAMENTO CORRESPONDIETE DE VALIDAR LOS DATOS.
                </h5>
              </div>
            </section>
          )*/}
        </div>
        <br />
        <br />
      </section>
    </div>
  );
}
