import React, { useState, useEffect } from "react";
import { getUser, removeUserSession } from "../../utils/Common"; 
import API from "../../utils/api";

export default function MyProfile() {
  const [siteMenu, setSiteMenu] = useState([]);
  const GetMenu = (pathUrl)=>{
    API.getData("SiteMenu/GetbyMenuRole?companyId=" + getUser().companyId + "&isSupervisor=" + getUser().isSupervisor.toUpperCase())
    .then((res) => {
      if (res.status === 200) { 
         setSiteMenu(res.data.find(c=>c.PatchName == pathUrl));
      }
    })
    .catch(function (err) {
      console.error("Error de conexion " + err);
    });
}
  useEffect(() => {
    GetMenu(window.location.pathname.replace('/',''));

  },[]);

  return (
    <div>
      <section className="wrapper">
        <div className="inner">
          {<div className="highlights" dangerouslySetInnerHTML={{ __html: siteMenu.TemplateHtml }} />}
        </div>
      </section>
    </div>
  );
}
