import React, { useState, useEffect } from "react";
import { getUser, removeUserSession } from "../../utils/Common";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Moment from "moment";
import es from "date-fns/locale/es";
import "moment/locale/es";
import API from "../../utils/api";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Loading from "../loading/loading";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import { LangSpanish } from "../../tools/dataTables.Language";
import $ from "jquery";
import { GetImagePatch } from "../../utils/CommonFunctions";
import { jsPDF } from "jspdf";
import {
  ShowPopUp,
  ShowConfirmationStatus,
  ShowConfirmationMessage,
  MessageResults,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import {
  GetLetterParam,
  GetLetterParams,
} from "../../components/myrequests/validateLetter";

import { GetLetter } from "../../components/myrequests/letters/letterWitoutSalary";
import { GetLetterWithSalary } from "../../components/myrequests/letters/letterWithSalary";
import { GetLetterBankWithSalary } from "../../components/myrequests/letters/letterBankWithSalary";
import { GetLetterConsulateWithSalary } from "../../components/myrequests/letters/letterConsulateWithSalary";
import { GetLetterOtherWithSalary } from "../../components/myrequests/letters/letterOtherWithSalary";

registerLocale("es", es);

$(document).ready(() => {
  let idUnico = 0;
  let status = 0;
  let DataResultado = [];
  let Tipo = 0;
  let cardType = "";

  const SaveChanges = (params) => {
    if (status >= 3) {
      if (
        cardType == "A quien pueda interesar sin salario" ||
        cardType == "A quien pueda interesar"
      ) {
        letterWitoutSalary(cardType);
      } else if (cardType == "Carta bancaria") {
        letterBank_Consulate_Salary("Banco", cardType);
      } else if (cardType == "Carta consular") {
        //Agregar metodo de la carta consular
        letterBank_Consulate_Salary("Consul", cardType);
      } else if (cardType == "Carta otro") {
        //Agregar metodo de la carta otro
        letterBank_Consulate_Salary("otro", cardType);
      }
      if (status == 3) {
        API.putData("StaffLetter/StatusCard?id=" + idUnico)
          .then((res) => {
            if (res.status === 200) {
              MessageResults(res.status);
              setTimeout(() => {
                window.location.reload(true);
              }, 1200);
            }
          })
          .catch(function (err) {
            console.error("Error de conexion " + err);
            MessageResults(400, err);
          });
      }
    }

    idUnico = 0;
  };

  const letterWitoutSalary = (cardType) => {
    var doc = new jsPDF();
    var logo = new Image();
    if (DataResultado.companyId == "01") {
      logo.src = GetImagePatch("/images/logo_dc.png");
    } else if (DataResultado.companyId == "39") {
      logo.src = GetImagePatch("/images/corvi_logo.png");
    } else if (DataResultado.companyId == "08") {
      logo.src = GetImagePatch("/images/auco.png");
    } else if (DataResultado.companyId == "04") {
      logo.src = GetImagePatch("/images/alcatext.png");
    }
    doc.addImage(logo, "PNG", 15, 20, 60, 20);
    doc.text(15, 60, DataResultado.header_date);
    doc.text(60, 80, "A QUIEN PUEDA INTERESAR");

    var texto = `Mediante la presente, le informamos que el SR. ${DataResultado.employee_name}, dominicano mayor de edad, portador de la cédula de identidad y electoral No.${DataResultado.employee_id_card}, labora en esta empresa desde el ${DataResultado.hiring_date}, desempeñando actualmente el puesto de ${DataResultado.position_name} ubicada en la ${DataResultado.address} `;
    var splitTitle = doc.splitTextToSize(texto, 250);

    doc.setFontSize("12");

    var y = 95;
    for (var i = 0; i < splitTitle.length; i++) {
      if (y > 250) {
        y = 8;
        doc.addPage();
      }
      doc.text(15, y, splitTitle[i]);
      y = y + 8;
    }
    if (Tipo == 1 || Tipo == 2) {
      doc.text(
        15,
        140,
        `Recibe un ingreso ${DataResultado.salary_frequency} de ${DataResultado.salary} pesos, entre sueldos y gratificaciones voluntarias.`
      );
    }
    doc.text(
      15,
      150,
      `Expedimos la presente solicitud a la parte interesada a los ${DataResultado.header_date}.`
    );
    doc.text(15, 170, `Atentamente,`);
    var firma = new Image();
    firma.src = DataResultado.urlImageSigned;
;
    doc.addImage(firma, "PNG", 60, 190, 90, 45);
    if (DataResultado.companyId == "01") {
      var sello = new Image();
      
      sello.src = GetImagePatch("/images/sello_gyg.png");
      doc.addImage(sello, "PNG", 60, 215, 90, 30);
    } else if (DataResultado.companyId == "39") {
      doc.setFontSize("13");
      var footer = `${DataResultado.companyName} | ${DataResultado.address}`;
      var contact = `${DataResultado.email} ${DataResultado.phones} ${DataResultado.rnc}`;
      doc.text(27, 270, footer);
      doc.text(46, 280, contact);
    } else if (DataResultado.companyId == "08") {
      doc.setFontSize("13");
      var footer = `${DataResultado.companyName} | ${DataResultado.address}`;
      var contact = `${DataResultado.email} ${DataResultado.phones} ${DataResultado.rnc}`;
      doc.text(27, 270, footer);
      doc.text(46, 280, contact);
    } else {
      doc.setFontSize("10");
      var footer = `${DataResultado.companyName} | ${DataResultado.address}`;
      var contact = `${DataResultado.email} ${DataResultado.phones} ${DataResultado.rnc}`;
      doc.text(15, 270, footer);
      doc.text(46, 280, contact);
    }

    // Save the PDF
    doc.save(`${cardType} ${DataResultado.employee_name}`);
  };

  const letterBank_Consulate_Salary = (Name, cardType) => {
    var doc = new jsPDF();
    var logo = new Image();

    if (DataResultado.companyId == "01") {
      logo.src = GetImagePatch("/images/logo_dc.png");
    } else if (DataResultado.companyId == "39") {
      logo.src = GetImagePatch("/images/corvi_logo.png");
    } else if (DataResultado.companyId == "08") {
      logo.src = GetImagePatch("/images/auco.png");
    } else if (DataResultado.companyId == "04") {
      logo.src = GetImagePatch("/images/alcatext.png");
    }

    doc.addImage(logo, "PNG", 15, 20, 60, 20);

    doc.text(15, 60, DataResultado.header_date);

    doc.text(15, 75, "Señores:");

    if (Name == "Banco") {
      doc.text(15, 85, DataResultado.bankName);
    } else if (Name == "Consul") {
      doc.text(15, 85, DataResultado.consulateName);
    } else {
      doc.text(15, 85, DataResultado.OtherEntityName);
    }

    var texto = `Mediante la presente, le informamos que el SR. ${DataResultado.employee_name}, dominicano mayor de edad, portador de la cédula de identidad y electoral No.${DataResultado.employee_id_card}, labora en esta empresa desde el ${DataResultado.hiring_date}, desempeñando actualmente el puesto de ${DataResultado.position_name} ubicada en la ${DataResultado.address} `;
    var splitTitle = doc.splitTextToSize(texto, 250);

    doc.setFontSize("12");

    var y = 105;
    for (var i = 0; i < splitTitle.length; i++) {
      if (y > 270) {
        y = 8;
        doc.addPage();
      }
      doc.text(15, y, splitTitle[i]);
      y = y + 8;
    }
    if (Tipo == 1 || Tipo == 2) {
      doc.text(
        15,
        150,
        `Recibe un ingreso ${DataResultado.salary_frequency} de ${DataResultado.salary} pesos, entre sueldos y gratificaciones voluntarias.`
      );
    }
    doc.text(
      15,
      163,
      `Expedimos la presente solicitud a la parte interesada a los ${DataResultado.header_date}.`
    );
    doc.text(15, 180, `Atentamente,`);
    var firma = new Image();
    firma.src = DataResultado.urlImageSigned;

    doc.addImage(firma, "PNG", 70, 195, 65, 15);

    var sello = new Image();
    sello.src = DataResultado.sello;

    doc.addImage(sello, "PNG", 60, 210, 90, 30);

    doc.setFontSize("10");
    var footer = `${DataResultado.companyName} | ${DataResultado.address}`;
    var contact = `${DataResultado.email} ${DataResultado.phones} ${DataResultado.rnc}`;
    doc.text(15, 270, footer);
    doc.text(46, 280, contact);

    // Save the PDF
    doc.save(`${cardType} ${DataResultado.employee_name}`);
  };

  $("body").on("click", "#TblStaffLetter #btDel", function (e) {
    let param = JSON.parse(
      Buffer($(e.currentTarget).parent().attr("data-item"))
    )[0];
    cardType = param.cardType;
    let consulate = "";
    let bank = "";
    let other = "";
    if (param.consulate != null) {
      consulate = param.consulate.consulateName;
    }
    if (param.bank != null) {
      bank = param.bank.bankName;
    }
    if (param.other != null) {
      other = param.other;
    }
    idUnico = param.staffLetterId;
    status = param.statusCardId;
    let DataResult = GetLetterParams(
      param.employeesList,
      param.salaryFrequencyId,
      consulate,
      bank,
      other
    );
    DataResultado = DataResult;
    Tipo = param.salaryFrequencyId;

    switch (param.cardTypeId) {
      case 1:
        ShowPopUp({
          handlerEvent: SaveChanges,
          htmlBody: GetLetter({ props: DataResult }),
          isDisabled: true,
          TextOk: status == 4 || status == 3 ? "Imprimir carta" : "OK",
        });
        break;
      case 2:
        ShowPopUp({
          handlerEvent: SaveChanges,
          htmlBody: GetLetterWithSalary({ props: DataResult }),
          isDisabled: true,
          TextOk: status == 4 || status == 3 ? "Imprimir carta" : "OK",
        });
        break;
      case 4:
        ShowPopUp({
          handlerEvent: SaveChanges,
          htmlBody: GetLetterBankWithSalary({ props: DataResult }),
          isDisabled: true,
          TextOk: status == 4 || status == 3 ? "Imprimir carta" : "OK",
        });
        break;
      case 5:
        ShowPopUp({
          handlerEvent: SaveChanges,
          htmlBody: GetLetterConsulateWithSalary({ props: DataResult }),
          isDisabled: true,
          TextOk: status == 4 || status == 3 ? "Imprimir carta" : "OK",
        });
        break;
      case 6:
        ShowPopUp({
          handlerEvent: SaveChanges,
          htmlBody: GetLetterOtherWithSalary({ props: DataResult }),
          isDisabled: true,
          TextOk: status == 4 || status == 3 ? "Imprimir carta" : "OK",
        });
        break;

      default:
        ShowAlertMessage("Información", "Algo Salio mal intente de nuevo");
        break;
    }
  });

  $("body").on("click", "#TblStaffLetter #btRec", function (e) {
    let param = JSON.parse(
      Buffer($(e.currentTarget).parent().attr("data-item"))
    )[0];
    ShowConfirmationMessage(
      SaveRejectedChanges,
      "",
      param,
      "DESEA RECHAZAR ESTA SOLICITUD"
    );
  });

  const SaveRejectedChanges = (params) => {
    let id = params.staffLetterId;
    API.putData("StaffLetter/RejectedCard?id=" + id)
      .then((res) => {
        if (res.status === 200) {
          MessageResults(res.status);
          setTimeout(() => {
            window.location.reload(true);
          }, 1200);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
        MessageResults(400, err);
      });
  };
});

export default function OpenRequestsList({ props }) {
  const [staffLetter, setStaffLetter] = useState(true);
  const [rows, setRows] = useState([]);
  const useStyles = makeStyles({
    root: { width: "100%" },
    container: { maxHeight: 440 },
  });
  const theme = createMuiTheme(
    { palette: { primary: { main: "#1976d2" } } },
    esES
  );
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const columns = [
    {
      id: "cardType",
      label: "Tipo\u00a0de\u00a0Carta",
      minWidth: 100,
      align: "left",
    },
    { id: "institution", label: "Institución", minWidth: 190, align: "left" },
    { id: "frequency", label: "Salario", minWidth: 160, align: "left" },
    {
      id: "creationDate",
      label: "Fecha\u00a0de\u00a0Solicitud",
      minWidth: 190,
      align: "left",
    },
    { id: "statusCard", label: "Estado", minWidth: 140, align: "left" },
    { id: "button", label: "Acción", minWidth: 100, align: "left" },
  ];
  const [dataLoading, setDataLoading] = useState(true);

  const createData = (
    cardType,
    institution,
    frequency,
    creationDate,
    statusCard,
    button
  ) => {
    return {
      cardType,
      institution,
      frequency,
      creationDate,
      statusCard,
      button,
    };
  };
  const GetData = () => {
    API.getData(
      "StaffLetter/getbyEmployeeNumber?employeeNumber=" +
        getUser().employeeNumber +
        "&companyId=" +
        getUser().companyId
    )
      .then((res) => {
        setDataLoading(false);
        if (res.status === 200) {
          let dataResult = [];

          setStaffLetter(res.data);
          let EditBtn =
            "&nbsp;<a href='#' id='btEdit'  class='fa fa-pencil-square-o custom-color size-effect-x2' title='Editar Carta' ></a>&nbsp;";
          let RechazarBtn =
            "<a href='#' id='btRec'  class='fa fa fa-trash custom-color size-effect-x2 red' title='Rechazar Solicitud' ></a>&nbsp;";
          let DeleteBtn =
            "<a href='#' id='btDel'  class='fa fa-eye custom-color size-effect-x2 ' title='Visualizar Carta' ></a>&nbsp;";
          res.data.forEach((item) => {
            dataResult.push({
              bank:
                '<span class="container d-flex align-items-center justify-content-center">' +
                (item.bank == null ? " " : item.bank.bankName) +
                "</>",
              cardType:
                '<span class="capitalized defaultText">' +
                (item.cardType == null ? " " : item.cardType) +
                "</span>",
              consulate:
                '<span class="capitalized defaultText">' +
                (item.consulate == null ? " " : item.consulate.consulateName) +
                "</span>",
              employeeName:
                '<span class="capitalized defaultText">' +
                item.employeeName +
                "</span>",
              salaryFrequencyId:
                '<span class="capitalized defaultText">' +
                item.salaryFrequencyId +
                "</span>",
              salaryName:
                '<span class="capitalized defaultText">' +
                (item.salaryFrequencyId == 0
                  ? " "
                  : item.salaryFrequency.salaryFrequencyName) +
                "</span>",
              other:
                '<span class="capitalized defaultText">' +
                (item.other == null
                  ? item.bank == null
                    ? item.consulate == null
                      ? " "
                      : item.consulate.consulateName
                    : item.bank.bankName
                  : item.other) +
                "</span>",
              statusCard:
                '<span class="capitalized defaultText ' +
                item.statusCssClass +
                '">' +
                item.statusCard +
                "</span>",
              creationDate:
                '<span class="capitalized defaultText">' +
                Moment(item.creationDate).format("DD/MM/YYYY ") +
                "</span>",
              itemBtn:
                "<span data-created='" +
                Moment(item.creationDate).format("DD/MM/YYYY ") +
                "'  data-item='" +
                Buffer.from(JSON.stringify([item])) +
                "'>" +
                DeleteBtn +
                "</span>",
            });
          });

          $("#TblStaffLetter").DataTable({
            destroy: true,
            searching: true,
            language: LangSpanish,
            bLengthChange: false,
            lengthMenu: [10, 20, 40, 60, 80, 90, 100, 200],
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: ["copy", "excel", "pdf"],
            data:
              dataResult.length === 0
                ? [
                    {
                      bank: "",
                      cardType: "",
                      consulate: "",
                      creationDate: "",
                      employeeName: "",
                      other: "",
                      statusCard: "",
                      salaryFrequencyId: "",
                      salaryName: "",
                      itemBtn: "",
                    },
                  ]
                : dataResult,
            columns: [
              {
                data: "cardType",
                title: "Tipo De Carta",
                width: "15%",
                className: "capitalized",
              },

              {
                data: "other",
                title: "Dirigida a",
                width: "15%",
                className: "capitalized",
              },
              {
                data: "salaryName",
                title: "Salario",
                width: "10%",
                className: "capitalized",
              },
              {
                data: "statusCard",
                title: "Estado",
                width: "5%",
                className: "capitalized",
              },
              {
                data: "creationDate",
                title: "Fecha de solicitud",
                width: "10%",
                className: "capitalized",
              },
              {
                data: "itemBtn",
                title: "\u00a0Acciones\u00a0\u00a0\u00a0",
                width: "2%",
                orderable: false,
              },
            ],
          });
          $(".csHidden").attr("style", "display:none");
        }
      })
      .catch(function (err) {
        ShowAlertMessage(
          "Información",
          "Hubo un problema intente de nuevo",
          "error"
        );
        console.error("Error de conexion " + err);
      });
    setDataLoading(false);

    /*    let DeleteBtn =(<IconButton id="btDel" onClick={console.log("delete")}>
    <Icon>add_circle</Icon>
  </IconButton>
  );
    API.getData(
      "StaffLetter/getbyEmployeeNumber?employeeNumber=" +
        getUser().employeeNumber +
        "&companyId=" +
        getUser().companyId
    )
      .then((res) => {
        setDataLoading(false);
        if (res.status === 200) {
          let UserName =
            (getUser().firstName.toUpperCase().trim() ?? "") +
            " " +
            (getUser().lastName.toUpperCase().trim() ?? "");
          let dataResult = [];
          res.data.forEach((item) => {
            let dateFormatted = Moment(item.creationDate).format(
              "DD/MM/YYYY hh:mm A"
            );
            switch (item.cardType.toLowerCase().trim()) {
              case "a quien pueda interesar sin salario":
                dataResult.push(
                  createData(
                    item.cardType,
                    item.cardType,
                    "N/A",
                    dateFormatted,
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          '<span class="' +
                          item.statusCssClass +
                          '">' +
                          item.statusCard +
                          "</span>",
                      }}
                    ></div>,
                    DeleteBtn
                  )
                );
                break;
              case "a quien pueda interesar":
                dataResult.push(
                  createData(
                    item.cardType,
                    item.cardType,
                    item.salaryFrequency.salaryFrequencyName,
                    dateFormatted,
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          '<span class="' +
                          item.statusCssClass +
                          '">' +
                          item.statusCard +
                          "</span>",
                      }}
                    ></div>,
                    DeleteBtn
                  )
                );
                break;
              case "carta bancaria":
                dataResult.push(
                  createData(
                    item.cardType,
                    item.bank.bankName,
                    item.salaryFrequency.salaryFrequencyName,
                    dateFormatted,
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          '<span class="' +
                          item.statusCssClass +
                          '">' +
                          item.statusCard +
                          "</span>",
                      }}
                    ></div>,
                    DeleteBtn
                  )
                );
                break;
              case "carta consular":
                dataResult.push(
                  createData(
                    item.cardType,
                    item.consulate.consulateName,
                    item.salaryFrequency.salaryFrequencyName,
                    dateFormatted,
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          '<span class="' +
                          item.statusCssClass +
                          '">' +
                          item.statusCard +
                          "</span>",
                      }}
                    ></div>,
                    DeleteBtn
                  )
                );
                break;
              case "carta otro":
                dataResult.push(
                  createData(
                    item.cardType,
                    item.other,
                    item.salaryFrequency.salaryFrequencyName,
                    dateFormatted,
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          '<span class="' +
                          item.statusCssClass +
                          '">' +
                          item.statusCard +
                          "</span>",
                      }}
                    ></div>,
                    DeleteBtn
                  )
                );

                break;

              default:
                break;
            }
          });
          setRows(dataResult);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });*/
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <>
      <br />
      <div className=" htmlMyrequests table-responsive container">
        <div className="row">
          {!dataLoading ? (
            <div className="col-lg-12 col-md-12 col-sm-12">
              <span className="text-uppercase">Cartas solicitadas</span>
              <hr />
              <div className="  ">
                <div>
                  <br />
                  <div className=" htmlPayroll    container">
                    <div className="row ">
                      {!dataLoading ? (
                        <>
                          <div className="container">
                            <div className="">
                              {!dataLoading ? (
                                <div
                                  className="scroll-table bordered"
                                  Style="min-height:600px"
                                >
                                  <table
                                    id="TblStaffLetter"
                                    className="table table-striped table-bordered display"
                                    Style="width:100% !important"
                                  ></table>
                                </div>
                              ) : (
                                <Loading />
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="container">
                          <Loading />
                        </div>
                      )}
                    </div>

                    <br />
                    <br />
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <Loading />
            </div>
          )}
        </div>
      </div>

      <br />
      <br />
    </>
  );
}
