import React, { useState, useEffect } from "react";
import BackButton from "../../components/Button/BackButton";
import { GetImagePatch, ShowConfirmationMessage, ShowPopUp, MessageResults } from '../../utils/CommonFunctions';
import API from "../../utils/api";
import { getUser } from "../../utils/Common";
import $ from 'jquery';

$(document).ready(()=>{
$('body').on('click', '.image-birthday',(e)=>{
    let formData = '<br /><br /><div id="divMessage" class="form-group text-left">' +
                        '<label for="tbMsjCongratulations"><span id="sp_nameCongratulation"></span></label>' +
                        '<textarea class="form-control" id="tbMsjCongratulations" rows="3"></textarea>' +
                    '</div>'; 
                    ShowPopUp({titleName: "Enviar Felicidades!", htmlBody: formData, handlerEvent: SendCongratulations, TextOk:"Enviar", isDisabled:true, EnabledDisabled:true}) 
                    $('#swal2-title').prepend('<i class="fa fa-birthday-cake faa-tada animated faa-slow red"></i>&nbsp;');
                    $("#sp_nameCongratulation").text("Para: " + JSON.parse(atob($(e.currentTarget).attr('data-emp'))).firstName);
                    $("#divMessage").attr('data-emp', $(e.currentTarget).attr('data-emp'));
})
$('body').on('keypress', '#tbMsjCongratulations',(e)=>{
    let btnOk = $(".swal2-confirm.swal2-styled");
    if($(e.currentTarget).val().trim().length > 2){
        $(btnOk).attr('disabled',false);
    }else{
        $(btnOk).attr('disabled',true);
    }
})
const OnClickSave = (params)=>{
let param = {
                EmployeeNumberFrom: getUser().employeeNumber,
                EmployeeNumberTo: params.EmployeeNumber,
                BodyNote: btoa(params.MessageText),
                SubjectInfo: params.SubjectText,
                CompanyId: getUser().companyId
            };

 API.postData("/InternalNotifications/AddNew?data=" + JSON.stringify(param))
 .then((res) => {
     if (res.status === 200) {
         SendMail(params);
         MessageResults(res.status);
     }
 })
 .catch(function (err) {
     console.error("Error de conexion " + err);
 });
    
}
const SendCongratulations = (e)=>{
    let dataItem = JSON.parse(atob($("#divMessage").attr('data-emp')));
    let params = {EmployeeNumber: dataItem.EmployeeNumber, Email: dataItem.Email,SubjectText:'Felicidades en tu Día!', fullName: dataItem.fullName, fromfullName: getUser().firstName + ' ' + getUser().lastName, MessageText: $("#tbMsjCongratulations").val()}
    
    ShowConfirmationMessage(OnClickSave, '',params);        

} 
})
const SendMail = (params) => {
    API.postData("EmailSender/CongratulationEmail?data=" + JSON.stringify(params))
      .then((response) => {
        if(response.status === 200){
            console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

export default function TodayBirthdate(props) {
    const [employees, setEmployees] = useState([]);
    useEffect(() => {
        API.getData("/Employees/getTodayBirthdate?company_id="+getUser().companyId)
            .then((res) => {
                if (res.status === 200) {
                    setEmployees(res.data);
                }
            })
            .catch(function (err) {
                console.error("Error de conexion " + err);
            });

    }, []);

    return (
        <div>
            <section className="container d-flex align-items-center justify-content-center ">
                <div className="inner">
                    <br />
                    <header className="special">
                        <h3>ELLOS CELEBRAN HOY UN AÑO MÁS EN SU VIDA:</h3>
                    </header>
                    <div className="highlights">
                        {employees.map((item) => (
                            <section key={item.id}>
                                <div className="content2">
                                    <header className="image-birthday" title="Click para enviar mensaje!"  data-emp={btoa(JSON.stringify({EmployeeNumber: item.employeeNumber, Email:item.email,firstName: item.firstName, fullName: item.firstName+" "+item.lastName}))} >
                                        <img src={GetImagePatch("/images/employee_photo/"+ item.employeeIdCard+".jpg")} width="140px" alt="foto" />
                                        <h6>{item.firstName+" "+item.lastName}</h6>
                                        <p className="defaultText">{item.department ?? item.divisionName}</p>
                                    </header>
                                </div>
                            </section>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}
