import React, { useState, useEffect } from "react";
import { getUser } from "../../utils/Common"; 
import { registerLocale } from "react-datepicker";
import API from "../../utils/api";
import Loading from "../../components/loading/loading";
import $ from 'jquery';
import { LangSpanish } from "../../tools/dataTables.Language";
import Moment from "moment";
import es from "date-fns/locale/es";
import "moment/locale/es";

registerLocale("es", es);

$(document).ready(()=>{

    $('body').on('click', '#TblMessage #spView', (e) =>{
      let control = $(e.currentTarget);
      if(control.parent().find('.green').length > 0){
        control.parent().find('.green').removeClass('green').addClass('red');
        $('.divBody_' + control.attr('data-id')).attr('hidden', false);       
      }else{
        control.parent().find('.red').removeClass('red').addClass('green');
        $('.divBody_' + control.attr('data-id')).attr('hidden', true);
      }
      if(control.attr('data-isnew') ==='true'){
          ChangeStatus(control.attr('data-id'));
           setTimeout(() => {
             control.attr('data-isnew','false');
             control.parent().parent().next().find('.CsNvo').attr('hidden', true)             
         }, 200);
      }

    }); 
    // $('body').on('click', '.swal2-close', (e) =>{
    //   window.location.reload(true);
    // });
    
  
  const ChangeStatus = (id)=>{
    API.putData("InternalNotifications/UpdateNoteStatusByHead?notificationsHeadId=" + id)
    .then((res) => {
         
    })
    .catch(function (err) {
      console.error("Error de conexion " + err);
    });  
  }
 
  
  
  });
const MyInternalNotifications = ()=>{
    const [dataLoading, setDataLoading] = useState(true);
    const [notificationCounter, setNotificationCounter] = useState(false);
    const FillData = ()=>{
        API.getData("InternalNotifications/GetbyEmployeeNumber?employeeNumber=" + getUser().employeeIdCard + "&companyId=" + getUser().companyId)
        .then((res) => {
            setDataLoading(false);
          if (res.status === 200) {
            let dataResult = [];
            let dataItem = res.data;
            let dataHead = [];
            let dataHeadResult = [];
            if(dataItem.length === 0){
                setNotificationCounter(true);
            }else{
                setNotificationCounter(false);
            }
            dataItem.forEach(item => {
                let EmployeeFullName = JSON.parse(item.employee).find(c=>c.Item1 === item.employeeNumberFrom).Item2
                  dataHead.push({ notificationsHeadId:  item.internalNotificationsHead.notificationsHeadId,  
                              subjectInfo: item.internalNotificationsHead.subjectInfo,
                              creationDate: Moment(item.internalNotificationsHead.creationDate).format("DD/MM/YYYY"),
                              EmployeeFullName: EmployeeFullName,
                              employeeNumberFrom: item.employeeNumberFrom,
                              bodyNote: '', 
                              isActive: false});              
            });
            dataHeadResult = dataHead.filter((v,i,a)=>a.findIndex(t=>(t.notificationsHeadId === v.notificationsHeadId))=== i);
        
            dataHeadResult.forEach(element => {
              let activeMsg = false;
                  for (let idx = 0; idx < dataItem.length; idx++) {
                      const item = dataItem[idx];
                      if(element.notificationsHeadId === item.internalNotificationsHead.notificationsHeadId){
                          if(item.isActive){
                            activeMsg = true;
                          }               
                          let bodyResult = '<div  class="divChildRow divBody_'+ item.internalNotificationsHead.notificationsHeadId +' container" hidden data-id="'+ item.notificationsNoteId +'" data-item="'+ item.internalNotificationsHead.notificationsHeadId +'">' +
                                          '<div class="row defaultText">' +
                                          '<div  class="col-12  text-left headMessage mainTextMessage">' +
                                                  '<span><span class="bold-font">'+ Moment(item.creationDate).format("DD/MM/YYYY hh:mm A") +'</span>&nbsp;<span class="capitalized">'+ element.EmployeeFullName +'</span></span>' +
                                          '</div>' +                                    
                                              '<div  class="col-12   text-left TextMessage">' +
                                                    '<span class="msgText"><i class="fa fa-comments-o faa-flash animated faa-slow" ></i>&nbsp;<span>'+ atob(item.bodyNote)  +'</span></span>' +
                                              '</div>' +                                    
                                          '</div>' +                     
                                        '</div>'
        
                          element.bodyNote += bodyResult;
                      }
                      
                  }
                  element.isActive = activeMsg;
              
              
            });
            for (let idx = 0; idx < dataHeadResult.length; idx++) {
                   const item = dataHeadResult[idx];
                   dataResult.push({ 
                      subject:  '<div class="container"><div class="row">'+
                                    '<div class="col-lg-12 col-md-12 col-sm-12  defaultText text-left"><span class="bold-font">'+ item.creationDate +'</span>&nbsp;<span>'+ item.subjectInfo +'</span>'+ (item.isActive? '&nbsp;<i class="CsNvo fa fa-comments-o faa-flash animated faa-slow red" >Nuevo!</i>':'') +'</div>' + 
                                '</div></div>' + item.bodyNote , 
                      itmButton:  '<div class="d-flex align-items-center justify-content-center"><a id="spView" data-isnew="'+ item.isActive +'" href="#" data-id="'+ item.notificationsHeadId +'"  class="fa fa-plus-circle green size-effect-x2" ></a></div>'
                    }); 

             $("#TblMessage").DataTable({
                        destroy: true,
                        searching: true,
                        language: LangSpanish,
                        lengthMenu: [8, 20, 40, 60, 80, 90, 100, 200],
                        lengthChange: false,
                        "order": [[0, "desc"]],
                        data:  (dataResult.length === 0? [{subject:'',itmButton:''}] : dataResult),
                        columns: [
                            { data: "itmButton", title: " ", width:'5', className:"capitalized btn-icon" },
                            { data: "subject", title: "Mensaje", width:'95%', className:"capitalized text-left" },                        
                        ]
        
                    });
         
        
            };
           
            
          }
        })
        .catch(function (err) {
            setDataLoading(false); 
          console.error("Error de conexion " + err);
        });
         
        
  }
      
useEffect(() => {
    FillData();
    
}, []);

return (

<>
<br />
<br />
<div className=" htmlPayroll table-responsive container">
    <div className="row">
        <div className="lowcolor col-12">
            <h2 className="h2"><i class="fa fa-comments-o faa-flash red" ></i>&nbsp;Mis&nbsp;Notificaciones</h2>
            <hr />
        </div>
    </div>
    <div className=" table-responsive">
        {!dataLoading ?
        <>
            <div className='form-group'>
                <div className='table-responsive' Style='min-height:600px'> 
                <table id='TblMessage'  className='table table-striped table-bordered' Style='width:100% !important'></table> 
                {notificationCounter? <><span>&nbsp;No hay Notificaciones</span></>: <></>}
                </div>
            </div> 
        </>
        :
        <Loading />
        }                                                                                                                            
    </div>
</div> 
</>

    )
}
export default  MyInternalNotifications;