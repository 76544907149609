import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import API from "../../utils/api";
import Moment from "moment";
import queryString from "query-string";
import {
  ShowAlertMessage,
  ShowConfirmationMessage,
  ShowPopUp,
  MessageResults,
  GetGuiId,
} from "../../utils/CommonFunctions";
import { getUser, removeEvaluationSession } from "../../utils/Common";
import $ from "jquery";

$(document).ready(() => {
  let param = [];
  $("body").on("click", "#btnSendEvaluation", () => {
    if (validateEvaluation()) {
      ShowConfirmationMessage(SaveChanges, "", param);
    }
  });
  const validateEvaluation = () => {
    param = [];
    let dataItem = $(".csUlEvaluation");
    let isValid = true;
    let infoText = " Debe seleccionar alguna opción!";
    for (let index = 0; index < dataItem.length; index++) {
      const idIdx = index + 1;

      let input = $(".csUlEvaluation .css-control-" + idIdx);
      let type = $(".csUlEvaluation .css-type-" + idIdx);
      let controlRadio = $(".csUlEvaluation .css-validate-" + idIdx);
      if ($(controlRadio).find("input[type='radio']:checked").length === 0) {
        $(".evaluation-body")
          .find(".sp_validator-evaluation" + idIdx)
          .text(infoText);
        $(controlRadio).find("input[type='radio']:first").focus();
        isValid = false;
      } else {
        $($(".evaluation-body").find(".sp_validator-evaluation" + idIdx)).text(
          ""
        );
        param.push(
          $(input).find("input[type='hidden']:hidden").val(),
          $(controlRadio).find("input[type='radio']:checked").val(),
          $(type).find("input[type='hidden']:hidden").val()
        );
      }
    }

    return isValid;
  };
  const SaveChanges = (param) => {
    if($("#taComment").val()!=""){
    localStorage.setItem("EvaluationPending", "false");
    Swal.fire({
      title: "Guardando información!",
      html: "Por favor espere!", // add html attribute if you want or remove
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    API.postData("NewEvaluation/add", {
      evaluationCalendarId: parseInt($("#evaluationCalendarId").val()),
      employeeNumber: getUser().employeeIdCard    ,           
      supervisorObservation: $("#taComment").val(),
      nominated: "",
      winner: "",
      supervisorNumber: getUser().SupervisorId,
      departamentNumber: "",
      detalle: param,
      dateEvaluation: new Date(),
      companyId: getUser().companyId
    })
      .then((response) => {
        localStorage.setItem("EvaluationPending", "false");
        Swal.close();
        MessageResults(response.status);

        setTimeout(() => {
          localStorage.setItem("EvaluationPending", "false");
          window.location.href = "/";
        }, 1200);
      })
      .catch((error) => {
        ShowAlertMessage(
          "Información",
          "Hubo un problema intente de nuevo",
          "error"
        );
        console.log(error);
      });
    }else{
      ShowAlertMessage(
        "Debe agregar un comentario para continuar...",
       
      );
    }
  }; 


}); /*End jQuery Lines */

export default function Evaluations(props) {
  const id = queryString.parse(props.location.search).id;
  var url = "";
  if (id == "5") {
    url = "/NewEvaluation/GetNewEvaluationCalendarId";
  } else if (id == "6") {
    url = "/NewEvaluation/GetNewEvaluationCalendarId";
  }
  else if (id == "8") {
    url = "/NewEvaluation/GetNewEvaluationCalendarId";
  }

  const [evaluationName, setEvaluationName] = useState("");
  const [evaluationQuestion, setEvaluationQuestion] = useState([]);
  useEffect(() => {
    API.getData(
      "" +
        url +
        "?companyId=" +
        getUser().companyId +
        "&evaluationCalendarId=" +
        id +
        "&employeeNumber=" +
        getUser().employeeIdCard +
        "&evaluarAnual=" +
        getUser().hiringDate +
        "&fechaActual=" +
        Moment(new Date()).format("YYYY-MM-DD") +
        "&level=" +
        getUser().level
    )
      .then((res) => {
        if (res.status === 200) {
          let dataResult = [];
          let counterElement = 0;
          let infoText = "";
          if (res.data.length > 0) {
            setEvaluationName(res.data[0].evaluationCalendarName);
          }

          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            counterElement += 1;
            let dataPossibleAsw = [
              '<div  class="csUlEvaluation" data-id="' +
                element.evaluationQuestionId +
                '"> <div class="css-control-' +
                counterElement +
                ' form-check mb-3"> <input type="hidden" id="evaluationQuestionId" value="' +
                element.evaluationQuestionId +
                '"/></div> <div class="css-type-' +
                counterElement +
                ' form-check mb-3"> <input type="hidden" id="evaluationQuestionType" value="' +
                element.evaluationQuestionType +
                '"/> <input  id="evaluationCalendarId" type="hidden" value="' +
                element.evaluationCalendarId +
                '"/></div>',
            ];
            dataResult.push(
              '<p class=""><strong>' +
                counterElement +
                " - " +
                element.evaluationQuestionName +
                '</strong>&nbsp;<span class="fa faa-flash animated sp_validator-evaluation' +
                counterElement +
                ' red ">' +
                infoText +
                "</span></p>"
            );
            switch (element.evaluationQuestionType) {
              case "MULTIPLE":
                let dataItem = JSON.parse(element.evaluationPossibleAnswers);
                for (let index = 0; index < dataItem.length; index++) {
                  const item = dataItem[index];
                  let radioId = GetGuiId().substr(0, 16);
                  dataPossibleAsw.push(
                    '<div class="p-0">' +
                      '<div class="css-validate-' +
                      counterElement +
                      ' form-check mb-3">' +
                      '<div class="evaluation-square-radio">' +
                      '<input class="form-check-input" type="radio" id="rdo_' +
                      radioId +
                      '" name="' +
                      element.evaluationQuestionId +
                      '" value="' +
                      item.Id +
                      '" />' +
                      '<label class="form-check-label" for="rdo_' +
                      radioId +
                      '">' +
                      item.PossibleAnswersName +
                      "</label>" +
                      "</div>" +
                      "</div>" +
                      "</div>"
                  );
                }
                dataResult.push(dataPossibleAsw.join(""));
                dataResult.push("<hr>");
                break;
              case "SI_NO":
                let radioIdYes = GetGuiId().substr(0, 16);
                let radioIdNo = GetGuiId().substr(0, 16);
                dataPossibleAsw.push(
                  '<div class="p-0">' +
                    '<div class="css-validate-' +
                    counterElement +
                    ' form-check mb-3">' +
                    '<div class="evaluation-square-radio">' +
                    '<input class="form-check-input" type="radio" id="rdo_' +
                    radioIdYes +
                    '" name="' +
                    element.evaluationQuestionId +
                    '" value="1" />' +
                    '<label class="form-check-label" for="rdo_' +
                    radioIdYes +
                    '">Si</label>' +
                    "</div>" +
                    "</div>" +
                    "</div>" +
                    '<div class="p-0">' +
                    '<div class="css-validate-' +
                    counterElement +
                    ' form-check mb-3">' +
                    '<div class="evaluation-square-radio">' +
                    '<input class="form-check-input" type="radio" id="rdo_' +
                    radioIdNo +
                    '" name="' +
                    element.evaluationQuestionId +
                    '" value="0" />' +
                    '<label class="form-check-label" for="rdo_' +
                    radioIdNo +
                    '">No</label>' +
                    "</div>" +
                    "</div>" +
                    "</div>"
                );
                dataResult.push(dataPossibleAsw.join(""));
                dataResult.push("<hr>");

                break;
            }
          }

          dataResult.push(
            '<div class="p-0"><div class="form-check mb-1"><label class="form-check-label">Comentario</label><br><textarea name="supervisor_observation" id="taComment" rows="4" placeholder="Comentario..." style="width:100%;resize: none;"></textarea>' +
              '<br><button id="btnSendEvaluation" style="background-color:#ad172b;" class="btn-outline-danger" data-prefix=""><span>ENVIAR EVALUACION</span></button></div>'
          );
          dataResult.push("</div></div>");
          setEvaluationQuestion(dataResult.join(""));
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  }, []);

  return (
    <div>
      <section className="container d-flex align-items-center justify-content-center ">
        <div className="container">
          <br />
          <header className="special">
            <h5>{evaluationName}</h5>
          </header>
          {
            <div
              className="evaluation-body container"
              dangerouslySetInnerHTML={{ __html: evaluationQuestion }}
            />
          }
          <br />
          <br />
        </div>
      </section>
    </div>
  );
}
