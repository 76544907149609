import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import Button from "../../components/Button/Button";
import { getUser } from "../../utils/Common";
import $ from "jquery";

import Moment from "moment";
import { LangSpanish } from "../../tools/dataTables.Language";
import {
  ShowConfirmationMessage,
  MessageResults,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";

$(document).ready(() => {
  $("body").on("click", "#TblNew #btDel", function (e) {
    let param = JSON.parse(
      atob($(e.currentTarget).parent().attr("data-item"))
    )[0];
    ShowConfirmationMessage(
      SaveDisableChanges,
      "",
      param,
      "DESEA CANCELAR LA RESERVACIÓN"
    );
  });
  const SaveDisableChanges = (params) => {
    let id = params.id;
    API.putData("GymReservation/delete?id=" + id)
      .then((res) => {
        if (res.status === 200) {
          MessageResults(res.status);
          setTimeout(() => {
            window.location.reload(true);
          }, 1200);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
        MessageResults(400, err);
      });
  };
});

export default function GymRequest(props) {
  const [dataLoading, setDataLoading] = useState(true);
  const [faq, setFaq] = useState(true);
  const [term, setTerms] = useState(false);
  const [seis, setSeis] = useState(false);
  const [sieteAm, setSieteAm] = useState(false);
  const [cincoPm, setCincoPm] = useState(false);
  const [seisPm, setSeisPm] = useState(false);

  const [seisM, setSeisM] = useState(false);
  const [sieteAmM, setSieteAmM] = useState(false);
  const [cincoPmM, setCincoPmM] = useState(false);
  const [seisPmM, setSeisPmM] = useState(false);
  const [signedUp, setSignedIp] = useState(false);
  const [showScheduleForm, setShowScheduleForm] = useState(false);
  const [showGymForm, setShowGymForm] = useState(true);
  const [hoy, setHoy] = useState(false);
  const [man, setMan] = useState(false);

  const [button, setButton] = useState(false);
  const [table, setTable] = useState(false);
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };

  const cel = useFormInput("");
  const validar = () => {
    if ($("#address").val() === "") {
      $("#sp_dire").text("DEBE AGREGAR LA DIRECCIÓN");
    } else {
      $("#sp_dire").text("");
    }
    if ($("#mobile").val() === "") {
      $("#sp_mobile").text("DEBE AGREGAR EL CELULAR");
    } else {
      $("#sp_mobile").text("");
    }

    if ($("#blood").val() === "") {
      $("#sp_blood").text("DEBE AGREGAR SU TIPO DE SANGRE");
    } else {
      $("#sp_blood").text("");
    }

    if ($("#email").val() === "") {
      $("#sp_email").text("DEBE AGREGAR SU EMAIL");
    } else {
      $("#sp_email").text("");
    }

    if ($("#emergName").val() === "") {
      $("#sp_name").text("DEBE AGREGAR UN NOMBRE");
    } else {
      $("#sp_name").text("");
    }

    if ($("#emergaddress").val() === "") {
      $("#sp_direE").text("DEBE AGREGAR UNA DIRECCIÓN");
    } else {
      $("#sp_direE").text("");
    }
    if (cel.value === "") {
      $("#sp_celE").text("DEBE AGREGAR UN NÚMERO DE CELULAR");
    } else {
      $("#sp_celE").text("");
    }
    if (term == false) {
      ShowAlertMessage(
        "¡Mensaje de aviso!",
        " <p>DEBE DE ACEPTAR LOS TÉRMINOS</p>"
      );
    }

    if (
      $("#address").val() !== "" &&
      $("#mobile").val() !== "" &&
      $("#blood").val() !== "" &&
      $("#email").val() !== "" &&
      $("#emergName").val() !== "" &&
      $("#emergaddress").val() !== "" &&
      cel.value !== "" &&
      term !== false
    ) {
      add();
    }
  };

  const add = () => {
    API.postData("Gym/Add", {
      EmployeeIdCard: getUser().employeeIdCard,
      EmployeeNumber: getUser().employeeNumber,
      FullName: getUser().firstName + " " + getUser().lastName,
      SignedUp: false,
      Terms: term,
      Direction: $("#address").val(),
      Phone: $("#phone").val(),
      Mobile: $("#mobile").val(),
      Bloodtype: $("#blood").val(),
      Email: $("#email").val(),
      NameE: $("#emergName").val(),
      DirectionE: $("#emergaddress").val(),
      PhoneE: $("#emergTel").val(),
      MobileE: cel.value,
      Subscription: true,
    })
      .then((response) => {
        MessageResults(response.status);
        setTimeout(() => {
          window.location.href = "/gymrequest";
        }, 2000);
      })
      .catch((error) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Intente de nuevo, algo salio mal</p>"
        );
        console.log(error);
      });
  };

  const addGymReservation = () => {
    API.postData("GymReservation/Add", {
      EmployeeIdCard: getUser().employeeIdCard,
      SixAm: seisM,
      SevenAm: sieteAmM,
      FivePm: cincoPmM,
      SixPm: seisPmM,
      Name: getUser().firstName + " " + getUser().lastName,
      SixAmM: seis,
      SevenAmM: sieteAm,
      FivePmM: cincoPm,
      SixPmM: seisPm,
    })
      .then((response) => {
        MessageResults(response.status);
        setTimeout(() => {
          window.location.href = "/gymrequest";
        }, 2000);
      })
      .catch((error) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Intente de nuevo, algo salio mal</p>"
        );
        console.log(error);
      });
  };
  const date = () => {
    var fecha = new Date(); //Fecha actual
    var mes = fecha.getMonth() + 1; //obteniendo mes
    var dia = fecha.getDate(); //obteniendo dia
    var ano = fecha.getFullYear(); //obteniendo año
    if (dia < 10) dia = "0" + dia; //agrega cero si el menor de 10
    if (mes < 10) mes = "0" + mes; //agrega cero si el menor de 10
    var hoy = ano + "-" + mes + "-" + dia;

    $("#fechahoy").val(hoy);
  };
  const fechaDeManana = () => {
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let manana = new Date(hoy.getTime() + DIA_EN_MILISEGUNDOS);

    var mes = manana.getMonth() + 1;
    var dia = manana.getDate();
    var ano = manana.getFullYear();
    if (dia < 10) dia = "0" + dia;
    if (mes < 10) mes = "0" + mes;
    let actual = ano + "-" + mes + "-" + dia;

    $("#fechamanana").val(actual);
  };

  const fillData = () => {
    let Record = [];
    API.getData(
      "GymReservation/getbyemployee?employeeIdCard=" + getUser().employeeIdCard
    )
      .then((res) => {
        setDataLoading(false);
        if (res.status === 200) {
          let dataResult = [];

          setFaq(res.data);
          if (res.data.length === 1) {
            if (res.data[0].manana == "Hoy") {
              setHoy(false);
              setMan(true);
            } else {
              setHoy(true);
            }
            setTable(true);
          }

          if (res.data.length === 2) {
            setButton(true);
            setTable(true);
            setHoy(true);
            setMan(true);
          }

          let DeleteBtn =
            "<a href='#' id='btDel'  class='fa fa fa-trash custom-color size-effect-x2 red' title='Eliminar Usuario' ></a>";
          res.data.forEach((item) => {
            dataResult.push({
              id:
                '<span class="container d-flex align-items-center justify-content-center">' +
                item.id +
                "</>",
              employeeIdCard:
                '<span class="capitalized defaultText">' +
                item.employeeIdCard +
                "</span>",
              horario:
                '<span class="capitalized defaultText">' +
                item.horario +
                "</span>",
              day:
                '<span class="capitalized defaultText">' +
                Moment(item.day).format("DD/MM/YYYY  ") +
                "</span>",
              name:
                '<span class="capitalized defaultText">' +
                item.name +
                "</span>",

              itemBtn:
                "<span data-created='" +
                item.id +
                "'  data-item='" +
                btoa(JSON.stringify([item])) +
                "'>" +
                DeleteBtn +
                "</span>",
            });
          });

          $("#TblNew").DataTable({
            destroy: true,
            searching: true,
            language: LangSpanish,
            bLengthChange: false,
            lengthMenu: [10, 20, 40, 60, 80, 90, 100, 200],
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: ["copy", "excel", "pdf"],
            data:
              dataResult.length === 0
                ? [
                    {
                      id: "",
                      employeeIdCard: "",
                      horario: "",
                      day: "",
                      name: "",
                    },
                  ]
                : dataResult,
            columns: [
              {
                data: "name",
                title: "Nombre",
                width: "25%",
                className: "capitalized",
              },
              {
                data: "employeeIdCard",
                title: "Card",
                width: "25%",
                className: "capitalized",
              },
              {
                data: "horario",
                title: "Horario",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "day",
                title: "Día",
                width: "20%",
                className: "capitalized",
              },

              {
                data: "itemBtn",
                title: "\u00a0Acciones\u00a0\u00a0\u00a0",
                width: "30%",
                orderable: false,
              },
            ],
          });
          $(".csHidden").attr("style", "display:none");
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };

  useEffect(() => {
    API.getData("Gym/getAccept?employeeidcard=" + getUser().employeeIdCard)
      .then((resp) => {
        if (resp.data.length > 0 || resp.data.length == undefined) {
          setShowGymForm(false);
          setShowScheduleForm(resp.data.signedUp);
          date();
          fillData();
          fechaDeManana();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const check = (horario, tipo, dia) => {
    API.getData(
      "GymReservation/get?horario=" +
        horario +
        "&tipo=" +
        tipo +
        "&dia=" +
        dia +
        ""
    )
      .then((resp) => {
        if (resp.data === 11) {
          if (dia == "hoy") {
            if (tipo === "sixAm") {
              setSeisM(false);
            } else if (tipo === "sevenAm") {
              setSieteAmM(false);
            } else if (tipo === "fivePm") {
              setCincoPmM(false);
            } else if (tipo === "sixPm") {
              setSeisPmM(false);
            }
          } else if (dia === "mañana") {
            if (tipo === "sixAm") {
              setSeis(false);
            } else if (tipo === "sevenAm") {
              setSieteAm(false);
            } else if (tipo === "fivePm") {
              setCincoPm(false);
            } else if (tipo === "sixPm") {
              setSeisPm(false);
            }
          }

          ShowAlertMessage(
            "¡Mensaje de aviso!",
            " <p>CANTIDAD DE PERSONAS " + resp.data + "</p>"
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const OnClickSavegym = () => {
    let param = [];
    ShowConfirmationMessage(
      DisableRegister,
      "",
      param,
      "DESEA CANCELAR LA SUBCRIPCIÓN DEL GYM"
    );
  };
  const DisableRegister = (param) => {
    API.putData("Gym/DisableRegister?id=" + getUser().employeeIdCard)
      .then((res) => {
        if (res.status === 200) {
          MessageResults(res.status);
          setTimeout(() => {
            window.location.reload(true);
          }, 1200);
        }
      })
      .catch((error) => {
        ShowAlertMessage(
          "Información",
          "Hubo un problema intente de nuevo",
          "error"
        );
        console.log(error);
      });
  };
  return (
    <section id="main" className="wrapper">
      {showGymForm ? (
        <div className="inner">
          <h4 className="text-center">Formulario Inscripción Gimnacio</h4>
          <hr />
          <section>
            <h4>Datos personal</h4>
            <div id="myform" className="form_container">
              <div className="form_group">
                <label className="classspan text-left">Nombre Completo </label>
                <input
                  type="text"
                  className="txtbox"
                  value={getUser().firstName + " " + getUser().lastName}
                  readonly="true"
                />
              </div>
              <div className="form_group">
                <label className="classspan text-left">Cédula</label>
                <input
                  type="text"
                  className="txtbox"
                  value={getUser().employeeIdCard}
                  readonly="true"
                />
              </div>
              <div className="form_group">
                <label className="classspan text-left">
                  Fecha de Nacimiento
                </label>
                <input
                  type="text"
                  className="txtbox"
                  value={getUser().birthdate.split("T")[0]}
                  readonly="true"
                />
              </div>
              <div className="form_group">
                <label className="classspan text-left">
                  Número de empleado
                </label>
                <input
                  type="text"
                  className="txtbox"
                  value={getUser().employeeNumber}
                  readonly="true"
                />
              </div>
              <div className="form_group">
                <label className="float-left">Dirección </label>
                <span class="float-left defaultText red" id="sp_dire"></span>
                <textarea className="txtbox" id="address" name="address" />
              </div>
              <div className="form_group">
                <label className="classspan text-left">Teléfono</label>
                <input
                  type="number"
                  className="txtbox"
                  id="phone"
                  name="phone"
                />
              </div>
              <div className="form_group">
                <label className="classspan text-left">Celular</label>
                <span class="float-left defaultText red" id="sp_mobile"></span>
                <input
                  type="number"
                  className="txtbox"
                  id="mobile"
                  name="mobile"
                />
              </div>
              <div className="form_group">
                <label className="classspan text-left">Tipo de Sangre</label>
                <span class="float-left defaultText red" id="sp_blood"></span>
                <input type="text" className="txtbox" id="blood" name="blood" />
              </div>
              <div className="form_group">
                <label className="classspan text-left">
                  Correo Electrónico
                </label>

                <span class="float-left defaultText red" id="sp_email"></span>
                <input type="text" className="txtbox" id="email" name="email" />
              </div>
              <br />
              <h4>Datos Contacto de Emergencia</h4>
              <div className="form_group">
                <label className="classspan text-left">Nombre</label>

                <span class="float-left defaultText red" id="sp_name"></span>
                <input
                  type="text"
                  className="txtbox"
                  id="emergName"
                  name="emergName"
                />
              </div>
              <div className="form_group">
                <label className="classspan text-left">Dirección</label>

                <span class="float-left defaultText red" id="sp_direE"></span>
                <textarea
                  className="txtbox"
                  id="emergaddress"
                  name="emergaddress"
                />
              </div>
              <div className="form_group">
                <label className="classspan text-left">Celular</label>
                <span class="float-left defaultText red" id="sp_celE"></span>

                <input type="number" className="form-control" {...cel} />
              </div>
              <div className="form_group">
                <label className="classspan text-left">Teléfono</label>

                <span class="float-left defaultText red" id="sp_telE"></span>
                <input
                  type="number"
                  className="txtbox"
                  id="emergTel"
                  name="emergTel"
                />
              </div>

              <br />
              <div className="form_group">
                <a
                  href="/contratogym/contrato  gym DISCOR (003).pdf"
                  download="/contratogym/contrato  gym DISCOR (003).pdf"
                >
                  Ver Terminos
                </a>
              </div>
              <br />
              <div className="form_group">
                <input
                  type="checkbox"
                  id="temrs"
                  name="temrs"
                  defaultChecked={term}
                  checked={term}
                  onChange={(e) => setTerms(!term)}
                />
                <label for="temrs" className="classspan text-left">
                  ¿Acepta los términos?
                </label>
              </div>
              <br />
              <div className="form_group">
                <Button
                  type="btn-outline-danger"
                  text="ENVIAR"
                  clickHandler={validar}
                />
              </div>
            </div>
          </section>
        </div>
      ) : showScheduleForm ? (
        <div className="csGYMForm inner">
          <div class="form-group col-md-4 align-content-end">
            <button
              type="button"
              className="mybt btn btn-outline-danger text-wrap "
              onClick={OnClickSavegym}
            >
              <i className="fa fa-plus-circle"></i>&nbsp;CANCELAR SUBCRIPCIÓN
              GYM
            </button>
          </div>
          {table ? (
            <div>
              <div className="row">
                <div className="lowcolor col-12">
                  <br />
                  <h2 className="h2">Reservaciónes </h2>

                  <br />
                </div>
              </div>
              <div className="row ">
                <div className="container">
                  <div className="">
                    <div
                      className="scroll-table bordered"
                      Style="min-height:300px"
                    >
                      <table
                        id="TblNew"
                        className="table table-striped table-bordered display"
                        Style="width:100% !important"
                      ></table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {!button ? (
            <h4 className="text-center">HORARIO GIMNASIO DE LUNES A VIERNES</h4>
          ) : (
            <div></div>
          )}
          {hoy ? (
            <div></div>
          ) : (
            <div>
              <hr />
              <h4 className="text-left">APARTAR PARA HOY </h4>
              <br />
              <div className="form_group col-md-4">
                <input
                  type="date"
                  class="form-control"
                  id="fechahoy"
                  readOnly
                />
              </div>
              <br />

              <div className="form_group css-validate-">
                <input
                  type="checkbox"
                  id="seisM"
                  defaultChecked={seisM}
                  checked={seisM}
                  onChange={(e) => {
                    setSeisM(!seisM);
                    check(true, "sixAm", "hoy");
                    if (sieteAmM === true) {
                      setSieteAmM(!sieteAmM);
                    } else if (cincoPmM === true) {
                      setCincoPmM(!cincoPmM);
                    } else if (seisPmM === true) {
                      setSeisPmM(!seisPmM);
                    }
                  }}
                />

                <label for="seisM" className="classspan text-left">
                  7 AM - 8 AM
                </label>
              </div>
              <div className="form_group css-validate-">
                <input
                  type="checkbox"
                  id="sieteAmM"
                  name="sieteAmM"
                  defaultChecked={sieteAmM}
                  checked={sieteAmM}
                  onChange={(e) => {
                    setSieteAmM(!sieteAmM);
                    check(true, "sevenAm", "hoy");
                    if (seisM === true) {
                      setSeisM(!seisM);
                    } else if (cincoPmM === true) {
                      setCincoPmM(!cincoPmM);
                    } else if (seisPmM === true) {
                      setSeisPmM(!seisPmM);
                    }
                  }}
                />
                <label for="sieteAmM" className="classspan text-left">
                  8 AM - 9 AM
                </label>
              </div>
              <div className="form_group css-validate-3">
                <input
                  type="checkbox"
                  id="cincoPmM"
                  name="cincoPmM"
                  defaultChecked={cincoPmM}
                  checked={cincoPmM}
                  onChange={(e) => {
                    setCincoPmM(!cincoPmM);
                    check(true, "fivePm", "hoy");
                    if (seisM === true) {
                      setSeisM(!seisM);
                    } else if (sieteAmM === true) {
                      setSieteAmM(!sieteAmM);
                    } else if (seisPmM === true) {
                      setSeisPmM(!seisPmM);
                    }
                  }}
                />
                <label for="cincoPmM" className="classspan text-left">
                  5 PM - 6 PM
                </label>
              </div>
              <div className="form_group css-validate-4">
                <input
                  type="checkbox"
                  id="seisPmM"
                  name="seisPmM"
                  defaultChecked={seisPmM}
                  checked={seisPmM}
                  onChange={(e) => {
                    setSeisPmM(!seisPmM);
                    check(true, "sixPm", "hoy");
                    if (seisM === true) {
                      setSeisM(!seisM);
                    } else if (sieteAmM === true) {
                      setSieteAmM(!sieteAmM);
                    } else if (cincoPmM === true) {
                      setCincoPmM(!cincoPmM);
                    }
                  }}
                />
                <label for="seisPmM" className="classspan text-left">
                  6 PM - 7 PM
                </label>
              </div>
              <br />
            </div>
          )}

          {man ? (
            <div></div>
          ) : (
            <div>
              <h4 className="text-left">APARTAR PARA MAÑANA</h4>
              <br />
              <div className="form_group col-md-4">
                <input
                  type="date"
                  class="form-control"
                  id="fechamanana"
                  readOnly
                />
              </div>
              <br />
              <div className="form_group css-validate-">
                <input
                  type="checkbox"
                  id="seis"
                  defaultChecked={seis}
                  checked={seis}
                  onChange={(e) => {
                    setSeis(!seis);
                    check(true, "sixAm", "mañana");
                    if (sieteAm === true) {
                      setSieteAm(!sieteAm);
                    } else if (cincoPm === true) {
                      setCincoPm(!cincoPm);
                    } else if (seisPm === true) {
                      setSeisPm(!seisPm);
                    }
                  }}
                />

                <label for="seis" className="classspan text-left">
                  7 AM - 8 AM
                </label>
              </div>
              <div className="form_group css-validate-">
                <input
                  type="checkbox"
                  id="sieteAm"
                  name="sieteAm"
                  defaultChecked={sieteAm}
                  checked={sieteAm}
                  onChange={(e) => {
                    setSieteAm(!sieteAm);
                    check(true, "sevenAm", "mañana");
                    if (seis === true) {
                      setSeis(!seis);
                    } else if (cincoPm === true) {
                      setCincoPm(!cincoPm);
                    } else if (seisPm === true) {
                      setSeisPm(!seisPm);
                    }
                  }}
                />
                <label for="sieteAm" className="classspan text-left">
                  8 AM - 9 AM
                </label>
              </div>
              <div className="form_group css-validate-3">
                <input
                  type="checkbox"
                  id="cincoPm"
                  name="cincoPm"
                  defaultChecked={cincoPm}
                  checked={cincoPm}
                  onChange={(e) => {
                    setCincoPm(!cincoPm);
                    check(true, "fivePm", "mañana");
                    if (seis === true) {
                      setSeis(!seis);
                    } else if (sieteAm === true) {
                      setSieteAm(!sieteAm);
                    } else if (seisPm === true) {
                      setSeisPm(!seisPm);
                    }
                  }}
                />
                <label for="cincoPm" className="classspan text-left">
                  5 PM - 6 PM
                </label>
              </div>
              <div className="form_group text-center">
                <input
                  type="checkbox"
                  id="seisPm"
                  name="seisPm"
                  defaultChecked={seisPm}
                  checked={seisPm}
                  onChange={(e) => {
                    setSeisPm(!seisPm);
                    check(true, "sixPm", "mañana");
                    if (seis === true) {
                      setSeis(!seis);
                    } else if (sieteAm === true) {
                      setSieteAm(!sieteAm);
                    } else if (cincoPm === true) {
                      setCincoPm(!cincoPm);
                    }
                  }}
                />
                <label for="seisPm" className="classspan text-left">
                  6 PM - 7 PM
                </label>
              </div>
              <br />
            </div>
          )}
          {!button ? (
            <div class="form-group col-md-6 ">
              <button
                type="button"
                className="mybt btn btn-outline-danger text-wrap"
                onClick={addGymReservation}
              >
                ENVIAR RESERVACIÓN
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <section id="main" className="wrapper">
          <div className="inner">
            <section>
              <h1>¡Aviso!</h1>
              <div id="myform" className="form_container">
                <p>Debe pasar por el dispensario médico para </p>
                <p>realizarse un examen de evaluación y </p>
                <p>llevar esos resultados a Gente y Gestión</p>
                <p>para poder aceptar su solicitud</p>
              </div>
            </section>
          </div>
        </section>
      )}
    </section>
  );
}
