import React from 'react'
import API from '../../utils/api';
import { MessageResults, ShowAlertMessage, ShowConfirmationMessage } from '../../utils/CommonFunctions';



export const addPermission = async (permission)=>{
    return new Promise((resolve, eject)=>{
        API.postData("EmployeePermision/Add", permission)
            .then((response) => {
              setTimeout(() => {
                MessageResults(response.status);
              }, 1500);
            })
            .catch((error) => {
              ShowAlertMessage(
                "¡Mensaje de aviso!",
                " <p>Intente de nuevo, algo salio mal al enviar el permiso</p>"
              );
              console.log(error);
            });
    });
}



export const deletePermission = async (id)=>{
  
  return new Promise((resolve, eject)=>{
      API.deleteData(`EmployeePermision/delete?id=${id}`)
          .then((response) => {
            if(response.status == 200){
              MessageResults(response.status);
              setTimeout(() => {
                window.location.reload(true);
            }, 1200);
            }
          })
          .catch((error) => {
            ShowAlertMessage(
              "¡Mensaje de aviso!",
              " <p>Intente de nuevo, algo salio mal al eliminar el permiso</p>"
            );
            console.log(error);
          });
  });
}

export const getPermitType = () => {
  return new Promise( (resolve, eject) => {
      API.getData(`PermitType/getPermitType`).then( response =>{
          resolve(response);
      });
  })    
};


const SaveDisablePolicies = (params) => {
  //props.history.push("/policies");
  window.location.href = '/policies';
};

export const getPolicies = (number, companyId, level) =>{
  API.getData(
    "Policies/get?employee=" +
      number +
      "&companyId=" +
      companyId +
      "&level=" +
      level
  )
    .then((response) => {
      console.log(response.data)
        response.data.map((data) =>{
          if(window.location.pathname !== "/genericpolicies" && window.location.pathname !== "/policies"){
            if(data.isRequired === 'Y'){
              if(data.leida === false){
                ShowConfirmationMessage(
                  SaveDisablePolicies,
                  "warning",
                  [],
                  "DEBES LEER Y ACEPTAR POLITICAS PENDIENTES"
                );
              }
            }
          }
        })
    })
    .catch((error) => {
      console.log(error);
    });
}