import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import Button from "../../components/Button/Button";
import { getUser } from "../../utils/Common";
import $ from "jquery";
import {
  ShowPopUp,
  MessageResults,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";
import { Grid } from "@material-ui/core";

export default function AddMedicalLicense(props) {
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };
  const description = useFormInput("");
  const [uni, setUnit] = useState("");
  const [titleValid, setTitleValid] = useState(true);
  
  const addMedicalLicense = async () => {
    let image = "";
    let dataUpload = $("#btn-file")[0];
    let formData = new FormData();
    formData.append("postedFiles", dataUpload.files[0]);
    await API.postData("MedicalLicenses/UploadFiles", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        image = res.data[0];
        //comment field validation
        if (description.value.toLowerCase().trim().length < 2) {
          return setTitleValid(false);
        }
        sendMedicalLicense(image);
        setTimeout(() => {
           window.location.reload(true);
        }, 1200);
        
      })
      .catch(function (err) {
        ShowAlertMessage(
          "Información",
          "Hubo un problema intente de nuevo",
          "error"
        );
        console.error("Error de conexion " + err);
      });
  };

  const sendMedicalLicense = (image) => {
    API.postData("MedicalLicenses/Add", {
      employeeNumber: getUser().employeeNumber,
      employee: getUser().firstName + " " + getUser().lastName,
      description: description.value.toLowerCase(),
      companyId: getUser().companyId,
      img: image,
      inactive: "N",
    })
      .then((response) => {
        setTimeout(() => {
          MessageResults(response.status);
        }, 1500);
      })
      .catch((error) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Intente de nuevo, algo salio mal al enviar la Licencia Médica</p>"
        );
        console.log(error);
      });
  };

  const convertiraBase64 = (e) => {
    const output = document.getElementById("output");
    output.src = URL.createObjectURL(e.target.files[0]);

    output.onload = function () {
      const url = URL.revokeObjectURL(output.src); // free memory
    };
    console.log(output);
  };

  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <h4 className="text-center">ENVIAR LICENCIA MÉDICA</h4>
        <hr />

        <section>
          <div id="myform" className="form_container">
            <div className="form_group">
              <label className="classspan text-left">Nombre Completo</label>

              <input
                type="text"
                className="txtbox"
                value={getUser().firstName + " " + getUser().lastName}
                readonly="true"
              />
            </div>
            <br />
            <div class="form-group ">
              <Grid item sm={3} xs={6}>
                <button class="contenedor-btn-file" style={{ color: "white" }}>
                  <i class="fa fa-picture-o" style={{ color: "white" }}></i>
                  Adjuntar archivo
                  <label for="btn-file"></label>
                  <input
                    id="btn-file"
                    type="file"
                    i
                    name="logo"
                    onChange={(e) => convertiraBase64(e)}
                    multiple
                  />
              </button>
              </Grid>
              <br/>
              <Grid item sm={3} xs={6}>
                <img id="output" width="250" height="200"/>
              </Grid>
            </div>

            <div className="form_group">
              <label className="classspan text-left">Comentario: </label>
              <textarea
                type="text"
                className={`form-control ${!titleValid && "is-invalid"}`}
                placeholder="Comentario"
                rows="4"
                {...description}
                cols="50"
                tyle={{
                  resize: "none",
                }}
              ></textarea>
              <br />
              <Button
                type="btn-outline-danger"
                text="ENVIAR LICENCIA MÉDICA"
                clickHandler={addMedicalLicense}
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
