import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import Button from "../../components/Button/Button";
import {
  ShowPopUp,
  ShowConfirmationMessage,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";

export default function AnonymeLine(props) {
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };
  const detailSuggestion = useFormInput("");
  const sendMail = () => {
    API.postData("EmailSender/AnonymousLineEmail", {
      Email: GetCssByCompany().emailSupport,
      Message: detailSuggestion.value.toLowerCase(),
    })
      .then((response) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Mensaje anonimo enviada correctamente</p>"
        );
        props.history.push("/anonymeline");
      })
      .catch((error) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Intente de nuevo, algo salio mal al enviar su Mensaje anónima </p>"
        );
        console.log(error);
      });
  };

  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <h4 className="text-center">
          El reporte de tus casos nos ayudan a ser mejores.
        </h4>
        <hr />
        <div className="jumbotron">
          <b>
            <p className="text-center">
              En Distruibuidora Corripio nos encanta saber de que manera podemos
              mejorar tu espacio de trabajo.
              <br />
              Comparte tus casos en el siguiente formulario.
            </p>
          </b>
        </div>

        <section>
          <center>
            <div id="myform" className="form_container">
              <div className="form_group">
                <label className="classspan text-left">Detalle del caso</label>
                <textarea
                  type="text"
                  rows="4"
                  {...detailSuggestion}
                  cols="50"
                ></textarea>
                <br />
                <Button
                  type="btn-outline-danger"
                  text="ENVIAR CASO"
                  clickHandler={sendMail}
                />
              </div>
            </div>
          </center>
        </section>
      </div>
    </section>
  );
}
