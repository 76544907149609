import React, { useState } from "react";
import API from '../../utils/api';
import Button from "../../components/Button/Button";

export default function Ticket() {

    const useFormInput = initialValue => {
        const [value, setValue] = useState(initialValue);

        const handleChange = e => {
            setValue(e.target.value);
        }
        return {
            value,
            onChange: handleChange
        }
    }
    const ticket = useFormInput("");
    const [datas, setData] = useState([]);

    const handleTicket = () => {
         API.getData('ServiceDesk/ticketstatus?ticketId=' + ticket.value).then(response => {
             var result = response.data;
             console.log(result[0].id)
             setData(result);
         }).catch(error => {
             console.log(error);
         });
    }

    return (
        <div className="container">
            <br />
            <br />            
            <section className="">
                <div className="inner">
                <h3 Style={"text-align:center"}>Consulta tu solicitud</h3>
            <br />
                    <input
                        className="form-control"
                        placeholder="Número de Ticket"
                        {...ticket}
                    />
                    <hr />

                    <div className="col-lg-2 col-md-12 col-sm-12">
                        <div className="input-group mb-2">
                            <Button
                                type="btn-outline-danger"
                                text="Consultar"
                                clickHandler={handleTicket}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="content2">
                        {datas.map((item) => (
                            <div className="body-details-search">
                                <header key={item.id}>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <h6>
                                        &nbsp;ID
                                        </h6>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-4">
                                        <span>&nbsp;{item.id}&nbsp;</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-6">
                                        <h6>
                                        &nbsp;T&iacute;tulo del caso
                                        </h6>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-4">
                                        <span>&nbsp;{item.asunto}&nbsp;</span>
                                        </div>
                                    </div>
                                    <hr />                                
                                    <div className="row">
                                        <div className="col-md-6">
                                        <h6>
                                        &nbsp;Solicitante
                                        </h6>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-4">
                                        <span>&nbsp;{item.solicitante}&nbsp;</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-6">
                                        <h6>
                                        &nbsp;Email Solicitante
                                        </h6>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-4">
                                        <span>&nbsp;{item.email_Solicitante}&nbsp;</span>
                                        </div>
                                    </div>
                                    <hr />           
                                    <div className="row">
                                        <div className="col-md-6">
                                        <h6>
                                        &nbsp;&Aacute;rea Asignada
                                        </h6>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-4">
                                        <span>&nbsp;{item.area_Asignada}&nbsp;</span>
                                        </div>
                                    </div>
                                    <hr /> 
                                    <div className="row">
                                        <div className="col-md-6">
                                        <h6>
                                        &nbsp;Prioridad
                                        </h6>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-4">
                                        <span>&nbsp;{item.priority}&nbsp;</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-6">
                                        <h6>
                                        &nbsp;Estatus
                                        </h6>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-4">
                                        <span>&nbsp;{item.status}&nbsp;</span>
                                        </div>
                                    </div>
                                    <hr /> 
                                    <div className="row">
                                        <div className="col-md-12">
                                        <h6>
                                        &nbsp;Comentario
                                        </h6>
                                        <p>&nbsp;{item.comment}&nbsp;</p>
                                        </div>
                                         
 
                                    </div>
                                    <hr />
                                </header>                                
                            </div>

                        ))}
                    </div>
                </div>
            </section>
            <br />
        </div>
        
    );
};
