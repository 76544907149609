import React from "react";
import { getUser } from "../../utils/Common";
import { NumbersWithComma } from "../../utils/CommonFunctions";

export default function MyTool() {
  return (
    <section id="main" className="wrapper">
      <div className="inner">
      <h2 Style={"text-align:center"}>Mis Herramientas</h2>
      <br />
      <br />
        <section>
          <div className="content2">
            <header>
              <h6>Tipo de Combustible</h6>
              <p> </p>
              <hr />
              <h6>Galones de Combustible</h6>
              <p>{getUser().gallonsOfFuel ? getUser().gallonsOfFuel : "N/A"}</p>
              <hr />
              <h6>Movilidad</h6>
              <p>
                {getUser().mobility
                  ? NumbersWithComma(getUser().mobility)
                  : "N/A"}
              </p>
              <hr />
              <h6>Computadora</h6>
              <p>{getUser().computer ? getUser().computer : "N/A"}</p>
              <hr />
              <h6>Dispositivo M&oacute;vil</h6>
              <p>{getUser().mobileDevice ? getUser().mobileDevice : "N/A"}</p>
              <hr />
              <h6>Plan de datos</h6>
              <p>{getUser().dataPlan ? getUser().dataPlan : "N/A"}</p>
              <hr />
              <h6>Plan de minutos</h6>
              <p>{getUser().minutePlan ? getUser().minutePlan : "N/A"}</p>
            </header>
          </div>
        </section>
      </div>
    </section>
  );
}
