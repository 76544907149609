import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Button from "../Button/Button";
import DropdownList from "../dropdown/dropdownList";
import "./myrequests.css";
import {
  ShowPopUp,
  ShowConfirmationMessage,
  ShowAlertMessage,
  MessageResults,
} from "../../utils/CommonFunctions";
import { GetLetter } from "./letters/letterWitoutSalary";
import { GetLetterWithSalary } from "./letters/letterWithSalary";
import { GetLetterBankWithSalary } from "./letters/letterBankWithSalary";
import { GetLetterConsulateWithSalary } from "./letters/letterConsulateWithSalary";
import { GetLetterOtherWithSalary } from "./letters/letterOtherWithSalary";
import API from "../../utils/api";
import {
  LetterToDisplay,
  ShowHideContinueBtn,
  GetLetterParam,
  ParamSender,
  ClearSessions,
} from "./validateLetter";

const MakeRequestsServices = ({ props }) => {
  const [dropCardType, setDropCardType] = useState([]);
  const [dropSalaryFreq, setDropSalaryFreq] = useState([]);
  const [dropBankLetter, setDropBank] = useState([]);
  const [dropConsulateLetter, setDropConsulate] = useState([]);
  const [otherLetter, setOtherLetter] = useState([]);
  const [continueBtn, setContinueBtn] = useState([]);

  const GetCardType = () => {
    API.getData("CardType/CardTypeSort?sort=asc")
      .then((res) => {
        if (res.status === 200) {
          let dropData = [];
          res.data.forEach((item) => {
            if (item.inactive.toString().toUpperCase() === "N") {
              dropData.push({
                label: item.cardTypeName,
                value: item.cardTypeId.toString(),
              });
            }
          });
          let Drop = (
            <DropdownList
              idControl="dropLetterType"
              headerLabel="Tipo de carta"
              data={dropData}
              handleChange={OnDropCardTypeChange}
            />
          );
          setDropCardType(Drop);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };

  const GetSalaryFreq = () => {
    API.getData("SalaryFrequency/Get")
      .then((res) => {
        if (res.status === 200) {
          let dropData = [];
          res.data.forEach((item) => {
            if (item.inactive.toString().toUpperCase() === "N") {
              dropData.push({
                label: item.salaryFrequencyName,
                value: item.salaryFrequencyId,
                dataItem: item.amountFrecuency,
              });
            }
          });
          let Drop = (
            <DropdownList
              idControl="dropSalary"
              headerLabel="Como mostrar su sueldo"
              data={dropData}
              handleChange={OnDropSalaryChange}
            />
          );
          setDropSalaryFreq(Drop);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };
  const GetBank = () => {
    API.getData("Bank/BanksSort?sort=asc")
      .then((res) => {
        if (res.status === 200) {
          let dropData = [];
          res.data.forEach((item) => {
            if (item.inactive.toString().toUpperCase() === "N") {
              dropData.push({ label: item.bankName, value: item.id });
            }
          });
          let Drop = (
            <DropdownList
              idControl="dropBankType"
              headerLabel="Entidad Bancaria"
              data={dropData}
              handleChange={OnDropBankChange}
            />
          );
          setDropBank(Drop);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };
  const GetConsulate = () => {
    API.getData("Consulate/ConsulateTypeSort?sort=asc")
      .then((res) => {
        if (res.status === 200) {
          let dropData = [];
          res.data.forEach((item) => {
            if (item.inactive.toString().toUpperCase() === "N") {
              dropData.push({ label: item.consulateName, value: item.id });
            }
          });
          let Drop = (
            <DropdownList
              idControl="dropConsulateType"
              headerLabel="Consulado"
              data={dropData}
              handleChange={OnDropConsularChange}
            />
          );
          setDropConsulate(Drop);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };

  const OnDropCardTypeChange = (selectedOption) => {
    sessionStorage.setItem("CardTypeValue", selectedOption.value);
    setDropSalaryFreq([]);
    setOtherLetter([]);
    setDropBank([]);
    setDropConsulate([]);
    if (selectedOption.label.toLowerCase().includes("sin salario")) {
      GetButton();
    } else if (selectedOption.label.toLowerCase().includes("pueda interesar")) {
      GetButton(true);
      GetSalaryFreq();
    } else if (selectedOption.label.toLowerCase().includes("carta otro")) {
      GetButton(true);
      GetSalaryFreq();
      setOtherLetter(
        <div className="container">
          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="tbOtherLetter">
                Nombre de la instituci&oacute;n referida
              </label>
              <input
                type="text"
                id="tbOtherLetter"
                onChange={OnOtherLetterChange}
                className="textInfo letterFields form-control"
                name="tbOtherLetter"
              />
            </div>
          </div>
        </div>
      );
    } else if (selectedOption.label.toLowerCase().includes("carta consular")) {
      GetButton(true);
      GetSalaryFreq();
      GetConsulate();
    } else if (selectedOption.label.toLowerCase().includes("carta bancaria")) {
      GetButton(true);
      GetSalaryFreq();
      GetBank();
    }
  };
  const OnDropSalaryChange = (selectedOption) => {
    sessionStorage.setItem("SalaryAmount", selectedOption.dataItem);
    sessionStorage.setItem("SalaryValue", selectedOption.value);
    GetButton(ShowHideContinueBtn());
  };
  const OnDropBankChange = (selectedOption) => {
      sessionStorage.setItem("BankValue", selectedOption.value);
    GetButton(ShowHideContinueBtn());
  };
  const OnDropConsularChange = (selectedOption) => {
      sessionStorage.setItem("ConsulateValue", selectedOption.value);
    GetButton(ShowHideContinueBtn());
  };
  const OnOtherLetterChange = () => {
    GetButton(ShowHideContinueBtn());
  };
  
  const onButtonClick = () => {
    let DataResult = GetLetterParam(LetterToDisplay());
    switch (DataResult.LetterToDisplay) {
      case "letterWithoutSalary":
        ShowPopUp({
          handlerEvent: SaveLetter,
          htmlBody: GetLetter({ props: DataResult }),
          TextOk: "Enviar Solicitud",
          isDisabled:true,
        });
        break;
      case "letterWithSalary":
        ShowPopUp({
          handlerEvent: SaveLetter,
          htmlBody: GetLetterWithSalary({ props: DataResult }),
          TextOk: "Enviar Solicitud",
          isDisabled:true,
        });
        break;
      case "letterBankWithSalary":
        ShowPopUp({
          handlerEvent: SaveLetter,
          htmlBody: GetLetterBankWithSalary({ props: DataResult }),
          TextOk: "Enviar Solicitud",
          isDisabled:true,
        });
        break;
      case "letterConsulateWithSalary":
        ShowPopUp({
          handlerEvent: SaveLetter,
          htmlBody: GetLetterConsulateWithSalary({ props: DataResult }),
          TextOk: "Enviar Solicitud",
          isDisabled:true,
        });
        break;
      case "letterOtherWithSalary":
        ShowPopUp({
          handlerEvent: SaveLetter,
          htmlBody: GetLetterOtherWithSalary({ props: DataResult }),
          TextOk: "Enviar Solicitud",
          isDisabled:true,
        });
        break;

      default:
        ShowAlertMessage(
          "Información",
          "Favor asegurarse de tener todos los campos completados"
        );
        break;
    }
  };
  const SaveLetter = () => {
    API.postData("StaffLetter/Add", ParamSender())
      .then((res) => {
            MessageResults(res.status)
      })
      .catch(function (err) {
          MessageResults(400, err)
        console.error("Error de conexion " + err);
      });   
  };

  const GetButton = (disabledBtn) => {
    setContinueBtn(
      <div className="container ">
        <div className="row">
          <div className="col-md-12">
            <Button
              isDisabled={disabledBtn ?? false}
              type="btnRequest btn-outline-danger text-wrap"
              text="CONTINUAR"
              clickHandler={onButtonClick}
            />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    ClearSessions(["all"]);
    GetCardType();
    GetButton(true);
  }, []);

  return (
    <>
      <br />
      <div className="  htmlMyrequests table-responsive container">
        <div className="row">
          <div className="col-12">
            <div className="row mb-2"></div>
            <h2 className="h2">
              Mis&nbsp;Solicitudes&nbsp;
              <i className="fa faa-flash animated">
                <i className="red fa fa-arrow-circle-right"></i>
                &nbsp;(Paso&nbsp;2&nbsp;de&nbsp;3)
              </i>
            </h2>
            <hr />
          </div>
          <div className=" col-12">
            <div className="input-group-prepend">
              <div className="text-wrap textInfo mb-2">
                <span>
                  Seleccione el tipo de carta que necesite de acuerdo a sus
                  necesidades.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div id="dropFields" className="row mb-2">
          {dropCardType}
          {dropSalaryFreq}
          {dropBankLetter}
          {dropConsulateLetter}
          {otherLetter}
          {continueBtn}
        </div>
      </div>
    </>
  );
};
export default MakeRequestsServices;
