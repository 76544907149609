import React, { useState, useEffect } from 'react';
import API from '../../utils/api';
import { getUser } from "../../utils/Common";
import BackButton from "../../components/Button/BackButton";

export default function Historia() {

    const [history, setHistory] = useState("");

    useEffect(() => {
        API.getData('company/get?companyId='+ getUser().companyId).then(response => {
            
            setHistory(response.data[0].history);
        }).catch(error => {
            console.log(error);
        });
      },[]);

    return ( 
        <section id="main" className="wrapper">
            <div className="inner">
                <div className="content">
                    <header>
                    <h2 Style={"text-align:center"}>Historia</h2>

                    </header>
                    <br></br>
                    <div dangerouslySetInnerHTML={{__html: history}} />
                </div>
            </div>
        </section>
    )
}