import React from "react";
import { GetImagePatch } from "../../utils/CommonFunctions";
import Zendesk from "react-zendesk";

export default function ZendeskSdk(props) {

    const ZENDESK_KEY = "88bf7e0d-e2ad-459a-b384-372e50d1147d";
    const setting = {
        color: {
            theme: "#6204AA"
        },
        webWidget: {
            contactOptions: {
                enabled: true,
                // contactButton: { "*": "Get in touch" },
                // chatLabelOnline: { "*": "Live Chat" },
                // chatLabelOffline: { "*": "Live Chat is unavailable" }
            },
            helpCenter: {
                // chatButton: {
                //     "*": "Chat with us now"
                // }
            },
            chat: {
                profileCard: {
                    avatar: true,
                    rating: false,
                    title: true
                }
            }
        }
    };

    const handleLoadEvent = () => {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let name = params.get('name');
        let email = params.get('email');

        window.zE("webWidget", "identify", {
            name: name || 'Anonimo',
            email: email || '',
            // organization: 'Corripio'
        });

        // window.zE("webWidget:on", "open", function () {
        //     console.log("Widget opened!");
        // });

        // window.zE("webWidget:on", "close", function () {
        //     console.log("Widget closed!");
        // });

        // window.zE("webWidget", "helpCenter:setSuggestions", {
        //     search: "student success app"
        // });
    };


    return (
        <div className="login_container">
            <h4 className="text-center">Zendesk</h4>
            <div id="myform" className="form_container">
                <hr />
                <br />
                <div>
                    <img
                        src={GetImagePatch("/images/logo512.png")}
                        className="size-effect img-fluid"
                        alt=""
                    />

                </div>
                <Zendesk
                    zendeskKey={ZENDESK_KEY}
                    {...setting}
                    onLoaded={handleLoadEvent}
                />
            </div>

        </div>
    );
}
