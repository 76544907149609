import React from "react";
import PayrollServices from "../../components/payroll/payroll";
import { getUser } from "../../utils/Common";

export default function Payroll(props) {
  const usre = getUser();

  return (
    <div>
      <PayrollServices props={props} />
    </div>
  );
}
