import React, { useState, useEffect } from "react";
import Button from "../Button/Button";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import $ from 'jquery';
import Moment from "moment";
import es from "date-fns/locale/es";
import "moment/locale/es";
import { getUser, removeUserSession } from "../../utils/Common";
import API from "../../utils/api";
import Loading from "../loading/loading";
import {ShowConfirmationMessage, MessageResults, ShowPopUp} from "../../utils/CommonFunctions";
import { DataTable } from 'datatables.net';
import { LangSpanish } from "../../tools/dataTables.Language";


registerLocale("es", es);

$(document).ready(()=>{
    $('body').on('click', '#TblVacationAdmin #btViewInfo', function(){
        let dataItem = JSON.parse(atob($(this).attr('data-item')))[0];
        let tableData = "<br /><div class='container d-flex flex-column'><hr />" +
                            "<div class='form-group'>" +
                                "<div class='divLeft'><label for='tbName'>Nombre:</label></div>" +
                                "<div class='input-group'>" +
                                    "<div class='input-group-prepend'>" +
                                    "<div class='input-group-text divSecond'><i class='fa fa-user-circle'></i></div>" +
                                    "</div>" +
                                    "<input type='text' class='form-control capitalized' id='tbName' title='Correo: "+ dataItem.Email + ", Tel:("+ dataItem.Phone +") (ext: "+ dataItem.Extention + "') value='"+ dataItem.EmployeeFullName + (dataItem.Department === null ? "" : " (" + dataItem.Department+ ")" )  +"' readonly />" +
                                "</div>" +
                            "</div>" +                             
                            "<div class='form-group'>" +
                            "<div class='divLeft'><label for='tbDateSol'>Fecha&nbsp;Solicitud:</label></div>" +
                            "<div class='input-group mb-2'>" +
                                "<div class='input-group-prepend'>" +
                                "<div class='input-group-text divSecond'><i class='fa fa-calendar'></i></div>" +
                                "</div>" +
                                "<input type='text' class='form-control' id='tbDateSol' value='"+ (dataItem.CreationDate === null ? "-" : dataItem.CreationDate)  +"' readonly />" +
                            "</div>" +                             
                            "</div>" +
                            "<div class='form-row'>" +
                                "<div class='form-group col-md-4'>" +
                                    "<div class='divLeft'><label for='tbDaysAmt'>D&iacute;as&nbsp;Correspond.:</label></div>" +
                                    "<input type='text' class='form-control' id='tbDaysAmt' value='"+ (dataItem.VacationDays === null ? "-" : dataItem.VacationDays)  +"' readonly />" +
                                "</div>" + 
                                "<div class='form-group col-md-4'>" +
                                    "<div class='divLeft'><label for='tbDaysPendingAmt'>D&iacute;as&nbsp;Disponibles:</label></div>" +
                                    "<input type='text' class='form-control' id='tbDaysPendingAmt' value='"+ (dataItem.VacationPendingDays === null ? "-" : dataItem.VacationPendingDays)  +"' readonly />" +
                                "</div>" + 
                                "<div class='form-group col-md-4'>" +
                                    "<div class='divLeft'><label for='tbDaysRequest'>D&iacute;as&nbsp;Solicitados:</label></div>" +
                                    "<input type='text' class='form-control' id='tbDaysRequest' value='"+ (dataItem.HolidaysTaken === null ? "-" : dataItem.HolidaysTaken)  +"' readonly />" +
                                "</div>" +                                                                 
                            "</div>" +
                            "<div class='form-row'>" +
                                "<div class='form-group col-md-6'>" +
								"<div class='divLeft'><label for='tbDtFrom'>Desde:</label></div>" +
                                "<div class='input-group mb-2'>" +
                                    "<div class='input-group-prepend'>" +
                                    "<div class='input-group-text divSecond'><i class='fa fa-calendar'></i></div>" +
                                    "</div>" +
                                    "<input type='text' class='form-control' id='tbDtFrom' value='"+ (dataItem.FromDate === null ? "-" : dataItem.FromDate)  +"' readonly />" +
                                "</div>" +
                                "</div>" +
                                "<div class='form-group col-md-6'>" +
								"<div class='divLeft'><label for='tbDtTo'>Hasta:</label></div>" +
                                "<div class='input-group mb-2'>" +
                                    "<div class='input-group-prepend'>" +
                                    "<div class='input-group-text divSecond'><i class='fa fa-calendar'></i></div>" +
                                    "</div>" +
                                    "<input type='text' class='form-control' id='tbDtTo' value='"+ (dataItem.ToDate === null ? "-" : dataItem.ToDate)  +"' readonly />" +
                                "</div>" +
                                "</div>" +                                
                            "</div>" +                              
                            "<div class='form-group'>" +
                                "<div id='divRdo' data-item='"+ dataItem.vacationRequestId  +"' data-nbr='"+ dataItem.EmployeeNumber  +"' class='form-check vacation-square-radio  container d-flex align-items-center justify-content-center'>" +
                                    "<input class='form-check-input' type='radio' name='vacationRdo' id='rdAproved' value='2'>" +
                                    "<label class='form-check-label' for='rdAproved'>Aprobar</label>&nbsp;&nbsp;" +
                                    "<input class='form-check-input' type='radio' name='vacationRdo' id='rdDeclined' value='3'>" +
                                    "<label class='form-check-label' for='rdDeclined'>Declinar</label>" +
                                "</div>" +                                
                            "</div><hr />" +                                                                                                               
                        "</div>";

        ShowPopUp({titleName: "Aprobar Solicitud", htmlBody: tableData, handlerEvent: OnClickSave, TextOk:"Guardar", isDisabled:true, EnabledDisabled:true})
        $("#divRdo.vacation-square-radio input[type='radio']").click((e)=>{
            let contrlRdo = $(e.currentTarget).parent();
            let btnOk = $(".swal2-confirm.swal2-styled");
            if($(contrlRdo).find("input[type='radio']:checked").length){
                $(btnOk).attr('disabled',false);
            }else{
                $(btnOk).attr('disabled',true);
            }

        })
    })    
const OnClickSave = ()=>{
 let param = {
                 vacationRequestId:  parseInt($("#divRdo").attr('data-item')), 
                 vacationRequestStatusId: parseInt($("#divRdo.vacation-square-radio  input[type='radio']:checked").val()),}; 
    ShowConfirmationMessage(SaveChanges, '',param);
}
const SaveChanges = (params)=>{ 
      var isApprove = params.vacationRequestStatusId == 2 ? true : false;
      API.putData("vacation/setVacation?requestId="+params.vacationRequestId+"&isApprove="+isApprove)
      .then((res) => {
          if (res.status === 200) {
              MessageResults(res.status);
              setTimeout(() => {
                  window.location.reload(true);
              }, 1200);
          } 
      })
      .catch(function (err) {
      console.error("Error de conexion " + err);
          MessageResults(400, err);
      });     
}

})

const VacationAdministrator = (props)=> {
   const [dataLoading, setDataLoading] = useState(true); 
 
async  function  fillData() { 
    let Record = [];
    await   API.getData("/Vacation/GetbySupervisorNumber?employeeNumber="+ getUser().employeeIdCard.trim() + "&companyId=" + getUser().companyId )
      .then((res) => {
        setDataLoading(false);
        if (res.status === 200) {
            let dataResult = []; 
            res.data.forEach(item => {
                let EmployeeData = JSON.parse(item.employeeData);
                let EmployeeItemData = [];
                EmployeeData.forEach(element => {
                    if(element.EmployeeNumber === item.employeeNumber){
                        EmployeeItemData.push({
                            EmployeeFullName: element.EmployeeFullName,
                            Department: (typeof(element.Department) ==="string"? element.Department.toLowerCase(): ""),
                            Email: (typeof(element.Email) ==="string"? element.Email.toLowerCase(): ""),
                            Phone: element.Phone,
                            Extention: element.Extention,
                            vacationRequestId: item.vacationRequestId,
                            HolidaysTaken:item.holidaysTaken,
                            CreationDate: Moment(item.creationDate).format("DD/MM/YYYY hh:mm A"),
                            FromDate: Moment(item.dateFrom).format("DD/MM/YYYY"),
                            ToDate:Moment(item.dateTo).format("DD/MM/YYYY"),
                            EmployeeNumber: element.EmployeeNumber,
                            VacationDays: (element.VacationDays ?? 0),
                            VacationPendingDays: (element.VacationPendingDays ?? 0)
                        });
                    }
                });
                dataResult.push({ 
                    id: '<span class="container d-flex align-items-center justify-content-center">' + item.vacationRequestId + '</>',
                    employeeFullName: '<span class="capitalized">' + EmployeeItemData[0].EmployeeFullName  + '</span>',
                    creationDate: Moment(item.creationDate).format("DD/MM/YYYY hh:mm A"),
                    holidaysTaken: '<span class="container d-flex align-items-center justify-content-center">' + item.holidaysTaken + '</>',
                    fromDate:Moment(item.dateFrom).format("DD/MM/YYYY"),
                    toDate:Moment(item.dateTo).format("DD/MM/YYYY"),
                    status:'<span class="defaultText ' + (item.vacationRequestStatus!== null? item.vacationRequestStatus.cssClass : "")  + '">' + (item.vacationRequestStatus!== null? item.vacationRequestStatus.vacationRequestName : "") + '</span>',
                    itemBtn: (item.vacationRequestStatus!== null? (item.vacationRequestStatus.cssClass ==="status-requested"?  "<a href='#' id='btViewInfo'  data-item="+ btoa(JSON.stringify(EmployeeItemData)) +" class='fa fa-pencil-square-o custom-color size-effect-x2' title='Editar' ></a>" : "") :"")  
                });                   
            });
            
            
            $("#TblVacationAdmin").DataTable({
                        destroy: true,
                        searching: true,
                        language: LangSpanish,
                        lengthMenu: [8, 20, 40, 60, 80, 90, 100, 200],
                        "order": [[0, "desc"]],
                        data:  (dataResult.length === 0? [{id: '',employeeFullName:'',creationDate:'',holidaysTaken:'', fromDate:'', toDate:'', status:'',itemBtn: ''}] : dataResult),
                        columns: [
                            { data: "id", title: "ID", width:'5%', className:"" },  
                            { data: "employeeFullName", title: "Nombre", width:'60%', className:"capitalized" },                          
                            { data: "creationDate", title: "Fecha\u00a0de\u00a0Solicitud", width:'20%', className:"capitalized" },
                            { data: "holidaysTaken", title: "Días\u00a0Solicitados", width:'10%', className:"capitalized" },
                            { data: "fromDate", title: "Desde", width:'20%'  },
                            { data: "toDate", title: "Hasta", width:'20%'  },
                            { data: "status", title: "Estado", width:'15%'  },
                            { data: "itemBtn", title: "", width:'2%', orderable: false},
                        ]

                    });
                $('.csHidden').attr('style', 'display:none');
          

        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
      setDataLoading(false);
 
}

  useEffect(() => {
     fillData();
 
  }, []);    
    
    return (
      <>
        <div>
            <br />
            <div className=" htmlPayroll table-responsive container">
              <div className="row">
                <div className="lowcolor col-12">
              
                  <h2 className="h2">Aprobar&nbsp;Vacaciones</h2>

                  <hr />
                </div>
              </div>        
                <div className=" table-responsive">
                    {!dataLoading ?
                        <>
                        <div className="container">    
                            <div className="">
                                {!dataLoading ?
                                    <div className="table-responsive" Style="min-height:600px">
                                        <table id="TblVacationAdmin"  className="table table-striped table-bordered" Style="width:100% !important"></table>
                                    </div>                 
                                :
                                <Loading />
                                }
                            
                            </div>            
                        </div>
                        </>
                        
                    :
                    <div className="container">
                    <Loading />  
                    </div>
                    
                    }
            </div>


            <br />
            <br />

            </div>
            <br />
            <br />
        </div>
               

     </>
    )
}
export default VacationAdministrator;
