import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import { getUser } from "../../utils/Common";
import $ from "jquery";
import { SetEmarsuite, GetEmarsuite } from "../../CssbyCompany/CommonCSS";
export default function Portals() {
  const [portals, setPortals] = useState([]);
  const GetPortals = () => {
    API.getData("Portal/get?companyId=" + getUser().companyId)
      .then((response) => {
        if (response.status === 200) {
          setPortals(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const RedirectPage = (e) => {
    let url = $(e.currentTarget).attr("dataitem");
    setTimeout(() => {
      window.location = url;
    }, 300);
  };

  useEffect(() => {
    if (GetEmarsuite().enlace == undefined) {
      SetEmarsuite();
    }
    GetPortals();
  }, []);

  return (
    <div>
      <section className="container">
        <div className="container">
          <br />
          <br />
          <div className="highlights">
            {portals.map((item) => (
              <section key={item.id}>
                <a
                  href="#"
                  dataitem={
                    item.webSite != "login"
                      ? item.webSite
                      : GetEmarsuite().enlace
                  }
                  onClick={RedirectPage}
                  className="icon most-visited-log"
                  data-prefix={item.title
                    .trim()
                    .replace(" ", "")
                    .replace(" ", "")}
                >
                  <div className="content2">
                    <header>
                      <img
                        src={"/images/portals/" + item.logo}
                        className="size-effect img-fluid"
                        alt=""
                      />
                      <br />
                      <br />
                      <h6>{item.title}</h6>
                    </header>
                  </div>
                </a>
              </section>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
