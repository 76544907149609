import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import { getUser, removeUserSession } from "../../utils/Common";
import {
  ShowPopUp,
  ShowConfirmationMessage,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";

export default function MyBenefits() {
  const [benefits, setbenefits] = useState([]);

  useEffect(() => {
    API.getData("BenefitsCategory/get?companyId=" + getUser().companyId)
      .then((response) => {
        if (response.status === 200) {
          setbenefits(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <section className="container">
        <div className="container">
          <br />
          <br />
          <header className="special">
            <h4 Style={"text-align:center"}>Pensando en tí</h4>
          </header>
          <div className="highlights">
            {benefits.map((item) => (
              <section key={item.id}>
                <div className="content2">
                  <header>
                    <a
                      href={"/benefitsImg?id=" + item.id}
                      className="icon most-visited-log"
                      data-prefix={item.description.trim().replace(" ", "")}
                    >
                      <img
                        src={item.logo}
                        style={{ width: "70px" }}
                        className="size-effect"
                      />
                      <br />
                      <br />
                      <h6>{item.description}</h6>
                      <br />
                    </a>
                  </header>
                </div>
              </section>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
