import React, { useState } from "react";
import Moment from "moment";
import { NumbersWithComma } from "../../utils/CommonFunctions";

export default function BuildTableWithData(res) {
const DistincValues = (value, index, self)=> {
  return self.indexOf(value) === index;
}

let netAmount = 0.00;
let itemDatails = [];
let DetailsResult = [];
let payrollGroupInfo = [];
let payrollGroupDetails = ['Ingresos Brutos','Descuentos De Ley'];
let payrollOrder = ['Ing. Netos (después de impuestos)','Descuentos Voluntarios','Ing. después de Descuentos de ley','Descuentos De Ley','Ingresos Brutos'];
let BuildGraficBuilder = [];
let htmlGraficBuilder = [];
let graphicData = [];
sessionStorage.removeItem('GraficAnnual');


res.data.forEach(item => {
  itemDatails.push(item.transactionDescription.trim());
  payrollGroupInfo.push(item.payrollGroup.trim()); 
  BuildGraficBuilder.push({name:item.payrollGroup.trim(), data:(item.payrollGroup.trim().includes('Descuentos') ? parseFloat('-' + Math.abs(item.amount)) : Math.abs(item.amount)) });
});

let payrollGroupData = payrollGroupInfo.sort().filter(DistincValues);
payrollGroupData.forEach(element => {
  if(!payrollGroupDetails.includes(element)){
    payrollGroupDetails.push(element)
  }
});

payrollGroupDetails.forEach(payrollGroup => { 
  let totalAmount = 0.00;
  BuildGraficBuilder.forEach(item => {
    if(item.name === payrollGroup){
      totalAmount += item.data;
    }
  });
  htmlGraficBuilder.push({name:payrollGroup, data:[totalAmount.toFixed(2)]});
  totalAmount =0.00;

});
htmlGraficBuilder.find(c=>c.name ==="Ingresos Brutos")
if(htmlGraficBuilder.find(c=>c.name ==="Ingresos Brutos") !== undefined && htmlGraficBuilder.find(c=>c.name =="Descuentos De Ley") !== undefined){
  let grossAmount = parseFloat(htmlGraficBuilder.find(c=>c.name ==="Ingresos Brutos").data);
  let lawDescount = parseFloat(htmlGraficBuilder.find(c=>c.name ==="Descuentos De Ley").data);
  let netoAmount = 0.00;
  htmlGraficBuilder.forEach(item => {

    if(item.name !=="Ingresos Brutos" && item.name.includes('Descuentos')){
      netoAmount += parseFloat(item.data);
    }
    
  });

  htmlGraficBuilder.push({name:'Ing. Netos (después de impuestos)', data: [grossAmount +netoAmount] });
  htmlGraficBuilder.push({name:'Ing. después de Descuentos de ley', data: [grossAmount +lawDescount] });
}

graphicData = [];
let maxVal = (htmlGraficBuilder.find(c=>c.name ==="Ingresos Brutos") !== undefined? parseInt(htmlGraficBuilder.find(c=>c.name ==="Ingresos Brutos").data) : 1000);
payrollOrder.forEach(element => {
  htmlGraficBuilder.forEach(item => {
    if(item.name === element){
      graphicData.push({name:item.name, data: item.data, maxValue:maxVal});
    }
  });
});
htmlGraficBuilder = graphicData;
sessionStorage.setItem('GraficAnnual', JSON.stringify(htmlGraficBuilder));

let itemsData = itemDatails.sort().filter(DistincValues);
itemsData.forEach(element => {
  let amountTotal= 0.00;
    for (let indx = 0; indx < res.data.length; indx++) {
      const item = res.data[indx];
      if(item.transactionDescription.trim() == element){
        if(!isNaN(item.amount)){
            amountTotal += item.amount;
            netAmount += item.amount;
        }
      }
    }
    DetailsResult.push({Details: element, Amount: amountTotal});

});
 
    let currentHTML = [];
    let htmlArr = [];
    let htmlBuilder = [];

    htmlBuilder.push(
      "<div class='table-responsive'>" +
        "<table class='table'><thead><tr>" +
        "<th scope='col'>" +
        "<span class='defColorHeader'>DESCRIPCION</span>" +
        "</th>" +
        "<th scope='col' class='defColorHeader colRight'>" +
        "<span>CANTIDAD</span>" +
        "</th>" +
        "</tr></thead>" +
        "<tbody class='tbody'></tbody>"
    );
    
    payrollGroupDetails.forEach(itemGroup => {
      let countAmount = 0;
      htmlBuilder.push("<tr><th colspan='2' class='table-group-compensation title-upper'><strong>"+ itemGroup.toUpperCase() +"</strong></th></tr>");
      for (let indx = 0; indx < res.data.length; indx++) {
          const itemData = res.data[indx];
          DetailsResult.forEach(item => {
            if(item.Details == itemData.transactionDescription.trim() && itemData.payrollGroup.trim() == itemGroup.trim()){
              if(!htmlArr.includes(item.Details + "|" + itemGroup.trim())){
                  htmlArr.push(item.Details + "|" + itemGroup.trim());
                  htmlBuilder.push(
                    "<tr class='defSize'><td>"+ item.Details +"</td>" +
                      "<td class='colRight'>" +
                      NumbersWithComma(Math.abs(item.Amount)) +
                      "</td></tr>" 
                  );
                  countAmount += Math.abs(item.Amount);
              }
 
            }
          }); 
      }

      htmlBuilder.push(
        "<tr class='defSize'><td>&nbsp;</td>" +
          "<td class='colRight table-group-compensation-total'>" +
          NumbersWithComma(Math.abs(countAmount)) +
          "</td></tr>" 
      );

    });
        
        currentHTML.push({
          id: currentHTML.length + 1,
          itemData:
            htmlBuilder.join("") +
            "<tfoot><tr><th scope='row'><span class='defColorHeader'>INGRESO&nbsp;NETO" +
            "</span></th><th scope='row' class='defColorHeader colRight " +
            (netAmount.toString().indexOf("-") == 0 ? "red" : "") +
            "'><span>" +
            NumbersWithComma(netAmount) +
            "</th><th></th></tr>" +
            "</tfoot></table>",
        });

  return currentHTML;
}