import Button from "../../components/Button/Button";
import React, { useState, useEffect } from "react";
import $, { param } from "jquery";
import "moment/locale/es";
import API from "../../utils/api";
import Moment from "moment";
import Loading from "../../components/loading/loading";
import { LangSpanish } from "../../tools/dataTables.Language";
import { getUser, removeUserSession } from "../../utils/Common";
import {
  ShowConfirmationMessage,
  MessageResults,
  ShowPopUp,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import { deletePermission } from "../action/permissionAction";

$(document).ready(() => {
  $("body").on("click", "#TblEmployeePermission #btRec", function (e) {
    let param = JSON.parse(
      atob($(e.currentTarget).parent().attr("data-item"))
    )[0];

    ShowPopUp({
      handlerEvent: SaveDisableChanges,
      htmlBody: GetCat(
        param.employeeNumber,
        param.typePermission,
        param.name,
        param.dateStart,
        param.dateEnd,
        param.comment
      ),
      isDisabled: true,
    });
  });

  const GetCat = (number, type, name, dateStart, dateEnd, comment) => {
    return `<html><body><section>
    <div class="container">
    <p style="margin-bottom: 0.25in; line-height: 100%; text-align-center: left;"><font face="Times New Roman, serif"><font size="3">PERMISO</font></font></p>
    <p   style="margin-bottom: 0in; line-height: 100%"> ${number} ${name}  <br /></p>  
    </br>
    <p style="margin-bottom: 0.25in; line-height: 100%; text-align: left;"><font face="Times New Roman, serif">Tipo Permiso: ${type} <font size="3"> </font></font></p>  
        <p style="margin-bottom: 0.25in; line-height: 100%; text-align: left;"><font face="Times New Roman, serif">Fecha Inicio: ${Moment(
      dateStart
    ).format("DD/MM/YYYY")}<font size="3"> </font></font></p>
    <p style="margin-bottom: 0.25in; line-height: 100%; text-align: left;"><font face="Times New Roman, serif">Fecha Fin: ${Moment(
      dateEnd
    ).format("DD/MM/YYYY")}<font size="3"> </font></font></p>  
        <p style="margin-bottom: 0.25in; line-height: 100%; text-align: left;"><font face="Times New Roman, serif">COMENTARIO: ${comment.toUpperCase()}</font><br /></p>
    </div>	
</section></body></html>`;
  };

  $("body").on("click", "#TblEmployeePermission #btDel", function (e) {
    let param = JSON.parse(
      atob($(e.currentTarget).parent().attr("data-item"))
    )[0];

    console.log(param.statusMedicalId);
    if (param.statusMedicalId === 2) {
      ShowAlertMessage(
        "INFORMACIÓN",
        "PERMISO EN PROCESO NO SE PUEDE ELIMINAR",
        "info"
      );
    } else if (param.statusMedicalId === 3) {
      ShowAlertMessage(
        "INFORMACIÓN",
        "PERMISO APROBADO NO SE PUEDE ELIMINAR",
        "info"
      );
    } else if (param.statusMedicalId === 4) {
      ShowAlertMessage(
        "INFORMACIÓN",
        "PERMISO DECLINADO NO SE PUEDE ELIMINAR",
        "info"
      );
    } else {
      ShowConfirmationMessage(
        deleteEmployeePermission,
        "INFORMACIÓN",
        param,
        "DESEA ELIMINAR ESTE REGISTRO "
      );
    }
  });

  const deleteEmployeePermission = (params) =>{
    deletePermission(params.id);
  }

  const SaveDisableChanges = (params) => {};
});
export default function EmployeePermission(props) {
  const [dataLoading, setDataLoading] = useState(true);
  const [permission, setPermission] = useState(true);

  const fillData = () => {
    API.getData(
      `EmployeePermision/getEmployeeNumber?employeeNumber=${getUser().employeeIdCard}`
    )
      .then((res) => {
        setDataLoading(false);
        if (res.status === 200) {
          let dataResult = [];
          setPermission(res.data);
          let DeleteBtn =
            "<a href='#' id='btDel'  class='fa fa fa-trash custom-color size-effect-x2 red' title='Eliminar permiso' > </a>&nbsp;&nbsp;&nbsp;";
          let RecBtn =
            "<a href='#' id='btRec'  class='fa fa-eye custom-color size-effect-x2 ' title='Visualizar permiso' ></a>&nbsp;";

          res.data.forEach((item) => {
            dataResult.push({
              id:
                '<span class="container d-flex align-items-center justify-content-center">' +
                item.id +
                "</>",
              name:
                '<span class="capitalized defaultText">' +
                item.name +
                "</span>",
              employeeNumber:
                '<span class="capitalized defaultText">' +
                item.employeeNumber +
                "</span>",
              typePermission:
                '<span class="capitalized defaultText">' +
                item.typePermission +
                "</span>",
              description:
                '<span class="capitalized defaultText">' +
                item.comment +
                "</span>",
              dateStart:
                '<span class="capitalized defaultText">' +
                Moment(item.dateStart).format("DD/MM/YYYY  ") +
                "</span>",
              dateEnd:
                '<span class="capitalized defaultText">' +
                Moment(item.dateEnd).format("DD/MM/YYYY  ") +
                "</span>",
              statusCard:
                '<span class="capitalized defaultText ' +
                item.statusCssClass +
                '">' +
                item.statusCard +
                "</span>",
              itemBtn:
                "<span data-created='" +
                item.id +
                "'  data-item='" +
                btoa(JSON.stringify([item])) +
                "'>" +
                DeleteBtn +
                RecBtn +
                "</span>",
            });
          });

          $("#TblEmployeePermission").DataTable({
            destroy: true,
            searching: true,
            language: LangSpanish,
            bLengthChange: false,
            lengthMenu: [10, 20, 40, 60, 80, 90, 100, 200],
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: ["copy", "excel", "pdf"],
            data:
              dataResult.length === 0
                ? [
                  {
                    name: "",
                    description: "",
                    dateStart: "",
                    dateEnd: "",
                    typePermission:"",
                    statusCard: "",
                    itemBtn: ""
                  },
                ]
                : dataResult,
            columns: [
              {
                data: "name",
                title: "Nombre",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "dateStart",
                title: "Desde",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "dateEnd",
                title: "Hasta",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "typePermission",
                title: "Tipo de Permiso",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "description",
                title: "Comentario",
                width: "20%",
                className: "capitalized",
              },
              {
                 data: "statusCard",
                 title: "Estado",
                 width: "20%",
                 className: "capitalized",
               },
              {
                data: "itemBtn",
                title: "\u00a0Acciones\u00a0\u00a0\u00a0",
                width: "5%",
                orderable: false,
              },
            ],
          });
          $(".csHidden").attr("style", "display:none");
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
    setDataLoading(false);
  };

  useEffect(() => { 
    fillData();
  }, []);

  const onButtonClick = () => {
    props.history.push("/addEmployeePermission");
  };

  return (
    <div className=" htmlMyrequests table-responsive container">
      <section>
        <br />
        <div className="row">
          <div className="col-12">
            <br />
            <h2 className="h2" Style={"text-align:center"}>
              &nbsp;PERMISOS
            </h2>
            <hr />
          </div>
        </div>
      </section>
      <div className="row">
       <div className="col-lg-10 col-md-10 col-sm-8"></div>
        <div className="col-lg-2 col-md-2 col-sm-4">
          <div className="mb-2">
            <Button
              type="btnRequest btn-outline-danger text-wrap most-visited-log"
              data_prefix="myrequest/solicitarcarta"
              text="Enviar Permiso"
              clickHandler={onButtonClick}
            />
            <br />
          </div>
        </div>
        {!dataLoading ? (
          <>
            <div className="container">
              <div className="">
                {!dataLoading ? (
                  <div
                    className="scroll-table bordered"
                    Style="min-height:600px"
                  >
                    <table
                      id="TblEmployeePermission"
                      className="table table-striped table-bordered display"
                      Style="width:100% !important"
                    ></table>
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="container">
            <Loading />
          </div>
        )}

        <br />
        <br />
      </div>
    </div>
  );
}
