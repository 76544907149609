import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import Button from "../../components/Button/Button";
import { getUser } from "../../utils/Common";
import Swal from "sweetalert2";
import $ from "jquery";
import {
  ShowPopUp,
  MessageResults,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import storage from "../../firebase/firebase.js";

export default function PollCovid(props) {
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
      setCovid(true);
    };
    return {
      value,
      onChange: handleChange,
    };
  };
  const [descriptionW, setDescription] = useState("");
  const [noVacunado, setNoVacunado] = useState(false);
  const [noMeVacunare, setNoMeVacunare] = useState(false);
  const [primeraDosis, setPrimeraDosis] = useState(false);
  const [segundaDosis, setSegundaDosis] = useState(false);
  const [cuartaDosis, setCuartaDosis] = useState(false);
  const [terceraDosis, setTerceraDosis] = useState(false);
  const [covid, setCovid] = useState(false);
  const [covids, setCovids] = useState(false);
  const description = useFormInput("");

  const [primeraDosisValor, setPrimeraDosisValor] = useState(false);
  const [segundaDosisValor, setSegundaDosisValor] = useState(false);
  const [terceraDosisValor, setTerceraDosisValor] = useState(false);
  const [image, setImage] = useState("");
  const [loadImage, setLoadImage] = useState(false);

  const [faq, setFaq] = useState([]);
  useEffect(() => {
    API.getData(
      "/PollVaccinesService/getbyemployeeidcard?employeeIdCard=" +
        getUser().employeeIdCard
    )
      .then((response) => {
        if (response.status === 200) {
          response.data.forEach((item) => {
            setDescription(item.vecesCovid);
            if (item.primeraDosis == true) {
              setPrimeraDosis(true);
            }
            if (item.segundaDosis == true) {
              setSegundaDosis(true);
            }
            if (item.terceraDosis == true) {
              setTerceraDosis(true);
            }
            if (item.cuartaDosis == true) {
              setCuartaDosis(true);
            }
            if (item.covid == true) {
              setCovid(true);
              setLoadImage(true);
              $('#tentacles').val(item.vecesCovid)
              setDescription(item.vecesCovid);
            } else {
              setCovids(true);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [state, setState] = useState({
    description: descriptionW,
  });
  function NoVacunado(event) {
    setPrimeraDosis(false);
    setSegundaDosis(false);
    setTerceraDosis(false);
    setCuartaDosis(false);
    setNoVacunado(event);
  }

  function NoMeVacunare(event) {
    setPrimeraDosis(false);
    setSegundaDosis(false);
    setTerceraDosis(false);
    setCuartaDosis(false);
    setNoMeVacunare(event);
  }

  function PrimeraDosis(event) {
    setPrimeraDosis(event);
    setSegundaDosis(false);
    setTerceraDosis(false);
    setCuartaDosis(false);
    if (event === true) {
      setNoVacunado(false);
      setNoMeVacunare(false);
    }
  }
  function SegundaDosis(event) {
    setPrimeraDosis(true);
    setSegundaDosis(event);
    setTerceraDosis(false);
    setCuartaDosis(false);
    if (event === true) {
      setNoVacunado(false);
      setNoMeVacunare(false);
    }
  }

  function TerceraDosis(event) {
    setPrimeraDosis(true);
    setSegundaDosis(true);
    setTerceraDosis(event);
    setCuartaDosis(false);
    if (event === true) {
      setNoVacunado(false);
      setNoMeVacunare(false);
    }
  }

  function CuartaDosis(event) {
    setPrimeraDosis(true);
    setSegundaDosis(true);
    setTerceraDosis(true);
    setCuartaDosis(event);
    if (event === true) {
      setNoVacunado(false);
      setNoMeVacunare(false);
    }
  }

  function Covid1(event) {
    setCovid(event);
    setCovids(false);
    if (event === true) {
      setLoadImage(true);
      setNoVacunado(false);
      setNoMeVacunare(false);
    }
  }
  function Covid2(event) {
    setCovids(event);
    setCovid(false);
    if (event === true) {
      setLoadImage(false);
      setNoVacunado(false);
      setNoMeVacunare(false);
    }
  }

  function onInputchange(event) {
    setState({
      [event.target.name]: event.target.value
    });
  }

  const saveFile = (e) => {
    Swal.fire({
      title: "Cargando Imagén",
      showConfirmButton: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      allowOutsideClick: false,
      dangerMode: true,
    });
    const ref = storage.ref(`/images/${e.name}`);
    const uploadTask = ref.put(e);
    uploadTask.on("state_changed", console.log, console.error, () => {
      ref.getDownloadURL().then((url) => {
        setImage(url);
      
        console.log(url);
        Swal.close();
      });
    });
  };

  const save = () => { 
  debugger;
    if (covid == true) {
      if ($('#tentacles').val() != "") {
        API.postData("PollVaccinesService/Add", {
          EmployeeIdCard: getUser().employeeIdCard,
          CompanyId: getUser().companyId,
          Vacunado: noVacunado,
          NoVacunado: noMeVacunare,
          PrimeraDosis: primeraDosis,
          SegundaDosis: segundaDosis,
          imagen: "ldldldldlldldld",
          TerceraDosis: terceraDosis,
          Covid: covid,
          CuartaDosis: cuartaDosis,
          vecesCovid: $('#tentacles').val(),
        })
          .then((response) => {
            MessageResults(response.status);
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          })
          .catch((error) => {
            ShowAlertMessage(
              "¡Mensaje de aviso!",
              " <p>Intente de nuevo, algo salio mal</p>"
            );
            console.log(error);
          });
      } else {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Debe mencionar cuantas veces a tenido covid</p>"
        );
      }
    }else{
      API.postData("PollVaccinesService/Add", {
        EmployeeIdCard: getUser().employeeIdCard,
        CompanyId: getUser().companyId,
        Vacunado: noVacunado,
        NoVacunado: noMeVacunare,
        PrimeraDosis: primeraDosis,
        SegundaDosis: segundaDosis,
        imagen: "ldldldldlldldld",
        TerceraDosis: terceraDosis,
        Covid: covid,
        CuartaDosis: cuartaDosis,
        vecesCovid: $('#tentacles').val(),
      })
        .then((response) => {
          MessageResults(response.status);
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        })
        .catch((error) => {
          ShowAlertMessage(
            "¡Mensaje de aviso!",
            " <p>Intente de nuevo, algo salio mal</p>"
          );
          console.log(error);
        });
    }
  };
  const addPoll = () => {
    save();
  };

  useEffect(() => {}, []);
  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <h4 className="text-center">Formulario COVID-19</h4>
        <hr />
        <section>
          <div id="myform" className="form_container">
            <div className="form_group">
              <label className="classspan text-left">Nombre Completo</label>

              <input
                type="text"
                className="txtbox"
                value={getUser().firstName + " " + getUser().lastName}
                readonly="true"
              />
            </div>
            <div class="col-6 col-12-small">
              <input
                type="checkbox"
                id="novacunado"
                name="novacunado"
                defaultChecked={noVacunado}
                checked={noVacunado}
                onChange={(e) => NoVacunado(!noVacunado)}
              />
              <label for="novacunado" className="classspan text-left">
                No me he vacunado
              </label>
            </div>
            <div class="col-6 col-12-small">
              <input
                type="checkbox"
                id="nomevacunare"
                name="nomevacunare"
                defaultChecked={noMeVacunare}
                checked={noMeVacunare}
                onChange={(e) => NoMeVacunare(!noMeVacunare)}
              />
              <label for="nomevacunare" className="classspan text-left">
                No me vacunaré
              </label>
            </div>
            <div class="col-6 col-12-small">
              <input
                type="checkbox"
                id="primeradosis"
                name="dosis"
                value={primeraDosis}
                onChange={(e) => PrimeraDosis(true)}
                checked={primeraDosis}
              />
              <label for="primeradosis" className="classspan text-left">
                Me vacuné con primera dosis
              </label>
            </div>
            <div class="col-6 col-12-small">
              <input
                type="checkbox"
                id="segundadosis"
                name="dosis"
                onChange={(e) => SegundaDosis(true)}
                checked={segundaDosis}
              />
              <label for="segundadosis" className="classspan text-left">
                Me vacuné con segunda dosis
              </label>
            </div>
            <div class="col-6 col-12-small">
              <input
                type="checkbox"
                id="terceraDosis"
                name="dosis"
                value={terceraDosis}
                onChange={(e) => TerceraDosis(true)}
                checked={terceraDosis}
              />
              <label for="terceraDosis" className="classspan text-left">
                Me vacuné con tercera dosis
              </label>
            </div>
            <div class="col-6 col-12-small">
              <input
                type="checkbox"
                id="cuartadosis"
                name="dosis"
                value={cuartaDosis}
                onChange={(e) => CuartaDosis(true)}
                checked={cuartaDosis}
              />
              <label for="cuartadosis" className="classspan text-left">
                Me vacuné con cuarta dosis
              </label>
            </div>

            <div className="form_group">
              <label className="classspan text-left">Te ha dado Covid?</label>
            </div>
            <div class="col-6 col-12-small">
              <input
                type="checkbox"
                id="covid"
                name="dosis"
                value={covid}
                onChange={(e) => Covid1(true)}
                checked={covid}
              />
              <label for="covid" className="classspan text-left">
                SI
              </label>
            </div>
            <div class="col-6 col-12-small">
              <input
                type="checkbox"
                id="covid1"
                name="dosis"
                value={covids}
                onChange={(e) => Covid2(true)}
                checked={covids}
              />
              <label for="covid1" className="classspan text-left">
                NO
              </label>
            </div>
            {loadImage ? (
              <>
                <div class="form_group">
                  <label class="control-label">
                    Escriba cuantas veces ha tenido Covid &nbsp;
                  </label>
                  <input
                    type="number"
                    id="tentacles"
                    name="tentacles" 
                    value={state.tentacles}
                    onChange={onInputchange}
                  />
                  <br />
                  <br />
                </div>
              </>
            ) : (
              <></>
            )}

            {/*loadImage ? (
              <>
                <div class="form-group col-md-6">
                  <label class="control-label">
                    Prueba de vacunación (Foto)
                  </label>
                  &nbsp;&nbsp;
                  <input
                    type="file"
                    id="logoTitulo"
                    accept="image/*"
                    onChange={(e) => saveFile(e.target.files[0])}

                    multiple
                  /> 
                  <br />
                  <img src={image} id="output" class="img-fluid" alt="Responsive image"/>
                </div>
              </>
            ) : (
              <></>
            )*/}
            <div className="form_group">
              <Button
                type="btn-outline-danger"
                text="ENVIAR"
                clickHandler={addPoll}
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
