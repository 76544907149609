import React, { useState, useEffect } from "react";
import { getUser } from "../../utils/Common"; 
import API from "../../utils/api";

const InternalNotifications = (props)=> { 
    const [notificationsCounter, setNotificationsCounter] = useState(0);
    const [iCss, setICss] = useState('fa fa-envelope envelopeDefault');
    const [spanCss, setSpanCss] = useState('badge badge-warning counterDefault');

    const GetNotifications = ()=>{
        API.getData("InternalNotifications/GetbyEmployeeNumber?employeeNumber=" + getUser().employeeNumber + "&companyId=" + getUser().companyId)
        .then((res) => {
          if (res.status === 200) { 
              if(res.data.length >0){
                let counter =0;
                res.data.forEach(element => {
                  if(element.isActive){
                    counter += 1;
                  }
                });
                if(counter >0){ 
                  setICss('fa fa-envelope faa-tada animated  faa-flash'); 
                  setSpanCss('badge badge-warning faa-tada animated  faa-flash');              
                }else{
                  setICss('fa fa-envelope envelopeDefault'); 
                  setSpanCss('badge badge-warning counterDefault');                 
                }
                setNotificationsCounter(counter);
              }
           
            
          }
        })
        .catch(function (err) {
          console.error("Error de conexion " + err);
        });
  } 
  useEffect(() => {
    GetNotifications();
    setInterval(()=>GetNotifications(), 30*1000);
    
   }, []);
   
   return (
   <>
        <a href="/internalNotifications"><span id='spBoxMessage' className="size-effect white mail-size">
            <i className={iCss}>&nbsp;
              <span id="spBoxCounter"  title="Mis Notificaciones"  className={spanCss}>{notificationsCounter} </span>
            </i>
        </span></a>
       </>
    );
   }
export default InternalNotifications;
   