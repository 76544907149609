import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import Button from "../../components/Button/Button";
import { getUser } from "../../utils/Common";
import DropdownList from "../../components/dropdown/dropdownList";
import {
  ShowPopUp,
  ShowConfirmationMessage,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";

export default function MySuggestion(props) {
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };
  const department = useFormInput("");
  const messenge = useFormInput("");
  const [dropCardType, setDropCardType] = useState([]);
  const detailSuggestion = useFormInput("");
  const [uni, setUnit] = useState("");

  const GetCardType = () => {
    API.getData("ServiceUnit/get")
      .then((res) => {
        if (res.status === 200) {
          let dropData = [];
          res.data.forEach((item) => {
            dropData.push({
              label: item.title,
              value: item.id,
            });
          });
          let Drop = (
            <DropdownList
              idControl="dropLetterType"
              headerLabel="Unidad de servicio"
              data={dropData}
              handleChange={change}
            />
          );
          setDropCardType(Drop);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };
  const change = (data) => {
    setUnit(data.label);
  };

  const sendMail = () => {
    API.postData("EmailSender/SendSuggestionEmail", {
      Email: GetCssByCompany().emailCaseReport,
      Name: getUser().firstName + " " + getUser().lastName,
      Departament: department.value,
      Unit: uni,
      Message: detailSuggestion.value.toLowerCase(),
    })
      .then((response) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Sugerencia enviada correctamente</p>"
        );
        props.history.push("/suggestion");
      })
      .catch((error) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Intente de nuevo, algo salio mal al enviar su sugerencia</p>"
        );
        console.log(error);
      });
  };
  useEffect(() => {
    GetCardType();
  }, []);
  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <h4 className="text-center">
          Tus ideas y sugerencias nos ayudan a ser mejores.
        </h4>
        <hr />
        <div className="jumbotron">
          <b>
            <p className="text-center">
              En Distruibuidora Corripio nos encanta saber de que manera podemos
              mejorar tu espacio de trabajo.
              <br />
              Comparte tus ideas en el siguiente formulario.
            </p>
          </b>
        </div>

        <section>
          <div id="myform" className="form_container">
            <div className="form_group">
              <label className="classspan text-left">Nombre Completo</label>

              <input
                type="text"
                className="txtbox"
                value={getUser().firstName + " " + getUser().lastName}
                readonly="true"
              />
            </div>
            <div className="form_group">
              <label className="classspan text-left">Departamento</label>
              <input type="text" {...department} className="txtbox" />
            </div>

            <div className="form_group row mb-2">{dropCardType}</div>
            <div className="form_group">
              <label className="classspan text-left">Detalle Sugerencia</label>
              <textarea
                type="text"
                rows="4"
                {...detailSuggestion}
                cols="50"
              ></textarea>
              <br />
              <Button
                type="btn-outline-danger"
                text="ENVIAR SUGERENCIA"
                clickHandler={sendMail}
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
