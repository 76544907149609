import React, { useState, useEffect } from 'react';
import API from '../../utils/api';

export default function BusinessUnit() {

    const [businessUnit, setBusinessUnit] = useState([]);

    useEffect(() => {
        API.getData('BusinessUnittype/get').then(response => {
            setBusinessUnit(response.data);
        }).catch(error => {
            console.log(error);
        });
    },[]);
    return (
        <div> 
            <section className="wrapper">
                <div className="inner">
                <br/>
                    <header className="special">
                        <h3>Nuestros Colaboradores dan el todo por el todo.</h3>
                    </header>
                    <div className="table-wrapper">
 
                        <table>
                            {
                                businessUnit.map(item => (
                                    <tr> 
                                        <td><a key={item} href={"/unidad?id=" + item.id + "&name="+ item.description} className="button fit" >{item.description}</a></td> 
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                </div>
            </section>
        </div>
    )
}