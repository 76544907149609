import React from "react";
import { getUser } from "../../utils/Common";
import Moment from "moment";

export default function MyData() {
  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <h2 Style={"text-align:center"}>Mis Datos</h2>
        <br />
        <section>
          <div className="content2">
            <header>
              <h6>
                <b>Nombre</b>
              </h6>
              <p>{getUser().firstName + " " + getUser().lastName}</p>
              <hr />
              <h6>
                <b>C&oacute;digo</b>
              </h6>
              <p>{getUser().employeeNumber}</p>
              <hr />
              <h6>
                <b>Empresa</b>
              </h6>
              <p>{getUser().companyName}</p>
              <hr />
              <h6>
                <b>Cargo</b>
              </h6>
              <p>{getUser().positionName}</p>
              <hr />
              <h6>
                <b>Nivel</b>
              </h6>
              <p>{getUser().level}</p>
              <hr />
              <h6>
                <b>Grupo Ocupacional</b>
              </h6>
              <p>{getUser().occupationalGroup}</p>
              <hr />
              <h6>
                <b>Fecha contrataci&oacute;n</b>
              </h6>
              <p>{Moment(getUser().hiringDate).format("DD-MM-YYYY")} </p>
              <hr />
              <h6>
                <b>Supervisor</b>
              </h6>
              <p>{getUser().supervisorName}</p>
            </header>
            <br /><br /><br />
            <div class="row">
              <div class="col-md-12">
                <center>
                  <div class="form-group col-md-6 ">
                    <a
                    href="/vaccinationData"
                      type="button"
                      className="mybt btn btn-outline-danger text-wrap"
                    >
                      Datos de vacunación
                    </a>
                  </div>
                 
                  <div class="form-group col-md-6 ">
                    <a
                    href="/PollCovid"
                      type="button"
                      className="mybt btn btn-outline-danger text-wrap"
                    >
                    Actualizar Datos de vacunación
                    </a>
                  </div>
                </center>
              </div>{" "}
            </div>{" "}
          </div>
        </section>
      </div>
    </section>
  );
}
