import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import { getUser } from "../../utils/Common";
import $ from "jquery";

import { GetImagePatch } from "../../utils/CommonFunctions";
import { useBarcode } from "react-barcodes";
import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";

export default function Promociones() {
  return (
    <div>
      <section className="container">
        <div className="container">
          <br />
          <br />
          <h6 className="text-center">PROMOCIONES DÍA DEL PADRE</h6>
          <div className="highlights">
            <section>
              <a href="/addpromotionPapaJohns">
                <div className="content2">
                  <header>
                    <img
                      src={GetImagePatch("/images/papajohns.jpg")}
                      className="size-effect img-fluid"
                      alt=""
                    />
                    <br />
                    <br />
                  </header>
                </div>
              </a>
            </section>
            <section>
              <div className="content2">
                <header>
                  <img
                    src={GetImagePatch("/images/BurgerKing.jpg")}
                    className="size-effect img-fluid"
                    alt=""
                  />
                  <br />
                  <br />
                </header>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}
