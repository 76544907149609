import React, { useState, useEffect } from "react";
import { getUser } from "../../utils/Common"; 

const Congratulations = (props)=> { 
  
const [isOnbithsday, setIsOnbithsday] = useState(false);
const [tittleText, setTittleText] = useState('Felíz Cumpleaños!');
const GetCongratulations = ()=>{

let currentDate = new Date().toISOString().slice(5,10);
let hiringDate = (getUser().hiringDate === undefined || getUser().hiringDate === null? "00-00" : new Date(getUser().hiringDate).toISOString().slice(5,10));
let Birthdate = (getUser().birthdate === undefined || getUser().birthdate === null? "00-00" : new Date(getUser().birthdate).toISOString().slice(5,10));
let defaultTimer = (3 * 1000);// 3seconds
if(currentDate === hiringDate){
    setTimeout(() => {
        setTittleText('Gracias haber cumplido un año mas con nosotros!');
        setIsOnbithsday(true); 
    }, defaultTimer);
}else if(currentDate === Birthdate){
    setTimeout(() => {
        setIsOnbithsday(true); 
    }, defaultTimer);   
}else{
    setIsOnbithsday(false); 
}

        
       
} 
  useEffect(() => {
    GetCongratulations();
    
   }, []);
   
   return (
      <>
      {isOnbithsday?
        <>
        <div className="container">
        <br />
        <br />
            <div id="divCongrats" className="cssCongrats d-flex align-items-center justify-content-center">
                <span className="CongratsH1" title={tittleText}>
                    <img src="/images/HappyBirthsDay/happybirthday4.gif" className="congratsImg fa faa-pulse animated"   alt="Congratulations" />
                        <span className="fa faa-tada animated faa-slow">Felicidades!</span> 
                    <img src="/images/HappyBirthsDay/happybirthday3.gif" className="congratsImg fa faa-pulse animated"   alt="Congratulations2" />
                </span>
            </div>         
        </div>
        </> :
        <></>
      }

       </>
    );
   }
export default Congratulations;
   
