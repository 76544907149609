import Button from "../../components/Button/Button";
import React, { useState, useEffect } from "react";
import $ from "jquery";
import "moment/locale/es";
import API from "../../utils/api";
import Moment from "moment";
import Loading from "../../components/loading/loading";
import { LangSpanish } from "../../tools/dataTables.Language";
import { getUser, removeUserSession } from "../../utils/Common";
import {
  ShowConfirmationMessage,
  MessageResults,
  ShowPopUp,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import { deleteLicense } from "../action/medicalLicenseAction";
$(document).ready(() => {
  $("body").on("click", "#TblMedicalLicenses #btRec", function (e) {
    let param = JSON.parse(
      atob($(e.currentTarget).parent().attr("data-item"))
    )[0];

    ShowPopUp({
      handlerEvent: SaveDisableChanges,
      htmlBody: GetCat(
        param.employeeNumber,
        param.img,
        param.employee,
        param.creationDate,
        param.description,
        param.imgForm
      ),
      isDisabled: true,
    });
  });

  const GetCat = (number, img, name, fecha, description,imgForm) => {
    return `<html><body><section>
    <div class="container">
    <p style="margin-bottom: 0.25in; line-height: 100%; text-align-center: left;"><font face="Times New Roman, serif"><font size="3"> LICENCIA MÉDICA</font></font></p>
    <p   style="margin-bottom: 0in; line-height: 100%"> ${number} ${name}  <br /></p>  
    <p style="margin-bottom: 0in; line-height: 100%; text-align: left;">
    ${
      imgForm != null ?
      `<img src="${imgForm}" class="img-fluid" style="width: 600px !important;min-width: 200px;min-height: 45px;margin-top: 20px;" name="Logo DC" align="bottom" width="200" height="45" border="0" /> <font color="#575757"><font face="Segoe UI, serif"><font size="2" style="font-size: 10pt"><br /> <br /></font></font></font><br />`
      :`<img src="${img}" class="img-fluid" style="width: 600px !important;min-width: 200px;min-height: 45px;margin-top: 20px;" name="Logo DC" align="bottom" width="200" height="45" border="0" /> <font color="#575757"><font face="Segoe UI, serif"><font size="2" style="font-size: 10pt"><br /> <br /></font></font></font><br />`
    }         
    </p>
        <p style="margin-bottom: 0.25in; line-height: 100%; text-align: left;"><font face="Times New Roman, serif">Fecha ${Moment(
      fecha
    ).format("DD/MM/YYYY")}<font size="3"> </font></font></p>
        
        <p style="margin-bottom: 0.25in; line-height: 100%; text-align: left;"><font face="Times New Roman, serif">COMENTARIO: ${description.toUpperCase()}</font><br /></p>
    </div>	
</section></body></html>`;
  };

  $("body").on("click", "#TblMedicalLicenses #btDel", function (e) {
    let param = JSON.parse(
      atob($(e.currentTarget).parent().attr("data-item"))
    )[0];

    if (param.statusMedicalId === 2) {
      ShowAlertMessage(
        "INFORMACIÓN",
        "LICENCIA EN PROCESO NO SE PUEDE ELIMINAR",
        "info"
      );
    } else if (param.statusMedicalId === 3) {
      ShowAlertMessage(
        "INFORMACIÓN",
        "LICENCIA APROBADA NO SE PUEDE ELIMINAR",
        "info"
      );
    } else if (param.statusMedicalId === 4) {
      ShowAlertMessage(
        "INFORMACIÓN",
        "LICENCIA DECLINADA NO SE PUEDE ELIMINAR",
        "info"
      );
    } else {
      ShowConfirmationMessage(
        deleteMedicalLicense,
        "INFORMACIÓN",
        param,
        "DESEA ELIMINAR ESTE REGISTRO "
      );
    }
  });

  const deleteMedicalLicense = (params) => {
    deleteLicense(params.id);
  }

  const SaveDisableChanges = (params) => { };
});
export default function MyRequests(props) {
  const [dataLoading, setDataLoading] = useState(true);
  const [medicalLicenses, setMedicalLicenses] = useState(true);

  const fillData = () => {
    API.getData(
      "MedicalLicenses/getEmployeeNumber?employeeNumber=" +
      getUser().employeeIdCard
    )
      .then((res) => {
        setDataLoading(false);
        if (res.status === 200) {
          let dataResult = [];

          setMedicalLicenses(res.data);
          let DeleteBtn =
            "<a href='#' id='btDel'  class='fa fa fa-trash custom-color size-effect-x2 red' title='Eliminar Licencia' > </a>&nbsp;&nbsp;&nbsp;";
          let RecBtn =
            "<a href='#' id='btRec'  class='fa fa-eye custom-color size-effect-x2 ' title='Visualizar Carta' ></a>&nbsp;";
          let EditBtn =
            "&nbsp;<a href='#' id='btEdit'  class='fa fa-pencil-square-o custom-color size-effect-x2' title='Enviar Foto Formulario Firmado' ></a>&nbsp;";

          res.data.forEach((item) => {
            dataResult.push({
              id:
                '<span class="container d-flex align-items-center justify-content-center">' +
                item.id +
                "</>",
              name:
                '<span class="capitalized defaultText">' +
                item.employee +
                "</span>",
              employeeNumber:
                '<span class="capitalized defaultText">' +
                item.employeeNumber +
                "</span>",
              img:
                item.imgForm != null ?
                '<img src="' + item.imgForm + '"  class="img-fluid "  alt="img" />'
                : '<img src="' + item.img + '"  class="img-fluid "  alt="img" />',
              description:
                '<span class="capitalized defaultText">' +
                item.description +
                "</span>",
              statusCard:
                '<span class="capitalized defaultText ' +
                item.statusCssClass +
                '">' +
                item.statusCard +
                "</span>",
              companyId:
                '<span class="capitalized defaultText">' +
                item.companyId +
                "</span>",

              creationDate:
                '<span class="capitalized defaultText">' +
                Moment(item.creationDate).format("DD/MM/YYYY  ") +
                "</span>",
              validation:
                `<span class="capitalized defaultText ${(item.validation === 1 ? 'status-approved'
                  : (item.validation === 2 ? 'status-requested' : 'status-pending')
                )}">` +
                (item.validation === 1 ? "Correcta" : (item.validation === 2 ? "Incorrecta" : "Sin validar")) +
                `</span>`,
              itemBtn:
                "<span data-created='" +
                item.id +
                "'  data-item='" +
                btoa(JSON.stringify([item])) +
                "'>" +
                (item.validation === 1 && item.statusMedicalId === 1
                  ?
                  '<a class="fa fa-pencil-square-o custom-color size-effect-x2"   title="Enviar Formulario Firmado" href="/editMedicalLicense?id=' +
                  item.id +
                  '"' +
                  item.id +
                  " ></a>&nbsp;&nbsp;"
                  : "") +
                RecBtn +
                DeleteBtn +
                "</span>",
            });
          });

          $("#TblMedicalLicenses").DataTable({
            destroy: true,
            searching: true,
            language: LangSpanish,
            bLengthChange: false,
            lengthMenu: [10, 20, 40, 60, 80, 90, 100, 200],
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: ["copy", "excel", "pdf"],
            data:
              dataResult.length === 0
                ? [
                  {
                    name: "",
                    img: "",
                    description: "",
                    companyId: "",
                    creationDate: "",
                    statusCard: "",
                    validation: "",
                    itemBtn: ""
                  },
                ]
                : dataResult,
            columns: [
              {
                data: "name",
                title: "Nombre",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "description",
                title: "Comentario",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "creationDate",
                title: "Fecha",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "statusCard",
                title: "Estado",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "validation",
                title: "Validacion",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "itemBtn",
                title: "\u00a0Acciones\u00a0\u00a0\u00a0",
                width: "5%",
                orderable: false,
              },
            ],
          });
          $(".csHidden").attr("style", "display:none");
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
    setDataLoading(false);
  };

  useEffect(() => {
    fillData();
  }, []);

  const onButtonClick = () => {
    props.history.push("/addMedicalLicenses");
  };

  return (
    <div className=" htmlMyrequests table-responsive container">
      <section>
        <br />
        <div className="row">
          <div className="col-12">
            <br />
            <h2 className="h2" Style={"text-align:center"}>
              Mis&nbsp;Licencia Médicas
            </h2>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className=" col-12">
            <div className="input-group-prepend">
              <div className="text-wrap textInfo mb-2">
                <span>
                  <i className="fa fa-exclamation-triangle faa-flash animated"></i>
                  &nbsp;FAVOR DE REVISAR SU FOTO ANTES DE ENVIARLA SOLO ELIMINAR
                  EL CONTENIDO EN CASO DE ALGÚN ERROR AL ENVIAR LA IMAGÉN POR
                  OTRA.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="row">
        <div className="col-lg-10 col-md-10 col-sm-8"></div>
        <div className="col-lg-2 col-md-2 col-sm-4">
          <div className="mb-2">
            <Button
              type="btnRequest btn-outline-danger text-wrap most-visited-log"
              data_prefix="myrequest/solicitarcarta"
              text="Enviar Licencia Médica"
              clickHandler={onButtonClick}
            />
            <br />
          </div>
        </div>
        {!dataLoading ? (
          <>
            <div className="container">
              <div className="">
                {!dataLoading ? (
                  <div
                    className="scroll-table bordered"
                    Style="min-height:600px"
                  >
                    <table
                      id="TblMedicalLicenses"
                      className="table table-striped table-bordered display"
                      Style="width:100% !important"
                    ></table>
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="container">
            <Loading />
          </div>
        )}

        <br />
        <br />
      </div>
    </div>
  );
}
