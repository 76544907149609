import { jwtDecode } from "jwt-decode";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { getUser } from "../../utils/Common";
import { NumbersWithComma } from "../../utils/CommonFunctions";
import Moment from "moment";
import es from "date-fns/locale/es";
import "moment/locale/es";
import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";
import { GetImagePatch } from "../../utils/CommonFunctions";
registerLocale("es", es);

export const GetLetterParam = (LetterToDisplay) => {
  let addressFooter = [];
  //TODO: Create new table to store this information.
  addressFooter.push({
    company_id: "04",
    companyName: "Alambres y Cables",
    address: "Calle F, Santo Domingo",
    phones: "Tel.809-537-2977",
    email: "rossy.padilla@corripio.com.do",
    sello: 'hidden="true"',
    rnc: "",
    suggestionEmail: "gygcercadeti@corripio.com.do",
  });
  addressFooter.push({
    company_id: "39",
    companyName: "Grupo Corvi S.A.S",
    address: "Autopista Duarte Km. 24, Santo Domingo, Rep. Dom.",
    phones: "Tel.809-331-0771 y 1-809-200-8113",
    email: "corvi@corripio.com.do",
    sello: 'hidden="true"',
    rnc: "",
    suggestionEmail: "GestionHumanaGrupoCorvi@corripio.com.do",
  });
  addressFooter.push({
    company_id: "01",
    companyName: "DISTRIBUIDORA CORRIPIO, S.A.S.",
    address:
      "Núñez de Cáceres esq. calle 1era. San Gerónimo, Santo Domingo, Rep.Dom.",
    phones: "Tel.809 227 3000",
    email: "www.distribuidoracorripio.com.do",
    sello: "",
    firma: "Osvaldo_Rosario_cocosign.png",
    rnc: "| RNC: 101003693",
    suggestionEmail: "gygcercadeti@corripio.com.do",
  });
  
  addressFooter.push({
    company_id: "08",
    companyName: "AUNCO",
    companyLogo: "auco.png",
    address:
      "Av. San Martín, Santo Domingo 10510, Rep.Dom.",
    phones: "Tel.809-565-7781",
    email: "yohanna.figueroa@corripio.com.do",
    sello: 'hidden="true"',
    firma: "Auco_firma.png",
    rnc: "| RNC: 101003693",
  });

  let signData = [];
  if (sessionStorage.getItem("signData") !== null) {
    signData = JSON.parse(sessionStorage.getItem("signData"));
     
  }
  var addressFooterData = addressFooter.find(
    (x) => x.company_id == getUser().companyId
  );
  //Decodificamos el Json para obtener la cédula
  const user = jwtDecode(getUser().employeeIdCard)
  const cedula = user.sub.split("/")[0]; 
  return {
    rnc: addressFooterData.rnc,
    sello: addressFooterData.sello,
    companyName: addressFooterData.companyName,
    address: addressFooterData.address,
    phones: addressFooterData.phones,
    email: addressFooterData.email,
    companyLogo: GetCssByCompany().CompanyLogoWithTitle,
    header_date:
      Moment(Date.now())
        .format("LL")
        .substring(0, Moment(Date.now()).format("LL").length - 8) +
      ", " +
      Moment(Date.now()).format("YYYY"),
    employee_id_card: cedula.trim(),
    employeeNumber: getUser().employeeNumber,
    employee_name:
      (getUser().firstName.toUpperCase().trim() ?? "") +
      " " +
      (getUser().lastName.toUpperCase().trim() ?? ""),
    hiring_date:
      Moment(new Date(getUser().hiringDate.toString()))
        .format("LL")
        .substring(
          0,
          Moment(new Date(getUser().hiringDate.toString())).format("LL")
            .length - 8
        ) +
        " del " +
        Moment(new Date(getUser().hiringDate.toString())).format("YYYY") ?? "",
    date:
      Moment(Date.now()).format("DD") +
      " días " +
      Moment(Date.now())
        .format("LL")
        .substring(3, Moment(Date.now()).format("LL").length - 8) +
      " del año " +
      Moment(Date.now()).format("YYYY"),
    prefix:
      getUser().gender.toUpperCase().trim() === "M"
        ? " el Sr."
        : " la Sra. " ?? "",
    position_name: getUser().positionName.toUpperCase().trim() ?? "",
    responsable_name: signData.staffLetterSignName ?? "",
    responsable_position: signData.employeePosition ?? "",
    urlImageSigned: GetImagePatch("/images/" + addressFooterData.firma),
    salary_frequency: LetterToDisplay.SalaryFrequencyName.toLowerCase(),
    salary: NumbersWithComma(
      parseInt(LetterToDisplay.SalaryFrequency) > 1
        ? getUser().annualSalary
        : getUser().salaryLetter
    ),
    bankName: LetterToDisplay.BankName,
    consulateName: LetterToDisplay.ConsulateName,
    OtherEntityName: LetterToDisplay.OtherLetterText,
    LetterToDisplay: LetterToDisplay.LetterToDisplayName,
    cardTypeId: parseInt(sessionStorage.getItem("CardTypeValue") ?? 0),
    salaryFrequencyId: parseInt(sessionStorage.getItem("SalaryValue") ?? 0),
    statusCardId: 1,
    consulateId: parseInt(sessionStorage.getItem("ConsulateValue") ?? 0),
    bankId: parseInt(sessionStorage.getItem("BankValue") ?? 0),
    companyId: getUser().companyId,
    suggestionEmail: addressFooterData.suggestionEmail,
  };
};

export const GetLetterParams = (
  employee,
  salaryFrequencyId,
  consulate,
  bank,
  others
) => {
  let salaryName = "";
  if (salaryFrequencyId == 1) {
    salaryName = "anual";
  } else if (salaryFrequencyId == 2) {
    salaryName = "mensual";
  }

  let addressFooter = [];
  //TODO: Create new table to store this information.
  addressFooter.push({
    company_id: "04",
    companyName: "Alambres y Cables",
    address: "Calle F, Santo Domingo",
    companyLogo: "alcatext.png",
    phones: "Tel.809-537-2977",
    email: "rossy.padilla@corripio.com.do",
    sello: 'hidden="false"',
    firma: "alcatext.png",
    rnc: "",
  });
  addressFooter.push({
    company_id: "39",
    companyName: "Grupo Corvi S.A.S",
    companyLogo: "corvi_logo.png",
    address: "Autopista Duarte Km. 24, Santo Domingo, Rep. Dom.",
    phones: "Tel.809-331-0771 y 1-809-200-8113",
    email: "corvi@corripio.com.do",
    sello: 'hidden="false"',
    firma: "firma_corvi.png",
    rnc: "",
  });
  addressFooter.push({
    company_id: "01",
    companyName: "DISTRIBUIDORA CORRIPIO, S.A.S.",
    companyLogo: "logo_dc.png",
    address:
      "Núñez de Cáceres esq. calle 1era. San Gerónimo, Santo Domingo, Rep.Dom.",
    phones: "Tel.809 227 3000",
    email: "www.distribuidoracorripio.com.do",
    sello: "",
    firma: "Osvaldo_Rosario_cocosign.png",
    rnc: "| RNC: 101003693",
  });

  addressFooter.push({
    company_id: "08",
    companyName: "AUNCO",
    companyLogo: "auco.png",
    address:
      "Av. San Martín, Santo Domingo 10510, Rep.Dom.",
    phones: "Tel.809-565-7781",
    email: "yohanna.figueroa@corripio.com.do",
    sello: 'hidden="true"',
    firma: "Auco_firma.png",
    rnc: "| RNC: 101003693",
  });

//Decodificamos el Json para obtener la cédula
const user = jwtDecode(getUser().employeeIdCard)
const cedula = user.sub.split("/")[0]; 
  let signData = [];
  if (sessionStorage.getItem("signData") !== null) {
    signData = JSON.parse(sessionStorage.getItem("signData"));
  }
  var addressFooterData = addressFooter.find(
    (x) => x.company_id == employee.companyId
  );
  return {
    rnc: addressFooterData.rnc,
    sello: addressFooterData.sello,
    companyName: addressFooterData.companyName,
    address: addressFooterData.address,
    phones: addressFooterData.phones,
    email: addressFooterData.email,
    companyLogo: GetCssByCompany().CompanyLogoWithTitle,
    header_date:
      Moment(Date.now())
        .format("LL")
        .substring(0, Moment(Date.now()).format("LL").length - 8) +
      ", " +
      Moment(Date.now()).format("YYYY"),
    employee_id_card: cedula,
    employeeNumber: employee.employeeNumber,
    employee_name:
      (employee.firstName.toUpperCase().trim() ?? "") +
      " " +
      (employee.lastName.toUpperCase().trim() ?? ""),
    hiring_date:
      Moment(new Date(employee.hiringDate.toString()))
        .format("LL")
        .substring(
          0,
          Moment(new Date(employee.hiringDate.toString())).format("LL").length -
            8
        ) +
        " del " +
        Moment(new Date(employee.hiringDate.toString())).format("YYYY") ?? "",
    date:
      Moment(Date.now()).format("DD") +
      " días " +
      Moment(Date.now())
        .format("LL")
        .substring(3, Moment(Date.now()).format("LL").length - 8) +
      " del año " +
      Moment(Date.now()).format("YYYY"),
    prefix:
      employee.gender.toUpperCase().trim() === "M"
        ? " el Sr."
        : " la Sra. " ?? "",
    position_name: employee.positionName.toUpperCase().trim() ?? "",
    responsable_name: signData.staffLetterSignName ?? "",
    responsable_position: signData.employeePosition ?? "",
    urlImageSigned: GetImagePatch("/images/" + addressFooterData.firma),
    salary_frequency: salaryName,
    salary: NumbersWithComma(
      salaryFrequencyId == 1 ? employee.annualSalary : employee.salaryLetter
    ),
    bankName: bank,
    consulateName: consulate,
    OtherEntityName: others,
    LetterToDisplay: "NI IDEA",
    cardTypeId: parseInt(sessionStorage.getItem("CardTypeValue") ?? 0),
    salaryFrequencyId: parseInt(sessionStorage.getItem("SalaryValue") ?? 0),
    statusCardId: 1 /*Pendiente*/,
    consulateId: parseInt(sessionStorage.getItem("ConsulateValue") ?? 0),
    bankId: parseInt(sessionStorage.getItem("BankValue") ?? 0),
    companyId: employee.companyId,
  };
};

export const ShowHideContinueBtn = () => {
  let resultValidation = false;
  let dropData = document.getElementById("dropFields");
  let controllers = dropData.getElementsByClassName(
    "css-2b097c-container"
  ).length;
  let controllersSelected = dropData.getElementsByClassName(
    "css-1uccc91-singleValue"
  ).length;
  let otherControllers = dropData.getElementsByClassName("letterFields");

  if (controllers > controllersSelected + 1) {
    resultValidation = true;
  } else if (otherControllers.length > 0) {
    if (otherControllers[0].value.trim().length < 2) {
      resultValidation = true;
    }
  }

  return resultValidation;
};
export const LetterToDisplay = () => {
  let dropData = document.getElementById("dropFields");
  let itemData = dropData.getElementsByClassName(" css-1uccc91-singleValue");
  let dataResult = [];
  let finaleReturn = {
    LetterToDisplayName: "none",
    SalaryFrequencyName: "",
    SalaryFrequency: sessionStorage.getItem("SalaryAmount") ?? 1,
    BankName: "",
    ConsulateName: "",
    OtherLetterText: "",
  };

  for (let idx = 0; idx < itemData.length; idx++) {
    const item = itemData[idx];
    dataResult.push(item.innerText.toLowerCase());
  }

  if (dataResult.length > 0) {
    switch (dataResult[0].toLowerCase().trim()) {
      case "a quien pueda interesar sin salario":
        finaleReturn.LetterToDisplayName = "letterWithoutSalary";
        ClearSessions([
          "ConsulateValue",
          "BankValue",
          "SalaryAmount",
          "SalaryValue",
        ]);
        finaleReturn.OtherLetterText = null;
        break;
      case "a quien pueda interesar":
        finaleReturn.LetterToDisplayName = "letterWithSalary";
        finaleReturn.SalaryFrequencyName = dataResult[1].toLowerCase().trim();
        ClearSessions(["ConsulateValue", "BankValue"]);
        finaleReturn.OtherLetterText = null;
        break;
      case "carta bancaria":
        finaleReturn.LetterToDisplayName = "letterBankWithSalary";
        finaleReturn.SalaryFrequencyName = dataResult[1].toLowerCase().trim();
        finaleReturn.BankName = dataResult[2].toLowerCase().trim();
        ClearSessions(["ConsulateValue"]);
        finaleReturn.OtherLetterText = null;
        break;
      case "carta consular":
        finaleReturn.LetterToDisplayName = "letterConsulateWithSalary";
        finaleReturn.SalaryFrequencyName = dataResult[1].toLowerCase().trim();
        finaleReturn.ConsulateName = dataResult[2].toLowerCase().trim();
        ClearSessions(["BankValue"]);
        finaleReturn.OtherLetterText = null;
        break;
      case "carta otro":
        finaleReturn.LetterToDisplayName = "letterOtherWithSalary";
        finaleReturn.SalaryFrequencyName = dataResult[1].toLowerCase().trim();
        finaleReturn.OtherLetterText = dropData
          .getElementsByClassName("letterFields")[0]
          .value.toLowerCase()
          .trim();
        ClearSessions(["ConsulateValue", "BankValue"]);

        break;

      default:
        break;
    }
  }

  return finaleReturn;
};
export const ParamSender = () => {
  let dataParam = GetLetterParam(LetterToDisplay());
  let tipoCard = "";

  if (dataParam.cardTypeId == 0) {
    tipoCard = "A quien pueda interesar sin salario";
  } else if (dataParam.cardTypeId == 1) {
    tipoCard = "A quien pueda interesar";
  } else if (dataParam.cardTypeId == 2) {
    tipoCard = "Carta bancaria";
  } else if (dataParam.cardTypeId == 3) {
    tipoCard = "Carta consular";
  } else {
    tipoCard = "Carta otro";
  }
  let params = {
    cardTypeId: dataParam.cardTypeId,
    employeeNumber: dataParam.employeeNumber,
    salaryFrequencyId: dataParam.salaryFrequencyId,
    statusCardId: dataParam.statusCardId,
    consulateId: dataParam.consulateId,
    bankId: dataParam.bankId,
    other: dataParam.OtherEntityName,
    companyId: dataParam.companyId,
    email: dataParam.suggestionEmail,
    name: getUser().firstName + " " + getUser().lastName,
    tipoCard: tipoCard,
  };

  return params;
};
export const ClearSessions = (param) => {
  let arrSession = [
    "CardTypeValue",
    "ConsulateValue",
    "BankValue",
    "SalaryAmount",
    "SalaryValue",
  ];
  if (JSON.stringify(param).includes("all")) {
    arrSession.forEach((key) => {
      sessionStorage.removeItem(key);
    });
  } else {
    param.forEach((key) => {
      sessionStorage.removeItem(key);
    });
  }
};
