import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import Moment from "moment";
import "moment/locale/es";
import $ from "jquery";
import { NumbersWithComma } from "../../utils/CommonFunctions";
import es from "date-fns/locale/es";
import Button from "../Button/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import "react-datepicker/dist/react-datepicker.css";
import "./AnnualCompensation.css";
import BuildTableWithData from "./AnnualCompetionTable";
import { getUser } from "../../utils/Common";
import API from "../../utils/api";
import BuildChart from "./AnnualComensationChart";
import Loading from "../loading/loading";

registerLocale("es", es);

const AnnualCompensation = ({ User }) => {
  const [radioValue, setRadioValue] = useState("detail");
  const [showRadio, setShowRadio] = useState(true);
  const radios = [
    { name: "Detalles", value: "detail" },
    { name: "Gráfico", value: "grafic" },
  ];

  const [listAnnualComp, setListAnnualComp] = useState([]);
  const [listAnnualData, setListAnnualData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [dataLoading, setDataLoading] = useState(true);

  const clickSearch = () => {
    API.getData(
      "/PayRoll/GetbyEmployeeAndDateRange?employeeNumber=" +
      getUser().employeeIdCard.trim() +
      "&companyId=" +
      getUser().companyId +
      "&dateTimeFrom=" +
      Moment(startDate).format("YYYY-MM-DD") +
      "&dateTimeTo=" +
      Moment(endDate).format("YYYY-MM-DD")
    )
      .then((res) => {
        setDataLoading(false);
        if (res.status === 200) {
          setListAnnualComp(BuildTableWithData(res));
          setListAnnualData(res);
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
    setDataLoading(false);
  };
  const onChangeRadio = (e) => {
    let validation = e.currentTarget.value;
    setRadioValue(validation);
    if (validation === "detail") {
      setShowRadio(true);
    } else {
      setShowRadio(false);
    }
  };

  useEffect(() => {
    clickSearch();
  }, []);

  return (
    <>
      <br />
      <div className=" htmlAnnualCompensation table-responsive container">
        <div className="row">
          <div className="lowcolor col-12">
            <br />
            <h2 className="h2" Style={"text-align:center"}>
              Mi&nbsp;Resumen&nbsp;Ingresos
            </h2>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-group-prepend">
              <div className="input-group-text mb-2">
                Seleccione&nbsp;el&nbsp;rango&nbsp;de&nbsp;fecha&nbsp;que&nbsp;desea&nbsp;visualizar
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">Desde:</div>
              </div>
              <DatePicker
                id="dtFrom"
                className=" reactCalendar"
                relativeSize={true}
                popperPlacement="top-start"
                selected={startDate}
                locale="es"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setStartDate(date)}
                peekNextMonth 
                showMonthDropdown
                dropdownMode="select"
                useShortMonthInDropdown
                showYearDropdown
 
              />
              <div className="input-group-prepend calendarCs">
                <div className="notAllowed">
                  <span
                    className="iconify"
                    data-icon="fa:calendar"
                    data-inline="false"
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">Hasta:&nbsp;</div>
              </div>
              <DatePicker
                className=" reactCalendar"
                relativeSize={true}
                popperPlacement="auto-right"
                id="dtTo"
                selected={endDate}
                minDate={startDate}
                locale="es"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setEndDate(date)}
                peekNextMonth  
                showMonthDropdown 
                dropdownMode="select"
                useShortMonthInDropdown
                showYearDropdown
              />
              <div className="input-group-prepend calendarCs">
                <div className="notAllowed">
                  <span
                    className="iconify"
                    data-icon="fa:calendar"
                    data-inline="false"
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 col-sm-12">
            <div className="input-group mb-2">
              <Button
                type="btn-outline-danger"
                text="BUSCAR"
                clickHandler={clickSearch}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <header className="container">
            <div className="important-jumbotron">
              <div className="container">
                <h5 className=" text-justify">
                  <i className="fa fa-exclamation-triangle faa-flash animated faa-slow red">
                    <b>&nbsp;Importante!!!</b>
                  </i>
                </h5>
                <p className=" text-justify">
                  Los datos aquí suministrados son aproximados y estimados.
                  Exclusivamente para consumo individual del colaborador. En
                  ningún caso podrá ser utilizado como cifras oficiales ante
                  instituciones externas a la empresa, ni ser considerados como
                  definitivos.
                </p>
              </div>
            </div>
          </header>{" "}
          <div className="lowcolor col-12">
            Resultados
            <hr />
          </div>
        </div>
        <div className="">
          {!dataLoading ? (
            <Accordion defaultActiveKey="0">
              {listAnnualComp.map((item) => (
                <Card key="0">
                  <Card.Header>
                    <ButtonGroup toggle>
                      {radios.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          type="radio"
                          variant="secondary"
                          name="radio"
                          className="rdo-grapic"
                          value={radio.value}
                          checked={radioValue === radio.value}
                          onChange={onChangeRadio}
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </Card.Header>
                  {showRadio ? (
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.itemData }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  ) : (
                    <Accordion.Collapse eventKey="0">
                      <BuildChart props={listAnnualData} />
                    </Accordion.Collapse>
                  )}
                </Card>
              ))}
            </Accordion>
          ) : (
            <div className="container">
              <Loading />
            </div>
          )}
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default AnnualCompensation;
