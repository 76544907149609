import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import { Carousel } from "react-responsive-carousel";
import { getUser, removeUserSession } from "../../utils/Common";

const CarouselServices = ({ props }) => {
  const [listCarousel, setListCarousel] = useState([]);
  useEffect(() => {
    API.getData("Slider/GetbyCompanyId?id=" + getUser().companyId)
      .then((res) => {
        if (res.status === 200) {
          let ArrImage = [];
          for (let idx = 0; idx < res.data.length; idx++) {
            ArrImage.push({
              id: res.data[idx].id,
              url: res.data[idx].imageName,
              class: "",
            });
          }
          setListCarousel(ArrImage);
        }
      })
      .catch(function (err) {
        const DefUrl = [
          { id: 0, url: "/images/logoCorripio.svg", class: "defaultLogo" },
        ];
        setListCarousel(DefUrl);
        console.error("Error de conexion " + err);
      });
  }, []);

  return (
    <>
      <Carousel {...props()} className="banner">
        {listCarousel.map((item, index) => (
          <div key={index} className="">
            <img
              className={"img-fluid img-responsive img-carousel"}
              src={item.url}
              alt={"pic" + item.id}
            />
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default CarouselServices;
