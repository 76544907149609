import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import Button from "../../components/Button/Button";
import { getUser } from "../../utils/Common";
import DropdownList from "../../components/dropdown/dropdownList";
import {
  ShowPopUp,
  ShowConfirmationMessage,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";
import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";

export default function TicketGyG(props) {
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };
  const detailTikect = useFormInput("");

  //Function for sending mail, the part of (email) is the recipient(To whom the mail will arrive) 
  //and emailFrom is the sender (who sends the message).
  const sendMail = () => {
    if(getUser()){
        if(getUser().email !== null){
            API.postData("EmailSender/sendTickeEmail", {
                Email: "asistenciagyg@corripio.com.do",
                Name: getUser().firstName + " " + getUser().lastName,
                Departament: getUser().department,
                Message: detailTikect.value.toLowerCase(),
                emailFrom: getUser().email
              })
                .then((response) => {
                  ShowAlertMessage(
                    "¡Mensaje de aviso!",
                    " <p>Asistencia enviada correctamente</p>"
                  );
                  setTimeout(() => {
                    window.location.reload(true);
                  }, 1200);
                })
                .catch((error) => {
                  ShowAlertMessage(
                    "¡Mensaje de aviso!",
                    " <p>Intente de nuevo, algo salio mal al enviar su asistencia</p>"
                  );
                  console.log(error);
                });
        }else{
            ShowAlertMessage(
                "¡Mensaje de aviso!",
                " <p>Usted no tiene correo registrado en nuestra base de datos por favor comunicarse con Mesa de Ayuda</p>"
            );
        }
    }else{
        console.log("error")
    }
  };

  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <h4 className="text-center">
          Ticket de Asistencia GYG
        </h4>
        <hr />
        <div className="jumbotron">
          <b>
            <p className="text-center">
              En Distruibuidora Corripio nos encanta saber de que manera podemos
              ayudarte en tu espacio de trabajo.
            </p>
          </b>
        </div>

        <section>
          <div id="myform" className="form_container">
            <div className="form_group">
              <label className="classspan text-left">Detalle del servicio</label>
              <textarea
                type="text"
                rows="4"
                {...detailTikect}
                cols="50"
              ></textarea>
              <br />
              <Button
                type="btn-outline-danger"
                text="ENVIAR"
                clickHandler={sendMail}
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
