import React from "react";
import MyRequestServices from "../../components/myrequests/myrequests";

export default function MedicalLicense(props) {
  return (
    <section>
      <MyRequestServices />
    </section>
  );
}
