import React from 'react';
import ReactDOM from 'react-dom';
import Menu from './components/menu/menu';

ReactDOM.render(
  <React.StrictMode>
    <Menu />
  </React.StrictMode>,
  document.getElementById('root'),
);

 