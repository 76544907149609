import React, { useState } from "react";
import Moment from "moment";
import { NumbersWithComma } from "../../utils/CommonFunctions";

export default function BuildTableWithData(res) {
  var uniqueDate = [],
    ListData = [],
    resultMixed = [],
    currentHTML = [];
  /*Step 1: Extract Inique date from data and split Data and formatting date*/
  uniqueDate.push(
    res.data
      .map((item) => Moment(item.payrollDate).format("YYYY-MM-DD"))
      .filter((value, index, self) => self.indexOf(value) === index)
  );
  for (let index = 0; index < uniqueDate[0].length; index++) {
    res.data.forEach((item) => {
      if (
        Moment(item.payrollDate).format("YYYY-MM-DD") === uniqueDate[0][index]
      ) {
        ListData.push({
          payrollDate: Moment(item.payrollDate).format("YYYY-MM-DD"),
          employeeNumber: item.employeeNumber,
          transactionDescription: item.transactionDescription,
          amount: item.amount,
          balance: item.balance,
          id: item.id,
          payrollType: item.payrollType,
        });
      }
    });

    if (ListData.length > 0) {
      resultMixed.push({ data: ListData });
    }
    ListData = [];
  }
  /*END Step 1:  of Extract Inique date from data and split Data and formatting date*/

  /*Step 2: Creation of Table for each date*/
  for (let index = 0; index < uniqueDate[0].length; index++) {
    let htmlBuilder = [];
    let amountList = [];
    let TotalAmount = 0;

    /*Step 2.1: Creation of Header*/
    htmlBuilder.push(
      "<div class='table-responsive'>" +
        "<table class='table'><thead><tr>" +
        "<th scope='col'>" +
        "<span class='defColorHeader'>DESCRIPCION</span>" +
        "</th>" +
        "<th scope='col' class='defColorHeader colRight'>" +
        "<span>CANTIDAD</span>" +
        "</th>" +
        "<th scope='col' class='defColorHeader colRight'>" +
        "<span class='defColorHeader'>BALANCE</span>" +
        "</th>" +
        "</tr></thead>" +
        "<tbody class='tbody'></tbody>"
    );
    /*END of Step 2.1: Creation of Header*/

    /*Step 2.2: Insert details into table*/
    for (let idx = 0; idx < resultMixed.length; idx++) {
      if (resultMixed[idx].data[0].payrollDate === uniqueDate[0][index]) {
        let items = [];
        amountList = [];
        for (
          let idxItem = 0;
          idxItem < resultMixed[idx].data.length;
          idxItem++
        ) {
          items = resultMixed[idx].data[idxItem];
          if (isNaN(items.amount) === false) {
            amountList.push(items.amount);
          }

          htmlBuilder.push(
            "<tr class='defSize'><td  data-item='" +
              items.id +
              "' data-item-date='" +
              Moment(items.payrollDate).format("YYYY-MM-DD") +
              "' >" +
              items.transactionDescription +
              "</td>" +
              "<td class='colRight  " +
              (items.amount.toString().indexOf("-") == 0 ? "red" : "") +
              "'>" +
              NumbersWithComma(items.amount) +
              "</td>" +
              "<td class='colRight'>" +
              NumbersWithComma(items.balance) +
              "</td></tr>"
          );
        }
        TotalAmount = NumbersWithComma(amountList.reduce((a, b) => a + b, 0));
        /*END of Step 2.2: Insert details into table*/

        /*Step 2.3: Creation of html*/
        currentHTML.push({
          id: currentHTML.length + 1,
          payrollDate: items.payrollDate,
          itemData:
            htmlBuilder.join("") +
            "</tr><tfoot><tr><th scope='row'><span class='defColorHeader'>NETO&nbsp;A&nbsp;PAGAR" +
            "</span></th><th scope='row' class='defColorHeader colRight " +
            (TotalAmount.toString().indexOf("-") == 0 ? "red" : "") +
            "'><span>" +
            TotalAmount +
            "</th><th></th></tr>" +
            "</tfoot></table>",
        });

        /*End of Step 2.3: Creation of html*/
      }
    }
    /*END of Step 2: Creation of Table for each date*/
  }

  return currentHTML;
}