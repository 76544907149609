import React from 'react'
import API from '../../utils/api';
import { MessageResults, ShowAlertMessage, ShowConfirmationMessage } from '../../utils/CommonFunctions';

export const addDependi = async (dependient)=>{
    return new Promise((resolve, eject)=>{
        API.postData("Dependient/Add", dependient)
            .then((response) => {
              setTimeout(() => {
                MessageResults(response.status);
              }, 1500);
            })
            .catch((error) => {
              ShowAlertMessage(
                "¡Mensaje de aviso!",
                " <p>Intente de nuevo, algo salio mal al enviar el permiso</p>"
              );
              console.log(error);
            });
    });
}


export const getDependient = (employeeId) => {
    return new Promise( (resolve, eject) => {
        API.getData(`Dependient/get?employeeId=${employeeId}`).then( response =>{
            resolve(response);
        });
    })    
  };