import React, { useState, useEffect } from "react";
import {SetAvailableHolidays} from '../../components/vacations/vacationCommon';
import { getUser, removeUserSession } from "../../utils/Common"; 
import API from "../../utils/api";

const UserRequest = (props)=> { 
    const [siteMenu, setSiteMenu] = useState([]);
const GetMenu = (pathUrl)=>{
      API.getData("SiteMenu/GetbyMenuRole?companyId=" + getUser().companyId + "&isSupervisor=" + getUser().isSupervisor.toUpperCase())
      .then((res) => {
        if (res.status === 200) { 
           setSiteMenu(res.data.find(c=>c.PatchName == pathUrl));
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
}      

useEffect(() => {
 GetMenu(window.location.pathname.replace('/',''));
 SetAvailableHolidays();
}, []);

return (
<>
      <section className="container">
         <div className="inner">
          <br />
          <br />            
          {<div className="highlights container d-flex align-items-center justify-content-center" dangerouslySetInnerHTML={{ __html: siteMenu.TemplateHtml }} />}                          
         </div>
    </section>
    </>
 );
}
export default UserRequest;
