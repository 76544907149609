import React, { useState, useEffect } from "react";
import Loading from "../../components/loading/loading";
import { ShowAlertMessage } from "../../utils/CommonFunctions";
import $ from "jquery";
import { LangSpanish } from "../../tools/dataTables.Language";
import API from "../../utils/api";
import { getUser } from "../../utils/Common";

export default function PollVaccine() {
  const [dataLoading, setDataLoading] = useState(true);
  const [policyAccepted, setpolicyAccepted] = useState(true);

  const fillData = () => {
    let Record = [];
    API.getData(
      `/PollVaccinesService/GetbySupervisorNumber?companyId=${
        getUser().companyId
      }&employeeNumber=${getUser().employeeIdCard}`
    )
      .then((res) => {
        setDataLoading(false);
        console.log(res);
        if (res.status === 200) {
          let dataResult = [];
          setpolicyAccepted(res.data);
          let DeleteBtn =
            "<a href='#' id='btDel'  class='fa fa-eye custom-color size-effect-x2 ' title='Visualizar Encuestado' ></a>&nbsp;";
          let EditBtn =
            "&nbsp;<a href='#' id='btEdit'  class='fa fa-pencil-square-o custom-color size-effect-x2' title='Detelle Encuesta' ></a>&nbsp;";
          res.data.forEach((item) => {
            console.log(item);
            dataResult.push({
              name:
                '<span class="capitalized defaultText">' + item.name + "</>",
              employeeIdCard:
                '<span class="capitalized defaultText">' +
                item.employeeIdCard +
                "</span>",
              employeeNumber:
                '<span class="capitalized defaultText">' +
                item.employeeNumber +
                "</span>",
              employee:
                '<span class="capitalized defaultText">' +
                item.firstName +
                " " +
                item.lastName +
                "</span>",
              noVacunado:
                '<span class="capitalized defaultText">' +
                (item.noVacunado === "0" ? "No" : "Si") +
                "</span>",
              firstDosis:
                '<span class="capitalized defaultText">' +
                (item.firstDosis === "0" ? "No" : "Si") +
                "</span>",
              secondDosis:
                '<span class="capitalized defaultText">' +
                (item.secondDosis === "0" ? "No" : "Si") +
                "</span>",
              thirdDosis:
                '<span class="capitalized defaultText">' +
                (item.thirdDosis === "0" ? "No" : "Si") +
                "</span>",
              fourDosis:
                '<span class="capitalized defaultText">' +
                (item.cuartaDosis === "0" ? "No" : "Si") +
                "</span>",
              covid:
                '<span class="capitalized defaultText">' +
                (item.covid === "0" ? "No" : "Si") +
                "</span>",
              imangen:
                '<img src="' +
                item.imangen +
                '"  class="img-fluid "  alt="Logo" />',
              itemBtn:
                "<span data-created='" +
                item.id +
                "'  data-item='" +
                Buffer.from(JSON.stringify([item])) +
                "'>" +
                DeleteBtn +
                EditBtn +
                "</span>",
            });
          });

          $("#TblpollVa").DataTable({
            destroy: true,
            searching: true,
            language: LangSpanish,
            bLengthChange: false,
            lengthMenu: [10, 20, 40, 60, 80, 90, 100, 200],
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: ["copy", "excel", "pdf"],
            data:
              dataResult.length === 0
                ? [
                    {
                      name: "",
                      thirdDosis: "",
                      employeeIdCard: "",
                      employeeNumber: "",
                      employee: "",
                      noVacunado: "",
                      firstDosis: "",
                      secondDosis: "",
                      imangen: "",
                      itemBtn: "",
                      covid: "",
                      fourDosis: "",
                    },
                  ]
                : dataResult,
            columns: [
              {
                data: "name",
                title: "Empleado ",
                width: "15%",
                className: "capitalized",
              },
              {
                data: "employeeIdCard",
                title: "Cedula",
                width: "15%",
                className: "capitalized",
              },
              {
                data: "employeeNumber",
                title: "Codigo",
                width: "15%",
                className: "capitalized",
              },
              {
                data: "noVacunado",
                title: "Vacunado",
                width: "5%",
                className: "capitalized",
              },
              {
                data: "firstDosis",
                title: "Primera Dosis",
                width: "15%",
                className: "capitalized",
              },
              {
                data: "secondDosis",
                title: "Segunda Dosis",
                width: "15%",
                className: "capitalized",
              },
              {
                data: "thirdDosis",
                title: "Tercera Dosis",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "fourDosis",
                title: "Cuarta Dosis",
                width: "20%",
                className: "capitalized",
              },
              {
                data: "covid",
                title: "Te ha dado Covid",
                width: "20%",
                className: "capitalized",
              },
            ],
          });
          $(".csHidden").attr("style", "display:none");
        }
      })
      .catch(function (err) {
        ShowAlertMessage(
          "Información",
          "Hubo un problema intente de nuevo",
          "error"
        );
        setDataLoading(false);

        console.error("Error de conexion " + err);
      });
  };

  useEffect(() => {
    fillData();
  }, []);

  return (
    <>
      <div>
        <br />
        <div className=" htmlPayroll    container">
          <section>
            <br />
            <div className="row">
              <div className="col-12">
                <br />
                <h2 className="h2" Style={"text-align:center"}>
                  &nbsp;REPORTE &nbsp;DE &nbsp;VACUNADOS
                </h2>
                <hr />
              </div>
            </div>
          </section>
          <div className="row ">
            {!dataLoading ? (
              <>
                <div className="container">
                  <div className="">
                    {!dataLoading ? (
                      <div
                        className="scroll-table bordered"
                        Style="min-height:600px"
                      >
                        <table
                          id="TblpollVa"
                          className="table table-striped table-bordered display"
                          Style="width:100% !important"
                        ></table>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="container">
                <Loading />
              </div>
            )}
          </div>

          <br />
          <br />
        </div>
        <br />
        <br />
      </div>
    </>
  );
}
