import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import { getToken, setUserSession } from "../../utils/Common";
import { SetCssByCompany } from "../../CssbyCompany/CommonCSS";

import {
  ShowPopUp,
  ShowAlertMessage,
  ShowInfoMessage,
  ShowConfirmationMessage,
} from "../../utils/CommonFunctions";
import { NumbersWithComma } from "../../utils/CommonFunctions";
import $, { param } from "jquery";

$(function () {
  if (localStorage.chkbx && localStorage.chkbx != "") {
    $("#remember_me").attr("checked", "checked");
    $("#tbUser").val(localStorage.usrname);
    $("#pass").val(localStorage.pass);
  } else {
    $("#remember_me").removeAttr("checked");
    $("#tbUser").val("");
    $("#pass").val("");
  }

  $("#remember_me").click(function () {
    if ($("#remember_me").is(":checked")) {
      // save username and password
      localStorage.usrname = $("#tbUser").val();
      localStorage.pass = $("#pass").val();
      localStorage.chkbx = $("#remember_me").val();
    } else {
      localStorage.usrname = "";
      localStorage.pass = "";
      localStorage.chkbx = "";
    }
  });
});
export default function Login(props) {
  const SaveChanges = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };
  const SaveChanges1 = () => {
    window.location.href = "https://forms.office.com/Pages/ResponsePage.aspx?id=pre5AjWJf0CHl_F6qYOOO8aQt_NBpdNCi1TmP_lxd_1UNU5DSlVSR0NJNE9GVTlKMEhMRDJNT1JGOC4u";
  
  };

  const handleLogin = () => {
    setError(null);
    setLoading(true);
    API.postData("auth/login", {
      username: username.value,
      password: password.value,
    })
      .then((response) => {
        setLoading(false);
        setUserSession(
          response.data.token,
          response.data.user,
          response.data.requiredchangePSW,
          response.data
        );
        SetCssByCompany();
     /*   if (response.data.activateBonificacionMens) {
        /*  ShowPopUp({
            handlerEvent: SaveChanges1,
            htmlBody: `<html><body><section>
        <div class="container">
         </b>Favor llenar la encuesta: Agradecimiento - Viernes 10 de diciembre 2021 <br /> 
        </div>	
        </section></body></html>`,
            isDisabled: true,
            TextOk: "Aceptar",
          });
 /*
          //Esto es para la bonificación esta comentada no borrar

             if (response.data.bonificiacion == !"OK") {
            ShowPopUp({
              handlerEvent: SaveChanges,
              htmlBody: `<html><body><section>
          <div class="container">
          Pago Bonificación 2020<br /><br />Estimado colaborador<br /><br />El 2023 fue un año de grandes retos y cambios, desafiamos las adversidades con la 
          voluntad y determinación para seguir “elevando la calidad de vida de los dominicanos a través de nuestros productos y servicios”. <br /><br />
          Agradecemos tu empeño y dedicación, para juntos obtener los resultados que hoy nos permiten realizar el pago de la bonificación.<br /><br />
          Es por ello que recibirás <b> RD$${NumbersWithComma(
            response.data.bonificiacion
          )} </b>(antes de descuentos) <br /><br />
          Esperamos continuar trabajando juntos para hacer de nuestra visión una realidad “contar con la preferencia de nuestras marcas en República Dominicana 
          “… porque contamos con buenas marcas y buenas personas.
          </div>	
          </section></body></html>`,
              isDisabled: true,
              TextOk: "Aceptar",
            });
          } else {
            setTimeout(() => {
              window.location.reload(false);
            }, 500);
          }
        } else {
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        }/*/
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response === undefined) {
          setError("Error: Error de Conexión");
        } else {
          if (error.response.status === 401) {
            setError("¡Verifique usuario y/o contraseña!");
            document.getElementById("tbUser").focus();
          } else setError("Error: " + error.message);
        }
      });
  };

  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };
  const restoreCredet = () => {
    if (restoreCred.value.trim() < 5) {
      ShowInfoMessage(
        "<i class='fa fa-exclamation-triangle faa-flash animated red'></i>&nbsp;<span class='red'>¡Debe ingresar su Cédula, para enviarle el enlace por correo!</span>"
      );
    } else {
      sendMail();
    }
  };
  const sendMail = () => {
    API.postData(
      "EmailSender/CredentialResetEmail?employeeIdCard=" +
        restoreCred.value.trim(),
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          ShowAlertMessage(
            "Información!",
            " <p>" + response.data.Message + "</p>"
          );
          props.history.push("/login");
        }
      })
      .catch((error) => {
        ShowAlertMessage(
          "Error!",
          " <p>" +
            error.request.responseText
              .replace('{"StatusCode":404,"Message":"', "")
              .replace('"}', "") +
            "</p>"
        );
        console.log(error);
      });
  };
  const user = localStorage.getItem("usrname") || "";
  const pass = localStorage.getItem("pass") || "";
  const [loading, setLoading] = useState(false);
  const username = useFormInput(user);
  const password = useFormInput(pass);
  const restoreCred = useFormInput("");
  const [error, setError] = useState(null);

  return (
    <div className="login_container">
         <img src="/images/GDC.png" class="img-fluid" alt="Responsive image" />
      <div id="myform" className="form_container">
        <hr />
        <div className="form_group">
          <label className="classspan">C&eacute;dula</label>

          <input
            id="tbUser"
            type="text"
            {...username}
            className="txtbox"
            placeholder="ejemplo: 00000000000"
          />
        </div>
        <div className="form_group">
          <label className="classspan">Contraseña</label>
          <input
            type="password"
            {...password}
            className="txtbox"
            placeholder="********"
            id="pass"
          />
        </div>
        <br />
        <div class="form-group">
          <input type="checkbox" class="form-check-input" id="remember_me" />
          <label class="form-check-label" for="remember_me">
            Recuerdame
          </label>
        </div>
        {error && (
          <>
            <small style={{ color: "red" }}>{error}</small>
            <br />
          </>
        )}
        <br />
        <div className="form_group">
          <button
            type="button"
            className="mybt btn btn-outline-danger text-wrap"
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? "Cargando..." : "ENTRAR"}
          </button>
        </div>
        <div className="forget">
          <hr />
          <a
            className="lform fa faa-flash animated faa-slow login-blue"
            href="#"
          >
            ¿Olvidaste tu Contraseña?
          </a>
        </div>
      </div>

      <div id="forget" className="form_container">
        <div className="form_group">
          <label class="classspan">
            Introduce tu cédula y te enviaremos un enlace por correo electrónico
            para crear una nueva contraseña.
          </label>
          <input
            type="text"
            {...restoreCred}
            className="txtbox"
            placeholder="Coloque su cédula"
          />
        </div>
        <div className="form_group">
          <br />
          <button
            type="button"
            className="mybt btn btn-outline-danger text-wrap"
            onClick={restoreCredet}
          >
            RESTABLECER
          </button>
        </div>
        <div className="forget">
          <hr />
          <a
            className="fform btn btn-info"
            Style="color:white !important"
            href="#"
          >
            Entrar
          </a>
        </div>
      </div>
    </div>
  );
}
