import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import { getUser, removeUserSession } from "../../utils/Common";

export default function Communication() {
  const [communication, setcommunication] = useState([]);

  useEffect(() => {
    API.getData("News/get?companyId=" + getUser().companyId)
      .then((response) => {
        setcommunication(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },[]);
  return (
    <div>
      <section className="wrapper">
        <div className="inner">
          <h4 className="text-center">Mis Comunicaciones</h4>
          <div className="table-wrapper">
            <table>
              {communication.map((item) => (
                <tr key={item.id}>
                  <td>
                    <a
                      href={
                        "/genericCommunication?id=" +
                        item.id +
                        "&name=" +
                        item.title
                      }
                      className="button fit"
                    >
                      {item.title}
                    </a>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </section>
    </div>
  );
}
