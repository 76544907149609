import Swal from "sweetalert2";
import SweetAlert from "sweetalert2-react";
import "animate.css/animate.min.css";
import "sweetalert2/dist/sweetalert2.css";
import API from "../utils/api";
import $ from "jquery";

export const NumbersWithComma = (numberVal) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return Number(numberVal).toLocaleString("en", options);
};

export const MessageResults = (status, error) => {
  Swal.fire({
    type: status === 200 || status === 201 ? "success" : "error",
    title:
      status === 200 || status === 201
        ? "Proceso realizado!"
        : "Proceso Fallido," + (error ?? "intente mas tarde!"),
    showConfirmButton: false,
    timer: 2000,
  });
};
export const ShowPopUp = (props) => {
  Swal.fire({
    title: "<strong>" + (props.titleName ?? "") + "</strong>",
    html: props.htmlBody ?? "<p></p>",
    showCloseButton: true,
    showCancelButton: true,
    allowOutsideClick: false,
    showCancelButton: props.cancelBtn ?? true,
    onOpen: () => {
      if (props.EnabledDisabled !== undefined) {
        Swal.disableConfirmButton();
      } else {
        Swal.enableConfirmButton();
      }
    },
    showConfirmButton: props.isDisabled ?? false,
    focusConfirm: false,
    reverseButtons: true,
    confirmButtonColor: "#28a745",
    cancelButtonColor: "#dc3545",
    confirmButtonText:
      '<span class="white">' + (props.TextOk ?? "OK") + "</span>",
    confirmButtonAriaLabel: "Thumbs up, OK",
    cancelButtonText: '<span class="white">Cancelar</span>',
    cancelButtonAriaLabel: "Thumbs down",
  }).then((result) => {
    if (result.value) {
      props.handlerEvent !== undefined
        ? props.e !== undefined
          ? props.handlerEvent(props.e)
          : props.handlerEvent()
        : Swal.close();
    }

    if (props.e === undefined) {
      setTimeout(() => {
        window.location.reload(false);
      }, 1500);
    }
  });
};
export const ShowAlertMessage = (textMessage, htmlBody, typeMessage) => {
  Swal.fire({
    type: typeMessage ?? "info",
    title: textMessage,
    html: htmlBody,
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp",
    },
  });
};
export const ShowConfirmationMessage = (
  handlerEvent,
  withIcon,
  param = undefined,
  optionalText = undefined
) => {
  Swal.fire({
    title: "Seguro?",
    text: optionalText !== undefined ? optionalText : "Desea continuar?",
    type: withIcon ?? "warning",
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Ok",
    cancelButtonText: "Cancelar",
  }).then((result) => {
    if (result.value ?? false) {
      if (param !== undefined) {
        handlerEvent(param);
      } else {
        handlerEvent();
      }
    }
  });
};
export const ShowInfoMessage = (htmlBody) => {
  Swal.fire({
    position: "top-center",
    html: htmlBody,
    showConfirmButton: false,
    timer: 3000,
  });
};
export const GetImagePatch = (ulrPatch) => {
  let defaultUrl = "/images/yo.jpg";
  try {
    const src = require("../../public" + ulrPatch);
    return ulrPatch;
  } catch (err) {
    return defaultUrl;
  }
};

export const GetDateDiff = (first, second) => {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
};
export const numberWithCommas = (strNumb) => {
  return strNumb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};
export const onlyNumberFormmat = (evt) => {
  var keyCode = evt.which ? evt.which : evt.keyCode;
  if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) return false;
  return true;
};
export const GetRandonNumber = (max) => {
  if (max === undefined) {
    max = 1000;
  }
  return Math.floor(Math.random() * (max - 1 + 1));
};
export const GetGuiId = (maxLength) => {
  if (maxLength === undefined) {
    maxLength = 8;
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16).replace("-", "").substr(0, maxLength);
  });
};

export const ShowConfirmationStatus = (
  handlerEvent,
  withIcon,
  param = undefined,
  name,
  title
) => {
  Swal.fire({
    title: title ? "SEGURO?" : title,
    text: "DESEA CAMBIAR EL ESTADO " + name + "?",
    type: withIcon ?? "warning",
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Cambiar",
    cancelButtonText: "Cancelar",
  }).then((result) => {
    if (result.value ?? false) {
      if (param !== undefined) {
        handlerEvent(param);
      } else {
        handlerEvent();
      }
    }
  });
};
