import React, { useState, useEffect } from "react";
import Button from "../Button/Button";
import DatePicker, { registerLocale } from "react-datepicker";
import $ from 'jquery';
import Moment from "moment";
import es from "date-fns/locale/es";
import "moment/locale/es";
import { getUser } from "../../utils/Common";
import API from "../../utils/api";
import Loading from "../loading/loading";
import { ShowConfirmationMessage, MessageResults, ShowPopUp } from "../../utils/CommonFunctions";
import { DataTable } from 'datatables.net';
import { LangSpanish } from "../../tools/dataTables.Language";


registerLocale("es", es);

$(document).ready(() => {
    $('body').on('click', '#TblPermisoAdmin #btViewInfo', function () {
        let dataItem = JSON.parse(atob($(this).attr('data-item')))[0];
        let tableData = "<br /><div class='container d-flex flex-column'><hr />" +
            "<div class='form-group'>" +
            "<div class='divLeft'><label for='tbName'>Nombre:</label></div>" +
            "<div class='input-group'>" +
            "<div class='input-group-prepend'>" +
            "<div class='input-group-text divSecond'><i class='fa fa-user-circle'></i></div>" +
            "</div>" +
            "<input type='text' class='form-control capitalized' id='tbName' title='Correo: " + dataItem.Email + ", Tel:(" + dataItem.Phone + ") (ext: " + dataItem.Extention + "') value='" + dataItem.EmployeeFullName + (dataItem.Department === null ? "" : " (" + dataItem.Department + ")") + "' readonly />" +
            "</div>" +
            "</div>" +
            "<div class='form-group'>" +
            "<div class='divLeft'><label for='tbDateSol'>Fecha&nbsp;Solicitud:</label></div>" +
            "<div class='input-group mb-2'>" +
            "<div class='input-group-prepend'>" +
            "<div class='input-group-text divSecond'><i class='fa fa-calendar'></i></div>" +
            "</div>" +
            "<input type='text' class='form-control' id='tbDateSol' value='" + (dataItem.CreationDate === null ? "-" : dataItem.CreationDate) + "' readonly />" +
            "</div>" +
            "</div>" +
            "<div class='form-row'>" +
            "<div class='form-group col-md-6'>" +
            "<div class='divLeft'><label for='tbDtFrom'>Desde:</label></div>" +
            "<div class='input-group mb-2'>" +
            "<div class='input-group-prepend'>" +
            "<div class='input-group-text divSecond'><i class='fa fa-calendar'></i></div>" +
            "</div>" +
            "<input type='text' class='form-control' id='tbDtFrom' value='" + (dataItem.DateStart === null ? "-" : dataItem.DateStart) + "' readonly />" +
            "</div>" +
            "</div>" +
            "<div class='form-group col-md-6'>" +
            "<div class='divLeft'><label for='tbDtTo'>Hasta:</label></div>" +
            "<div class='input-group mb-2'>" +
            "<div class='input-group-prepend'>" +
            "<div class='input-group-text divSecond'><i class='fa fa-calendar'></i></div>" +
            "</div>" +
            "<input type='text' class='form-control' id='tbDtTo' value='" + (dataItem.DateEnd === null ? "-" : dataItem.DateEnd) + "' readonly />" +
            "</div>" +
            "</div>" +
            "</div>" +
            "<div class='form-group'>" +
            "<div id='divRdo' data-item='" + dataItem.EmployeePermissionId + "' data-nbr='" + dataItem.EmployeeNumber + "' class='form-check permiso-square-radio  container d-flex align-items-center justify-content-center'>" +
            "<input class='form-check-input' type='radio' name='permisoRdo' id='rdAproved' value='3'>" +
            "<label class='form-check-label' for='rdAproved'>Aprobar</label>&nbsp;&nbsp;" +
            "<input class='form-check-input' type='radio' name='permisoRdo' id='rdDeclined' value='4'>" +
            "<label class='form-check-label' for='rdDeclined'>Declinar</label>" +
            "</div>" +
            "</div><hr />" +
            "</div>";

        ShowPopUp({ titleName: "Aprobar Solicitud", htmlBody: tableData, handlerEvent: OnClickSave, TextOk: "Guardar", isDisabled: true, EnabledDisabled: true })
        $("#divRdo.permiso-square-radio input[type='radio']").click((e) => {
            let contrlRdo = $(e.currentTarget).parent();
            let btnOk = $(".swal2-confirm.swal2-styled");
            if ($(contrlRdo).find("input[type='radio']:checked").length) {
                $(btnOk).attr('disabled', false);
            } else {
                $(btnOk).attr('disabled', true);
            }

        })
    })
    const OnClickSave = () => {
        let param = {
            EmployeePermissionId: parseInt($("#divRdo").attr('data-item')),
            permissionRequestStatusId: parseInt($("#divRdo.permiso-square-radio  input[type='radio']:checked").val()),
        };
        ShowConfirmationMessage(SaveChanges, '', param);
    }
    const SaveChanges = (params) => {
        var isApprove = params.permissionRequestStatusId == 3 ? true : false;
        API.putData("EmployeePermision/setPermiso?requestId=" + params.EmployeePermissionId + "&isApprove=" + isApprove)
            .then((res) => {
                if (res.status === 200) {
                    MessageResults(res.status);
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 1200);
                }
            })
            .catch(function (err) {
                console.error("Error de conexion " + err);
                MessageResults(400, err);
            });
    }

})

const EmployeePermissionAdmin = (props) => {
    const [dataLoading, setDataLoading] = useState(true);

    async function fillData() {
        let Record = [];
        var companyId = Number(getUser().companyId)
        await API.getData("/EmployeePermision/GetbySupervisorNumber?employeeNumber=" + getUser().employeeNumber + "&companyId=" + companyId)
            .then((res) => {
                setDataLoading(false);
                if (res.status === 200) {
                    let dataResult = [];
                    res.data.forEach(item => {
                        let EmployeeData = JSON.parse(item.employeeData)
                        let EmployeeItemData = [];
                        EmployeeData.forEach(element => {
                            if (element.EmployeeNumber === item.employeeNumber) {
                                EmployeeItemData.push({
                                    EmployeePermissionId: item.id,
                                    EmployeeFullName: element.EmployeeFullName,
                                    Department: (typeof (element.Department) === "string" ? element.Department.toLowerCase() : ""),
                                    Email: (typeof (element.Email) === "string" ? element.Email.toLowerCase() : ""),
                                    Phone: element.Phone,
                                    Extention: element.Extention,
                                    CreationDate: Moment(item.dateStart).format("DD/MM/YYYY"),
                                    DateStart: Moment(item.dateStart).format("DD/MM/YYYY"),
                                    DateEnd: Moment(item.dateEnd).format("DD/MM/YYYY"),
                                    EmployeeNumber: element.EmployeeNumber,
                                });
                            }
                        });
                        dataResult.push({
                            id:
                                '<span class="container d-flex align-items-center justify-content-center">' +
                                item.id +
                                "</>",
                            name:
                                '<span class="capitalized defaultText">' +
                                item.name +
                                "</span>",
                            employeeNumber:
                                '<span class="capitalized defaultText">' +
                                item.employeeNumber +
                                "</span>",
                            typePermission:
                                '<span class="capitalized defaultText">' +
                                item.typePermission +
                                "</span>",
                            description:
                                '<span class="capitalized defaultText">' +
                                item.comment +
                                "</span>",
                            dateStart:
                                '<span class="capitalized defaultText">' +
                                Moment(item.dateStart).format("DD/MM/YYYY  ") +
                                "</span>",
                            dateEnd:
                                '<span class="capitalized defaultText">' +
                                Moment(item.dateEnd).format("DD/MM/YYYY  ") +
                                "</span>",
                            statusCard:
                                '<span class="capitalized defaultText ' +
                                item.statusEmployeePermissionId.cssClass +
                                '">' +
                                item.statusEmployeePermissionId.statusMedicalName +
                                "</span>",
                            status: '<span class="defaultText ' + (item.statusEmployeePermissionId !== null ? item.statusEmployeePermissionId.cssClass : "") + '">' + (item.statusEmployeePermissionId !== null ? item.statusEmployeePermissionId.statusMedicalName : "") + '</span>',
                            itemBtn: (item.statusEmployeePermissionId !== null ? (item.statusEmployeePermissionId.cssClass === "status-requested" ? "<a href='#' id='btViewInfo'  data-item=" + btoa(JSON.stringify(EmployeeItemData)) + " class='fa fa-pencil-square-o custom-color size-effect-x2' title='Editar' ></a>" : "") : "")
                        });
                    });
                    $("#TblPermisoAdmin").DataTable({
                        destroy: true,
                        searching: true,
                        language: LangSpanish,
                        lengthMenu: [8, 20, 40, 60, 80, 90, 100, 200],
                        "order": [[0, "desc"]],
                        data:
                            dataResult.length === 0
                                ? [
                                    {
                                        name: "",
                                        description: "",
                                        dateStart: "",
                                        dateEnd: "",
                                        typePermission: "",
                                        statusCard: "",
                                        status: '',
                                        itemBtn: ''
                                    },
                                ]
                                : dataResult,
                        columns: [
                            {
                                data: "name",
                                title: "Nombre",
                                width: "20%",
                                className: "capitalized",
                            },
                            {
                                data: "dateStart",
                                title: "Desde",
                                width: "20%",
                                className: "capitalized",
                            },
                            {
                                data: "dateEnd",
                                title: "Hasta",
                                width: "20%",
                                className: "capitalized",
                            },
                            {
                                data: "typePermission",
                                title: "Tipo de Permiso",
                                width: "20%",
                                className: "capitalized",
                            },
                            {
                                data: "description",
                                title: "Comentario",
                                width: "20%",
                                className: "capitalized",
                            },
                            {
                                data: "statusCard",
                                title: "Estado",
                                width: "20%",
                                className: "capitalized",
                            },
                            { data: "itemBtn", title: "", width: '2%', orderable: false },
                        ]

                    });
                    $('.csHidden').attr('style', 'display:none');


                }
            })
            .catch(function (err) {
                console.error("Error de conexion " + err);
            });
        setDataLoading(false);

    }

    useEffect(() => {
        fillData();

    }, []);

    return (
        <>
            <div>
                <br />
                <div className=" htmlPayroll table-responsive container">
                    <section>
                        <br />
                        <div className="row">
                            <div className="col-12">
                                <br />
                                <h2 className="h2" Style={"text-align:center"}>
                                    APROBAR&nbsp;PERMISOS
                                </h2>
                                <hr />
                            </div>
                        </div>
                    </section>
                    <div className=" table-responsive">
                        {!dataLoading ?
                            <>
                                <div className="container">
                                    <div className="">
                                        {!dataLoading ?
                                            <div className="table-responsive" Style="min-height:600px">
                                                <table id="TblPermisoAdmin" className="table table-striped table-bordered" Style="width:100% !important"></table>
                                            </div>
                                            :
                                            <Loading />
                                        }

                                    </div>
                                </div>
                            </>

                            :
                            <div className="container">
                                <Loading />
                            </div>

                        }
                    </div>


                    <br />
                    <br />

                </div>
                <br />
                <br />
            </div>


        </>
    )
}
export default EmployeePermissionAdmin;
