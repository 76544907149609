import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import queryString from "query-string";
import { getUser, removeUserSession } from "../../utils/Common";

export default function MyBenefitsImg(props) {
  const [benefitsImg, setBenefitsImg] = useState([]);
  const id = queryString.parse(props.location.search).id;
  useEffect(() => {
    API.getData(
      "CompanyBenefits/getbyCategory?id=" +
        id +
        "&companyId=" +
        getUser().companyId
    )
      .then((response) => {
        if (response.status === 200) {
          setBenefitsImg(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <section className="wrapper">
        <div className="inner">
          <div className="row">
            {benefitsImg.map((item) => (
              <div className="col-sm  mb-4">
                <center>
                  <a
                    key={item}
                    href={"/benefitsGeneric?id=" + item.id}
                    className="icon most-visited-log"
                    data-prefix={item.company
                      .trim()
                      .replace(" ", "")
                      .replace(" ", "")}
                  >
                    <img
                      src={item.imageMenu}
                      className="img-fluid"
                      alt="Responsive image"
                    />

                    <br />
                    <br />
                    <h5>{item.company}</h5>
                  </a>
                </center>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
