import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import Button from "../Button/Button";
import "./myrequests.css";
import { getUser, removeUserSession } from "../../utils/Common";
import API from "../../utils/api";
import RequestsResume from "./myrequestsResume";
import OpenRequestsList from "./myrequestsList";
import MakeRequestsServices from "./makeMyRequests";

const MyRequestServices = ({ userInfo }) => {
  const [bodyRequest, setListBodyRequest] = useState([]);

  const GetSignLetter = () => {
    API.getData("StaffLetterSign/GetFirstByOrderPriority?companyId=" + getUser().companyId)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("signData", JSON.stringify(res.data));
        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
  };
  const ResumePage = () => {
    return (
      <>
        <div className=" htmlMyrequests table-responsive container">
          <RequestsResume />
          <div className="row">
            <div className="col-lg-10 col-md-10 col-sm-8"></div>
            <div className="col-lg-2 col-md-2 col-sm-4">
              <div className="mb-2">
                <Button
                  type="btnRequest btn-outline-danger text-wrap most-visited-log"
                  data_prefix="myrequest/solicitarcarta"
                  text="Solicitar Carta"
                  clickHandler={onButtonClick}
                />
              </div>
            </div>
            <OpenRequestsList />
          </div>
        </div>
      </>
    );
  };
  const GetScreen = (props) => {
    switch (props) {
      case "ResumePage":
        setListBodyRequest(ResumePage());
        break;
      case "MakeRequestsPage":
        GetSignLetter();
        setListBodyRequest(<MakeRequestsServices />);
        break;

      default:
        setListBodyRequest(ResumePage());
        break;
    }
  };
  const onButtonClick = () => {
    GetScreen("MakeRequestsPage");
  };

  useEffect(() => {
    GetScreen("ResumePage");
  }, []);

  return (
    <>
      <div>{bodyRequest}</div>
    </>
  );
};

export default MyRequestServices;
