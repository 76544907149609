import React, { useState, useEffect } from "react";
import { getUser } from "../../utils/Common";
import {
  Container,
} from "@material-ui/core"
import moment from "moment";
import Moment from "moment"
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { addPermission, getPermitType } from "../action/permissionAction";

const now = moment().minutes(0).seconds(0).add(1, "hours");
const nowPlus1 = now.clone().add(1, "days");

const initEvent = {
  name: "",
  employeeNumber: "",
  typePermission: "",
  comment: "",
  dateStart: now.toDate(),
  dateEnd: nowPlus1.toDate(),
};

export default function AddEmployeePermission(props) {
  const [formValues, setFormValues] = useState(initEvent);
  const [dateStart1, setDateStart] = useState(now.toDate());
  const [dateEnd1, setDateEnd] = useState(nowPlus1.toDate());
  const [titleValid, setTitleValid] = useState(true);
  const [permit, setPermit] = useState([{}]);

  const { comment, dateStart, dateEnd, typePermission } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleStartDateChange = (e) => {
    setDateStart(e);
    setFormValues({
      ...formValues,
      start: e,
    });
  };

  const handleEndDateChange = (e) => {
    setDateEnd(e);
    setFormValues({
      ...formValues,
      end: e,
    });
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const momentStart = moment(dateStart);
    const momentEnd = moment(dateEnd);

    if (momentStart.isSameOrAfter(momentEnd)) {
      return Swal.fire(
        "Error",
        "La fecha fin debe de ser mayor a la fecha de inicio",
        "error"
      );
    }

    if (comment.trim().length < 2) {
      return setTitleValid(false);
    }

    formValues.dateStart = Moment(dateStart1).format("YYYY-MM-DD");
    formValues.dateEnd = Moment(dateEnd1).format("YYYY-MM-DD");
    formValues.name = getUser().firstName + " " + getUser().lastName;
    formValues.employeeNumber = getUser().employeeNumber;

    var result = addPermission(formValues);
    setTimeout(() => {
      props.history.push('/employeePermission');
    }, 1500);

    setTitleValid(true);
  };

  useEffect(() => {
    const getListPermit = async () =>{
      const response = await getPermitType();
      setPermit(response.data);
  }
    getListPermit();
  }, [])


  return (
    <Container className="container">
      <div className="row">
        <div className="col-12">
          <br />
          <h2 className="h2" Style={"text-align:center"}>
            &nbsp;SOLICITAR&nbsp;PERMISO
          </h2>
          <hr />
        </div>
      </div>
      <form className="container" onSubmit={(e) => e.preventDefault()}>
        <div className="row">
          <div className="col-12">
            <div className="input-group-prepend">
              <div className="input-group-text mb-2">
                Seleccione&nbsp;el&nbsp;rango&nbsp;de&nbsp;fecha&nbsp;de&nbsp;su&nbsp;permiso
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">Desde:</div>
              </div>
              <DatePicker
                id="dtFrom"
                className=" reactCalendar"
                relativeSize={true}
                popperPlacement="auto-right"
                selected={dateStart1}
                locale="es"
                dateFormat="dd/MM/yyyy"
                onChange={handleStartDateChange}
                peekNextMonth
                showMonthDropdown               
                dropdownMode="select"
                useShortMonthInDropdown
              />
              <div className="input-group-prepend calendarCs">
                <div className="notAllowed">
                  <span
                    className="iconify"
                    data-icon="fa:calendar"
                    data-inline="false"
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-group mb-2" >
              <div className="input-group-prepend">
                <div className="input-group-text">Hasta:&nbsp;</div>
              </div>
              <DatePicker
                className="reactCalendar"
                relativeSize={true}
                popperPlacement="auto-right"
                id="dtTo"
                selected={dateEnd1}
                locale="es"
                dateFormat="dd/MM/yyyy"
                minDate={dateStart1}
                onChange={handleEndDateChange}
                peekNextMonth
                showMonthDropdown               
                dropdownMode="select"
                useShortMonthInDropdown
              />
              <div className="input-group-prepend calendarCs">
                <div className="notAllowed">
                  <span
                    className="iconify"
                    data-icon="fa:calendar"
                    data-inline="false"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="form-group">
          <label className="classspan text-left">Nombre Completo</label>
          <input
            type="text"
            className="form-control"
            value={getUser().firstName + " " + getUser().lastName}
            name="name"
            onChange={handleInputChange}
            readOnly="true"
          />
        </div>
        <div className="form-group">
          <label className="classspan text-left">Tipo de Permiso</label>
          <select className="form-select" name="typePermission" value={typePermission} onChange={handleInputChange}>
              <option>Seleccione...</option>
              {
                permit.map(data => (
                    data.active === 1 ?(
                      <option key={data.id} value={data.description}>{data.description}</option>
                    ):null
                ))
              }
          </select>
        </div>
        <div className="form-group">
          <label className="classspan text-left">Comentario</label>
          <textarea
            type="text"
            className={`form-control ${!titleValid && "is-invalid"}`}
            placeholder="Comentario"
            rows="5"
            name="comment"
            value={comment}
            onChange={handleInputChange}
            style={{
              resize: "none",
            }}
          ></textarea>
        </div>

        <button type="submit" className="btn btn-danger btn-block" onClick={handleSubmitForm}>
          <i className="fa fa-save" style={{color:'white'}}></i>
          <span style={{color:'white'}}> Guardar</span>
        </button>
      </form>
    </Container>
  );
}
