import React, { useState, useEffect } from 'react'
import API from '../../utils/api';
import queryString from 'query-string';
import { getUser, removeUserSession } from "../../utils/Common";

export default function UnitGeneric(props) {

    const [unitInfo, setUnitInfo] = useState([]);
    const id = queryString.parse(props.location.search).id;
    const name = queryString.parse(props.location.search).name;
    useEffect(() => {
        API.getData('BusinessUnit/getByUniType?id='+id + "&companyId=" + getUser().companyId ).then(response => {
            setUnitInfo(response.data);
        }).catch(error => {
            console.log(error);
        });
    },[]);

    return ( 
        <section id="main" className="wrapper">
            <div className="inner">
                <h3 Style={"text-align:center"}>{name}</h3>
                <br/>
                <br/>
                <div className="content">
                    {
                        unitInfo.map(item => (
                            <>
                            <br/>
                                {name == "UNIDADES DE NEGOCIOS" ?
                                <header> 
                                    <img src={"/images/units/"+item.logo} style={{width: "157px"}} alt="Pic01" />
                                </header> :
                                <header> 
                                    <img src={"/images/units/"+item.logo} style={{width: "288px"}} alt="Pic01" />
                                </header>
                                }
                                <br/>
                                <div key={item} dangerouslySetInnerHTML={{ __html: item.detail }} />
                            </>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}