import React, { useState, useEffect } from "react";
import { GetImagePatch } from '../../utils/CommonFunctions';
import API from "../../utils/api";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { formatDate } from '@fullcalendar/react';
import Swal from 'sweetalert2'
import { getUser } from "../../utils/Common";


export default function MonthBirthdate(props) {
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        API.getData("/Employees/getMonthBirthdate?company_id="+getUser().companyId)
            .then((res) => {
                if (res.status === 200) {
                    let dataResult = [];
                    let dataFinalResult = [];
                    const map = new Map();

                    res.data.forEach((itemData) => {
                        const curDate = itemData;
                        let counter = 0;
                        res.data.forEach(item => {
                            if(curDate.date === item.date){
                                counter ++;
                            }
                        });
                        
                        dataResult.push({date: curDate.date, title: counter});
                    });
                    
                    for (const item of dataResult) {
                        if(!map.has(item.date, item.title)){
                            map.set(item.date, true); 
                            dataFinalResult.push({
                                date: item.date,
                                title:   item.title
                            });
                        }
                    }                    
                    setEvents(dataFinalResult);
                }
            })
            .catch(function (err) {
                console.error("Error de conexion " + err);
            });

    }, []);
      function handleEventClick(info){
          API.getData("/Employees/getBirthdateByMonthAndDate?datetime="+info.event.startStr+"&company_id="+getUser().companyId)
          .then((res) => {
              if (res.status === 200) {
                  var d = "<div class='swMonthbthd container col-12 d-flex align-items-center justify-content-center'><div class='row' style='width: 45rem;'>";  
                  res.data.map((item) => (
                    d += `<section class="col-lg-4 col-md-4 col-sm-6">
                                <div class="inner">
                                    <div class="container">    
                                        <section key=${item.id}>
                                            <div class="content2">
                                                <header>
                                                    <img src=${GetImagePatch("/images/employee_photo/"+ item.employeeIdCard+".jpg")} width="110px" alt="foto" />
                                                    <h6>${item.firstName+" "+item.lastName}</h6>
                                                    <p class="defaultText">${item.department ?? item.divisionName}</p>
                                                </header>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </section>`
                  ));
                  Swal.fire({
                    width: 700,
                    confirmButtonText: 'Cerrar',
                    html: d + "</div></div>"
                  })
              }
          })
          .catch(function (err) {
              console.error("Error de conexion " + err);
          });
       
      }
    return (
        <div>
            <section className="container d-flex align-items-center justify-content-center ">
                <div className="inner">
                <br />
                    <br />
                    <h2 className="h2">Cumpleaños&nbsp;del&nbsp;Mes</h2>
                    <hr />                    
                    <section>
                        <div className="row">
                            <div className=" col-12">
                            <div className="input-group-prepend">
                                <div className="text-wrap textInfo mb-2 container d-flex align-items-center justify-content-center">
                                <span>
                                    <i className="fa fa-exclamation-triangle faa-flash animated"></i>
                                    &nbsp;Click sobre el dia del mes para visualizar los cumpleañeros
                                </span>
                                </div>
                            </div>
                            </div>
                        </div>           
                    </section>
                    <FullCalendar
                        locale= 'es'
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        headerToolbar={false}
                        height ="560px"
                        events={events}
                        selectable={true}
                        eventClick={handleEventClick}
                    />
                </div>
            </section>
        </div>
    );
}
