import React from "react";
import $ from "jquery";
import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";
export default function Contacto() {
  const RedirectPage = (e) => {
    let url = $(e.currentTarget).attr("dataitem");
    setTimeout(() => {
      window.location = url;
    }, 300);
  };

  return (
    <>
      <div>
        <section className="container">
          <div className="container">
            <br />
            <div className="highlights">
              <section>
                <div className="content2">
                  <header>
                    <a
                      href={GetCssByCompany().emailSupport}
                      onClick={RedirectPage}
                      target="_blank"
                      className="icon most-visited-log"
                    >
                      <span
                        className="iconify iconifysize color-Icon size-effect"
                        data-icon="ion-ios-chatbubbles"
                        data-inline="false"
                      ></span>
                    </a>
                    <br />
                    <br />
                    <h6>Chat de Soporte G&G</h6>
                    <br />
                    <br />
                  </header>
                </div>
              </section>
              <section>
                <div className="content2">
                  <header>
                    <a href="/faq" className="icon most-visited-log">
                      <span
                        className="iconify iconifysize  color-Icon size-effect"
                        data-icon="ion-ios-help-buoy"
                        data-inline="false"
                      ></span>
                    </a>
                    <br />
                    <br />
                    <h6>Preguntas Frecuentes</h6>
                    <br />
                    <br />
                  </header>
                </div>
              </section>
              <section>
                <div className="content2">
                  <header>
                    <a href="/suggestion" className="icon most-visited-log">
                      <span
                        className="iconify iconifysize  color-Icon size-effect"
                        data-icon="ion-ios-archive"
                        data-inline="false"
                      ></span>
                    </a>
                    <br />
                    <br />
                    <h6>Buzón de sugerencia </h6>
                    <br />
                    <br />
                  </header>
                </div>
              </section>
              <section>
                <div className="content2">
                  <header>
                    <a href="/anonymeline" className="icon most-visited-log">
                      <span
                        className="iconify iconifysize  color-Icon size-effect"
                        data-icon="ion-ios-mail"
                        data-inline="false"
                      ></span>
                    </a>
                    <br />
                    <br />
                    <h6>Linea Anonima</h6>
                    <br />
                    <br />
                  </header>
                </div>
              </section>
              <section>
                <div className="content2">
                  <header>
                    <a href="/Directory" className="icon most-visited-log">
                      <span
                        className="iconify iconifysize  color-Icon size-effect"
                        data-icon="ion-ios-book"
                        data-inline="false"
                      ></span>
                    </a>
                    <br />
                    <br />
                    <h6>Directorio</h6>
                    <br />
                    <br />
                  </header>
                </div>
              </section>

              <section>
                <div className="content2">
                  <header>
                    <a href="/ticketGyG" className="icon most-visited-log">
                      <span
                        className="iconify iconifysize  color-Icon size-effect"
                        data-icon="typcn-ticket"
                        data-inline="false"
                      ></span>
                    </a>
                    <br />
                    <br />
                    <h6>Ticket de asistencia gyg</h6>
                    <br />
                    <br />
                  </header>
                </div>
              </section>
              <section>
                <div className="content2">
                  <header>
                    <a href="/ticket" className="icon most-visited-log">
                      <span
                        className="iconify iconifysize  color-Icon size-effect"
                        data-icon="typcn-ticket"
                        data-inline="false"
                      ></span>
                    </a>
                    <br />
                    <br />
                    <h6>Consulta tu ticket MDA</h6>
                    <br />
                    <br />
                  </header>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
