import firebase from 'firebase';
import 'firebase/storage';
/*
const firebaseConfig = {
    apiKey: "AIzaSyCyqvNDnO6DvxxUjCBlI6baPpdtCvY3kRw",
    authDomain: "appfileb2e.firebaseapp.com",
    projectId: "appfileb2e",
    storageBucket: "appfileb2e.appspot.com",
    messagingSenderId: "228540096620",
    appId: "1:228540096620:web:6184a154ceb2dc42fc3849",
    measurementId: "G-N7ZNSQ5QCE"

  };*/
  const firebaseConfig = {
   apiKey: "AIzaSyDbHOQbUyU153rpgmdPOgtP-3rQ9C_Tx9U",
  authDomain: "corripioempleados-67953.firebaseapp.com",
  projectId: "corripioempleados-67953",
  storageBucket: "corripioempleados-67953.appspot.com",
  messagingSenderId: "432654884893",
  appId: "1:432654884893:web:3e7f67379a0be3480226f4",
  measurementId: "G-QVL0QFTN95"
};
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
 }else {
    firebase.app(); // if already initialized, use that one
 }

  var storage = firebase.storage();
  export default storage;