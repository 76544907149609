import React from 'react'
import VacationDetails from '../../components/vacations/vacationDetails';
import { getUser } from '../../utils/Common';


export default function vacations(props) {
    const usre = getUser();
    
    return (
        <div>
             <VacationDetails User={usre} />
        </div>
    )
}