import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import queryString from "query-string";
import { GetCssByCompany } from "../../CssbyCompany/CommonCSS";

export default function BenefitsGeneric(props) {
  const [benefitsGeneric, setBenefitsGeneric] = useState([]);
  const id = queryString.parse(props.location.search).id;

  useEffect(() => {
    API.getData("CompanyBenefits/getbyid?id=" + id)
      .then((response) => {
        if (response.status === 200) {
          setBenefitsGeneric(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section id="main card-benefit-default">
      <div className="container">
        <br />
        <br />
        <section>
          <center>
            <div className="">
              {benefitsGeneric.map((item) => (
                <header>
                  <div
                    className=" container d-flex align-items-center justify-content-center card card-benefit"
                    Style="width: 30rem;"
                  >
                    <div className="text-left">
                      <br />
                      <div
                        className="d-flex align-items-center justify-content-center"
                        Style={
                          "background-color:" + GetCssByCompany().PrimaryColor
                        }
                      >
                        <span className="title-benefit-upper">
                          {item.company}
                        </span>
                        <hr />
                      </div>
                      <div className="">
                        <img
                          Style="width: 125%; max-height: 200px;"
                          src={item.banner}
                          className="img-fluid"
                          alt="companía"
                        />
                      </div>
                      <br />
                      <br />

                      <h6 className="red">Ahorro</h6>
                      <p>{item.saving}</p>
                      <hr />
                      <h6 className="red">
                        <i class="fa fa-phone"></i>&nbsp;Contacto
                      </h6>
                      <p>{item.contactName}</p>
                      <p>{item.contactPhone}</p>
                      <hr />
                      <h6 className="red">
                        <i class="fa fa-map-marker"></i>&nbsp;Dirección
                      </h6>
                      <p>{item.address}</p>
                      <hr />
                      <h6 className="red">
                        <i class="fa fa-chrome"></i>&nbsp;Página web
                      </h6>
                      <p>
                        <a href={item.webAddress}>{item.webAddress}</a>
                      </p>
                    </div>
                    <br />
                  </div>
                </header>
              ))}
            </div>
          </center>
        </section>
      </div>
    </section>
  );
}
