import React, { useState } from "react";
import API from "../../utils/api";
import { getUser } from "../../utils/Common";
import {
  ShowAlertMessage,
  MessageResults,
  ShowInfoMessage,
} from "../../utils/CommonFunctions";
import $ from "jquery";
import Moment from "moment";
import { GetImagePatch } from "../../utils/CommonFunctions";

export default function AddpromotionBurgerKing(props) {
  const [promocion, setPromocion] = useState(true);
  const [promocionReward, setPromocionReward] = useState(true);
  const Consultarpromotion = async () => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let fecha = "" + year + "-0" + month + "-" + day + "";

    await API.getData(
      "PromotionDay/get?id=1&cedula=" +
        employeeIdCard.value +
        "&companyId=1&dateNow=" +
        fecha +
        ""
    )
      .then((res) => {
        let respuesta = res.data.data;
        if (respuesta == "No se encontro promocion en esa fecha") {
          ShowInfoMessage("La promoción está fuera de fecha");
        } else if (res.data.messasge) {
          if (res.data.messasge == "Usted ya uso esta promocion") {
            ShowInfoMessage("El colaborador consumió esta promoción");
          } else if (
            res.data.messasge == "No existe ningun empleado con esta cedula"
          ) {
            ShowInfoMessage(
              "No existe colaborador con este registro de documento"
            );
          }
        } else {
          console.error(res.data);
          setPromocion(res.data[0]);
          setPromocionReward(res.data[0].reward[0]);
        }
      })
      .catch(function (err) {
        ShowAlertMessage(
          "Información",
          "Hubo un problema intente de nuevo",
          "error"
        );
        console.error("Error de conexion " + err);
      });
  };

  const Savepromotion = async () => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let fecha = "" + year + "-0" + month + "-" + day + "";
    if (employeeIdCard.value != "" && promocionReward.reward != undefined) {
      API.postData("PromotionDay/add", {
        id_promotion: 1,
        identification_card: employeeIdCard.value,
        date_used: fecha.toString(),
        award_name: promocionReward.reward,
      })
        .then((response) => {
          MessageResults(response.status);

          setTimeout(() => {
            window.location.reload(true);
          }, 1200);
        })
        .catch((error) => {
          ShowAlertMessage(
            "Información",
            "Hubo un problema intente de nuevo",
            "error"
          );
          console.log(error);
        });
    } else {
      ShowInfoMessage("Validar cédula y campo de regalo no esté vacio");
    }
  };

  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };

  const employeeIdCard = useFormInput("");

  return (
    <div className="container">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12">
            <br />
            <h3 className="text-center">Promoción Burger King Día del padre</h3>
            <center>
              <div class="form-group col-md-6">
                <br />
                <img
                  src={GetImagePatch("/images/BurgerKing.jpg")}
                  id="output1"
                  className="img-fluid"
                />
              </div>
            </center>
            <br />
            <br />
            <div className="row">
              <div className="form-group col-md-12">
                <label className="control-label">Cédula del colaborador</label>
                <input
                  type="number"
                  className={`form-control  `}
                  {...employeeIdCard}
                />
              </div>
            </div>
          </div>
          <br />
        </div>
        <center>
          <div className="form-group col-md-6 ">
            <button
              type="button"
              className="mybt btn btn-outline-danger text-wrap"
              onClick={Consultarpromotion}
            >
              Consultar Colaborador
            </button>
          </div>
        </center>

        <section>
          <div className="content2">
            <header>
              <h6>{promocion.nombre}</h6>

              <hr />
              <h6>
                <b>Fecha validez</b>
              </h6>
              <p>{Moment(promocion.to).format("DD-MM-YYYY")} </p>
              <hr />
              <h6>
                <b>Regalo de la empresa</b>
              </h6>
              <p>{promocionReward.reward} </p>
            </header>
          </div>
        </section>
        <center>
          <div className="form-group col-md-2 ">
            <button
              type="button"
              className="mybt btn btn-outline-danger text-wrap"
              onClick={Savepromotion}
            >
              Guardar
            </button>
          </div>
        </center>
      </div>
    </div>
  );
}
