import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import queryString from "query-string";
import Moment from "moment";
import { getUser, setPolicieSession, removePolicieSession } from "../../utils/Common";
import {
  ShowPopUp,
  ShowConfirmationMessage,
  ShowAlertMessage,
} from "../../utils/CommonFunctions";

export default function PoliciesGeneric(props) {
  const [policies, setPolicies] = useState([]);
  const [Id, setId] = useState(queryString.parse(props.location.search).id);

  const name = queryString.parse(props.location.search).name;

  useEffect(() => {
    API.getData(
      "Policies/getbyid?id=" + Id + "&employee=" + getUser().employeeIdCard
    )
      .then((response) => {
        if(!response.data.leida){
          setPolicieSession()
        }
      
        setPolicies(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
 
  },[]);

 async function addPolicies() { 
  await  API.postData("EmployeesPolicies/add", {
      employeeNumber: getUser().employeeIdCard,
      policyId: parseInt(Id),
      id: 0,
      readingDate: Moment(new Date()).format("YYYY-MM-DD"),
      policies: null,
    })
      .then((response) => {
        //setTimeout(() => {removePolicieSession();  }, 200);
        removePolicieSession(); 
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>¡Política aceptada correctamente!</p>"
        );
        props.history.push("/policies");
      })
      .catch((error) => {
        ShowAlertMessage(
          "¡Mensaje de aviso!",
          " <p>Error al intentar aceptar la política intente de nuevo!</p>"
        );
      });
           
  };

  return (
    <section id="main" className="wrapper">
      <div className="inner">
        <center>
          <h4>{name}</h4>
          <p className="text-left">
            Publicado el {Moment(policies.creationDate).format("DD-MM-YYYY")}
          </p>
          {policies.leida ? (
            <p className="text-right text-success">
              Leída y Aceptada en Fecha{" "}
              {Moment(policies.fechaLeida).format("DD-MM-YYYY")}
            </p>
          ) : (
            <></>
          )}
        </center>

        <div className="content">
          {<div dangerouslySetInnerHTML={{ __html: policies.content }} />}
        </div>
        {policies.leida ? (
          <></>
        ) : (
          <center>
            <div className="form_group ">
              <button
                type="submit"
                onClick={addPolicies}
                className=" btn btn-success border-white"
              >
                <h6 className="text-white">
                  <span
                    className="iconify"
                    data-icon="ion:chevron-down-circle-sharp"
                    data-inline="false"
                  ></span>{" "}
                  &nbsp; HE LEÍDO Y ACEPTO ESTA PÓLITICA
                </h6>
              </button>
            </div>
            <br />
            <br />
          </center>
        )}
      </div>
    </section>
  );
}
