import { ShowInfoMessage } from "../utils/CommonFunctions";

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the user data from the session storage
export const getBonification = () => {
  const userStr = sessionStorage.getItem("bonification");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.clear();
  console.clear();
};

export const removeBonification = () => {
  sessionStorage.setItem("bonification", JSON.stringify([]));
};

// set the token and user from the session storage
export const setUserSession = (token, user, requiredchangePSW, info) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
  sessionStorage.setItem("chkPs", requiredchangePSW);

  sessionStorage.setItem("bonification", JSON.stringify(info));
};

export const setPolicieSession = () => {

  localStorage.setItem(
    "PoliciePending",
    btoa(
      JSON.stringify({
        EmployeeNumber: getUser().employeeNumber,
        pathUrl: window.location.pathname + window.location.search,
      })
    )
  );
};
export const getPolicieSession = () => { 
  if (getUser() !== null) {
    if (localStorage.getItem("PoliciePending") !== null) {
      let dataResult = JSON.parse(atob(localStorage.getItem("PoliciePending")));
      if (
        dataResult.EmployeeNumber === getUser().employeeNumber &&
        window.location.pathname !== "/genericpolicies"
      ) {
        ShowInfoMessage(
          "<i class='fa fa-exclamation-triangle faa-flash animated red'></i>&nbsp;<span class='red'>¡Debe leer y aceptar Política!</span>"
        );
        setTimeout(() => {
          window.location.href = dataResult.pathUrl;
        }, 3500);
      }
    }
  }
};
export const setAdmSession = (token, user) => {
  sessionStorage.setItem("AdmToken", token);
  sessionStorage.setItem("AdmUser", btoa(JSON.stringify(user)));
  var logoutTimer = setTimeout(() => {
    removeAdmUser();
  }, 20 * 60 * 1000); //20min
};
export const removeAdmUser = () => {
  sessionStorage.removeItem("AdmToken");
  sessionStorage.removeItem("AdmUser");
};
export const getAdmUser = () => {
  const userStr = sessionStorage.getItem("AdmUser");
  if (userStr) return JSON.parse(atob(userStr));
  else return null;
};
export const getAdmToken = () => {
  return sessionStorage.getItem("AdmToken") || null;
};
export const removePolicieSession = () => {
  localStorage.removeItem("PoliciePending",btoa(
    JSON.stringify({
      EmployeeNumber: getUser().employeeNumber,
      pathUrl: window.location.pathname + window.location.search,
    })
  ));
};

export const getUserChangesSession = () => {
  if (getUser() !== null) {
    if (sessionStorage.getItem("chkPs") !== null) {
      let dataResult = sessionStorage.getItem("chkPs");
      let defaultPath = "/changepassword";
      if (dataResult === "true" && window.location.pathname !== defaultPath) {
        ShowInfoMessage(
          "<i class='fa fa-exclamation-triangle faa-flash animated red'></i>&nbsp;<span class='red'>¡Se requiere Cambio de Clave!</span>"
        );
        setTimeout(() => {
          window.location.href = "/changepassword";
        }, 3500);
      }
    }
  }
};

export const setEvaluationSession = () => {
  /*localStorage.setItem(
    "EvaluationPending",
    btoa(
      JSON.stringify({
        EmployeeNumber: getUser().employeeNumber,
        pathUrl: window.location.pathname + window.location.search,
      })
    )
  );*/
};
export const getEvaluationSession = () => {
  if (getUser() !== null) {
    debugger;
    if (localStorage.getItem("EvaluationPending") == "true") {
      ShowInfoMessage(
        "<i class='fa fa-exclamation-triangle faa-flash animated red'></i>&nbsp;<span class='red'>¡Debe Llenar la encuesta!</span>"
      );
    }
  }
};

export const removeEvaluationSession = () => {
  localStorage.removeItem("EvaluationPending");
};
