import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { DataTable } from 'datatables.net';
import { LangSpanish } from "../../tools/dataTables.Language";
import API from "../../utils/api";
import { getUser } from "../../utils/Common";
import {ShowAlertMessage} from "../../utils/CommonFunctions";
import Loading from "../loading/loading";

$(document).ready(()=>{
    $('body').on('click', '#TblContactDirectory #btViewInfo', function(){
        let dataItem = JSON.parse(atob($(this).attr('data-item')))[0];
        let tableData = "<br /><div class='container d-flex flex-column'>" +
                            "<div class='form-group'>" +
                                "<div class='divLeft'><label for='tbName'>Nombre:</label></div>" +
                                "<div class='input-group mb-2'>" +
                                    "<div class='input-group-prepend'>" +
                                    "<div class='input-group-text divSecond'><i class='fa fa-user-circle'></i></div>" +
                                    "</div>" +
                                    "<input type='text' class='form-control capitalized' id='tbName' value='"+ dataItem.name  +"' />" +
                                "</div>" +
                            "</div>" +
                            "<div class='form-group'>" +
                                "<div class='divLeft'><label for='tbDepartment'>Departamento:</label></div>" +
                                "<div class='input-group mb-2'>" +
                                    "<div class='input-group-prepend'>" +
                                    "<div class='input-group-text divSecond'><i class='fa fa-building-o'></i></div>" +
                                    "</div>" +
                                    "<input type='text' class='form-control capitalized' id='tbDepartment' value='"+ (dataItem.department === null ? "-" : dataItem.department)  +"' />" +
                                "</div>" +
                            "</div>" +
                            "<div class='form-group'>" +
                                "<div class='divLeft'><label for='tbPhone'>Tel&eacute;fono:</label></div>" +
                                "<div class='input-group mb-2'>" +
                                    "<div class='input-group-prepend'>" +
                                    "<div class='input-group-text divSecond'><i class='fa fa-phone faa-shake animated faa-slow'></i></div>" +
                                    "</div>" +
                                    "<input type='text' class='form-control' id='tbPhone' value='"+ dataItem.phone  +"' />" +
                                "</div>" +
                            "</div>" +
                            "<div class='form-group'>" +
                                "<div class='divLeft'><label for='tbExten'>Extensi&oacute;n:</label></div>" +
                                "<div class='input-group mb-2'>" +
                                    "<div class='input-group-prepend'>" +
                                    "<div class='input-group-text divSecond'><i class='fa'>#</i></div>" +
                                    "</div>" +
                                    "<input type='text' class='form-control' id='tbExten' value='"+ dataItem.exten  +"' />" +
                                "</div>" +
                            "</div>" +                             
                            "<div class='form-group'>" +
                                "<div class='divLeft'><label for='tbEmail'>Correo Electr&oacute;nico:</label></div>" +
                                "<div class='input-group mb-2'>" +
                                    "<div class='input-group-prepend'>" +
                                    "<div class='input-group-text divSecond'><i class='fa fa-envelope-o'></i></div>" +
                                    "</div>" +
                                    "<input type='text' class='form-control' id='tbEmail' value='"+ (dataItem.email === null ? "-" : dataItem.email)  +"' />" +
                                "</div>" +
                            "</div>" +                                                                                   
                        "</div>";

        ShowAlertMessage("Información del colaborador", tableData,''); 
    })    
 

})
const ContactDirectory = (props)=> { 
    const [dataLoading, setDataLoading] = useState(true);
    const fillData = ()=>{ 

    let Record = [];
    API.getData("/Employees/get?companyId=" + getUser().companyId)
      .then((res) => {
        setDataLoading(false);
        if (res.status === 200) {
            for (let indx = 0; indx < res.data.length; indx++) {
                const item = res.data[indx];
                const dataList = btoa(JSON.stringify([{id:item.id,  name: item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase(), department: (item.department !== null ? item.department.toLowerCase(): '') , phone: (item.phone !== null ? item.phone.trim() : ''), email: (item.email !== null ? item.email.toLowerCase(): ''), exten: (item.extention !== null ? item.extention:'') }]));
                Record.push({itemData: "<a href='#' id='btViewInfo' data-item="+ dataList +" class='fa fa-eye' title='ver más información' ></a>", name: item.firstName.trim().toLowerCase() + ' ' + item.lastName.trim().toLowerCase(), department: (item.department !== null ? item.department.toLowerCase():''), exten: (item.extention !== null ? item.extention:'')  });
            } 
            
            $("#TblContactDirectory").DataTable({
                        destroy: true,
                        searching: true,
                        language: LangSpanish,
                        lengthMenu: [6, 20, 40, 60, 80, 90, 100, 200, 500, 1000, 2000],
                        "order": [[1, "asc"]],
                        data:  (Record.length === 0? [{itemData: '', name:'',department:'', exten:'-'}] : Record),
                        columns: [
                            { data: "itemData", title: "", width:'2%', orderable: false},
                            { data: "name", title: "Nombre Completo", width:'45%', className:"capitalized" },
                            { data: "department", title: "Departamento", width:'40%', className:"capitalized" },
                            { data: "exten", title: "Extensión", width:'10%'  }
                        ]

                    });
                $('.csHidden').attr('style', 'display:none');
          

        }
      })
      .catch(function (err) {
        console.error("Error de conexion " + err);
      });
 
};
useEffect(() => {
     fillData()
}, []);
return (
<>
     <section className="container">
         <div className="container">
            <div className="container"> 
                    <br />
                    <br />         
                    <hr />    
                    <div className="">
                        {!dataLoading ?
                            <div className="table-responsive" Style="min-height:600px">
                                <table id="TblContactDirectory"  className="table table-striped table-bordered" Style="width:100% !important"></table>
                            </div>                 
                        :
                        <Loading />
                        }
                    
                    </div>
                </div>             
         </div>
    </section>
    </>
 );
 
};
export default ContactDirectory;
