import React, { useState, useEffect } from 'react';
import API from '../../utils/api';
import { getUser } from "../../utils/Common";

export default function FundacionCorripio() {

    const [fundacionCorripio, setFundacionCorripio] = useState("");

    useEffect(() => {
        API.getData('company/get?companyId=' + getUser().companyId).then(response => {
            setFundacionCorripio(response.data[0].fundacionCorripio);
        }).catch(error => {
            console.log(error);
        });
      },[]);

    return ( 
        <section id="main" className="wrapper">
            <div className="inner">
               
                <div className="content">
                    <header> <h2 Style="text-align:center">Fundación Corripio</h2>
                    </header>
                    <div dangerouslySetInnerHTML={{__html: fundacionCorripio}} />
                </div>
            </div>
        </section>
    )
}