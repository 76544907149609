import React, { useState } from "react";
import { getUser, removeUserSession } from "../../utils/Common";
import API from "../../utils/api";
import BackButton from "../Button/BackButton";

export default function RequestsResume() {
  return (
    <>
      <br />
      <div className="row">
        <div className="col-12">
          <br />
          <h2 className="h2" Style={"text-align:center"}>Mis&nbsp;Solicitudes</h2>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className=" col-12">
          <div className="input-group-prepend">
            <div className="text-wrap textInfo mb-2">
              <span>
                <i className="fa fa-exclamation-triangle faa-flash animated"></i>
                &nbsp;Las cartas son entregadas a los 5 d&iacute;as
                h&aacute;biles luego de ser solicitadas. Estas son entregadas
                todos los jueves en horario laboral en el departamento de Gente
                y Gesti&oacute;n.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
